import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import Toolbar from "@/components/ui/toolbar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { useGetString } from "@/context/AppContext";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import WizardStep from "@/components/wizard/WizardStep";
import { ProjectType } from "@/types";

const formSchema = z.object({
  name: z.string(),
});

type FormShape = z.infer<typeof formSchema>;

export default function ProjectName({
  defaultValues,
  onSubmit,
  projectType = "project",
}: {
  defaultValues: { name?: string };
  onSubmit: (v: { name: string }) => Promise<void>;
  projectType?: ProjectType;
}) {
  const getString = useGetString();
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>Let's name your {projectType}</Title>
            <Description>
              Choose a name that is simple but detailed enough for you to
              identify later.
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    autoFocus
                    value={field.value || ""}
                    placeholder={getString(
                      "project_name_placeholder",
                      projectType === "webstore"
                        ? "e.g. Employee Pop Up Store"
                        : "e.g. New Hire Kits",
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <WizardSubmitButton>Next</WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
