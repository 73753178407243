import ProjectName from "@/components/projects/create/ProjectName";
import ProjectDelivery from "@/components/projects/create/ProjectDelivery";
import ProjectReview from "@/components/projects/create/ProjectReview";
import { useCreateProject } from "@/api/projects";
import { useAppContext, useSettings } from "@/context/AppContext";
import ProjectCustomFields from "@/components/projects/create/ProjectCustomFields";
import { useTitle } from "@/lib/title";
import WizardPage, { WizardStep } from "@/components/wizard/WizardPage";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetCart } from "@/api/cart";
import SplashScreen from "@/pages/SplashScreen";
import { CREATE_PROJECT_STORAGE_KEY } from "@/constants/project";
import min from "lodash/min";
import { ProjectCreatePayload } from "@/types";
import { formatDate } from "@/lib/dates";

export default function ProjectCheckout() {
  useTitle("Project Checkout");

  const createProjectRequest = useCreateProject();
  const { membership } = useAppContext();
  const settings = useSettings();
  const navigate = useNavigate();
  const { data: cart } = useGetCart();

  const handleSubmit = (values: ProjectCreatePayload) => {
    if (membership) {
      return createProjectRequest.mutateAsync(values);
    }
    localStorage.setItem(CREATE_PROJECT_STORAGE_KEY, JSON.stringify(values));
    const searchParams = new URLSearchParams({
      redirect_to: "/projects/submit",
    });
    navigate("/register?" + searchParams.toString());
    return Promise.resolve();
  };

  const steps: WizardStep<ProjectCreatePayload>[] = [
    {
      name: "Project Name",
      description: (v) => v.name || "What should we call this project?",
      isComplete: (v) => !!v.name,
      component: ProjectName,
    },
    {
      name: "Shipping & Delivery",
      description: (v) =>
        v.in_hands_date
          ? formatDate(v.in_hands_date)
          : "How and when would you like your project delivered?",
      isComplete: (v) => Boolean(v.shipping_mode),
      component: ProjectDelivery,
    },

    {
      name: "Review & Submit",
      description: "What else should we know?",
      isComplete: (v) => Boolean(v.description),
      component: ProjectReview,
    },
  ];

  if (settings.project_fields.length > 0) {
    steps.splice(1, 0, {
      name: "Additional Information",
      description:
        "Your company requires us to collect additional information.",
      isComplete: (v) =>
        settings.project_fields.every(
          (pf) => v.fields?.find((f) => f.customer_project_field_id === pf.id),
        ),
      component: ProjectCustomFields,
    });
  }

  if (!cart) {
    return <SplashScreen />;
  }

  if (cart.items.length === 0) {
    return <Navigate to="/orders/create" replace />;
  }

  return (
    <WizardPage
      title="Checkout"
      sessionStorageKey={`project-checkout.${cart.id}.${cart.updated_at}`}
      isLoading={createProjectRequest.isLoading}
      onSubmit={handleSubmit}
      defaultValues={{
        type: "self-service",
        cart_id: cart.id,
        budget_type: "total",
        budget_qty: min(cart.items.map((i) => i.qty)), // Cart item with least amount (e.g. 24, 48, 72 = 24),
        budget_amount: cart.subtotal,
        attachments: [],
        items: cart.items
          .filter((i) => i.collection_id)
          .map((item) => ({
            collection_id: item.collection_id!,
            product_id: item.product.id,
            variants: item.variants,
            decoration: item.decoration,
          })),
        fields: settings.project_fields.map((field) => ({
          customer_project_field_id: field.id,
          value: "",
        })),
      }}
      steps={steps}
    />
  );
}
