import { useState } from "react";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import OptionQuantitiesForm from "@/components/projects/OptionQuantitiesForm";
import { useSearchParams } from "react-router-dom";
import {
  CartProduct,
  ProjectItem,
  ProjectOption,
  ProjectSelection,
} from "@/types";
import ProductImageTiles from "@/components/products/ProductImageTiles";
import ProjectOptionDetails from "@/components/projects/ProjectOptionDetails";
import ProjectOptionDuplicateColorDialog from "./ProjectOptionDuplicateColorDialog";
import { Label } from "@/components/ui/label";

export default function ProjectOptionDialog({
  item,
  groupedOptions = [],
  option,
  selection,
}: {
  item: ProjectItem;
  groupedOptions?: ProjectOption[];
  option: ProjectOption | undefined;
  selection?: ProjectSelection;
}) {
  const [selected, setSelected] = useState<CartProduct | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const optionId = option?.id;

  const onOpenChange = (open: boolean) => {
    if (open) {
      setSearchParams({ option: String(optionId) });
    } else {
      setSearchParams((prev) => {
        prev.delete("option");
        return prev;
      });
    }
  };

  const colorOptions = option
    ? option.color_options.filter(
        (co) => !groupedOptions.some((go) => go.product?.id === co.id),
      )
    : [];

  const colorImages = [
    ...groupedOptions.map((go) => go.images[0]!),
    ...colorOptions.map((o) => o.image!),
  ] as string[];

  return (
    <Dialog
      open={searchParams.get("option") === String(optionId)}
      onOpenChange={onOpenChange}
    >
      {option && (
        <DialogContent
          className={cn(
            "max-h-[calc(100vh-2rem)] overflow-y-auto sm:max-w-7xl",
          )}
        >
          <ProjectOptionDetails
            option={option}
            item={item}
            children={
              <>
                {colorImages.length > 1 && (
                  <div className="mt-4">
                    <Label>Colors</Label>
                    <ProductImageTiles
                      images={colorImages}
                      selectedImage={option.images[0]}
                      onImageSelected={(_, index) => {
                        if (groupedOptions && groupedOptions[index]) {
                          setSearchParams({
                            option: String(groupedOptions[index]!.id),
                          });
                          return;
                        }
                        const optionIndex = index - groupedOptions.length;
                        if (colorOptions[optionIndex]) {
                          setSelected(colorOptions[optionIndex]);
                          return;
                        }
                      }}
                    />
                  </div>
                )}

                <OptionQuantitiesForm
                  key={option.id}
                  onSuccess={() => onOpenChange(false)}
                  option={option}
                  selection={selection}
                />

                {selected && (
                  <ProjectOptionDuplicateColorDialog
                    onSuccess={(option: ProjectOption) => {
                      setSearchParams({ option: String(option.id) });
                      setSelected(undefined);
                    }}
                    option={option}
                    selected={selected}
                    onClose={() => setSelected(undefined)}
                  />
                )}
              </>
            }
          />
        </DialogContent>
      )}
    </Dialog>
  );
}
