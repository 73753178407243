import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerVendor } from "@/types";

export const VENDORS_KEY = "vendors";

export function useSearchVendors(debouncedQuery: string) {
  const queryClient = useQueryClient();

  return useQuery([VENDORS_KEY, "search", debouncedQuery], () =>
    axios
      .get<{ data: CustomerVendor[] }>(`/vendors?query=${debouncedQuery}`)
      .then((res) => {
        return res.data.data.map((vendor) => {
          queryClient.setQueryData([VENDORS_KEY, "detail", vendor.id], vendor);
          return vendor;
        });
      }),
  );
}

export function useGetVendor(vendorId: number | null | undefined) {
  return useQuery(
    [VENDORS_KEY, "detail", vendorId],
    () =>
      axios.get<CustomerVendor>(`/vendors/${vendorId}`).then((res) => res.data),
    {
      staleTime: 5 * 60 * 1000,
      enabled: vendorId != null,
    },
  );
}
