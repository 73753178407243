import { ProductCollection } from "@/types";
import { Loader } from "lucide-react";
import CollectionButton from "@/components/collections/CollectionButton";
import { useGetCollections } from "@/api/collection";
import { cn } from "@/lib/utils";

export default function ItemChooser({
  onSelect,
  selected,
}: {
  onSelect: (c: ProductCollection) => void;
  selected: number[];
}) {
  const { data: collections = [], isLoading } = useGetCollections();

  return (
    <div className={cn(`relative space-y-8 py-1`)}>
      {isLoading && <Loader className="size-10 animate-spin" />}

      {collections.map((collection) => {
        return (
          <div key={collection.id}>
            <h3 className="mb-2 text-xl font-semibold">{collection.name}</h3>
            {collection.children && (
              <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                {collection.children.map((child) => (
                  <CollectionButton
                    key={child.id}
                    collection={child}
                    selected={selected.includes(child.id)}
                    onSelect={onSelect}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
