import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { DatePicker } from "@/components/ui/date-picker";
import { addDays } from "date-fns";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { projectCreatePayloadSchema } from "@/types";

const formSchema = projectCreatePayloadSchema.pick({
  in_hands_date: true,
});

type FormShape = z.infer<typeof formSchema>;

export default function WebstoreTimeline({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>Let us know your timeline</Title>
            <Description>
              When are you looking to go live with this store?
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="in_hands_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Go-Live Date</FormLabel>
                <FormControl>
                  <DatePicker
                    date={field.value ? new Date(field.value) : undefined}
                    setDate={(d) =>
                      field.onChange(d?.toISOString().slice(0, 10))
                    }
                    calendarProps={{
                      fromDate: addDays(new Date(), 1),
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <WizardSubmitButton>Next</WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
