import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCreateItem, useUpdateItem } from "@/api/projects";
import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import ItemChooser from "@/components/collections/ItemChooser";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import { handleLaravelErrors } from "@/lib/form";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { canEdit } from "@/lib/projects";
import { useProject } from "@/context/ProjectContext";
import { ProjectItemPayload, projectItemPayloadSchema } from "@/types";

export default function ProjectItemEdit() {
  const { item: itemId } = useParams<{ item: string }>();
  const createRequest = useCreateItem();
  const updateRequest = useUpdateItem(Number(itemId));
  const navigate = useNavigate();
  const project = useProject();

  const editingItem = project.items.find((i) => i.id === Number(itemId));

  const form = useForm({
    resolver: zodResolver(projectItemPayloadSchema),
    defaultValues: {
      collection_id: editingItem?.collection?.id || 0,
      product_id: editingItem?.product?.id,
      notes: editingItem?.notes,
    },
  });

  if (!canEdit(project) || (itemId && !editingItem)) {
    // Can't edit project or passed an item ID to edit, but couldn't find that item
    return <Navigate to={`/projects/${project.id}`} />;
  }

  const onSubmit = (values: ProjectItemPayload) => {
    const promise = itemId
      ? updateRequest.mutateAsync(values)
      : createRequest.mutateAsync(values);

    return promise
      .then(() => {
        navigate(`/projects/${project.id}`);
      })
      .catch(handleLaravelErrors(form));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <WizardStep>
          <Toolbar className="mb-6">
            <Title>{editingItem ? "Edit" : "Add"} Product Category</Title>
          </Toolbar>
          <FormField
            control={form.control}
            name="collection_id"
            render={({ field }) => (
              <ItemChooser
                onSelect={(c) => field.onChange(c.id)}
                selected={field.value ? [field.value] : []}
              />
            )}
          />

          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Notes</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    value={field.value || ""}
                    className="min-h-[80px]"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <WizardSubmitButton isLoading={form.formState.isSubmitting}>
            Save
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
