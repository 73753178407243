import { ShippingMode } from "@/types";

export const CREATE_PROJECT_STORAGE_KEY = "createProjectPayload";

export const SHIPPING_MODE_LABELS: Record<ShippingMode, string> = {
  bulk: "Ship to Single Address",
  kits: "Ship to Multiple Addresses",
  inventory: "Add to Inventory",
  inventory_kit: "Add to Inventory as Kit",
};

export const DECORATION_METHODS = [
  "screenprint",
  "embroidery",
  "ad_specialty",
  "heat_transfer",
  "blanks",
  "patch",
] as const;

export const FEES: string[] = [
  "inbound_shipping",
  "outbound_shipping",
  "standard_shipping",
  "expedited_shipping",
  "pms_charge",
  "digitizing",
  "vectorizing",
  "setup_fee",
  "rush_fee",
  "run_charge",
  "ltm_charge",
];

export const OPTION_TAGS = [
  "sustainable",
  "diverse_supplier",
  "made_in_usa",
  "companion",
];
