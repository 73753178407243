import { useAppState } from "@/context/AppContext";
import { Navigate } from "react-router-dom";
import { JSX } from "react";
import { MembershipRole } from "@/types";

export default function RedirectGate({
  children,
  role = ["avail", "avail_incognito", "admin", "member"], // non-guest is default
}: {
  children: JSX.Element;
  role?: MembershipRole | MembershipRole[];
}) {
  const roles = Array.isArray(role) ? role : [role];
  const { membership } = useAppState();

  if (!roles.includes(membership.role)) {
    return <Navigate to="/" replace />;
  }

  return children;
}
