import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight, HelpCircleIcon } from "lucide-react";
import { Cart } from "@/types";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { curr } from "@/lib/curr";

export default function CartActions({
  cart,
  checkoutUrl,
}: {
  cart: Cart;
  checkoutUrl: string;
}) {
  const checkoutLabel = checkoutUrl.includes("project")
    ? "Next: Project Details"
    : "Proceed to Checkout";

  if (cart.items.length === 0) {
    return null;
  }

  return (
    <div className="mt-auto text-center">
      <div className="mb-2 inline-flex items-center">
        <SimpleTooltip tooltip="Shipping & Taxes will be calculated later">
          <HelpCircleIcon className="mr-2 size-4" />
        </SimpleTooltip>
        <span>Estimated Subtotal:</span>
        <span className="ml-1 font-semibold">{curr(cart.subtotal)}</span>
      </div>
      <Button asChild className="w-full" size="lg">
        <Link to={checkoutUrl}>
          {checkoutLabel} <ArrowRight className="ml-2 size-4" />
        </Link>
      </Button>
    </div>
  );
}
