import { Notification } from "@/types";
import NotificationListItem from "@/components/notifications/NotificationListItem";
import EmptyState from "@/components/ui/empty-state";

export default function NotificationsList({
  notifications,
}: {
  notifications: Notification[];
}) {
  if (notifications.length === 0) {
    return (
      <EmptyState
        title="No Activity"
        description="You have no notifications at this time."
      />
    );
  }
  return (
    <ul>
      {notifications.map((n) => (
        <NotificationListItem key={n.id} notification={n} />
      ))}
    </ul>
  );
}
