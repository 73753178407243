import { CustomerInventoryReturn } from "@/types";
import { Badge, BadgeProps } from "@/components/ui/badge";
import startCase from "lodash/startCase";

function getInventoryReturnStatus(
  status: CustomerInventoryReturn["status"],
): BadgeProps["variant"] {
  switch (status) {
    case "awaiting":
      return "yellow";
    case "processing":
      return "teal";
    case "completed":
      return "green";
  }
}

export default function InventoryReturnStatus(
  props: Pick<CustomerInventoryReturn, "status">,
) {
  return (
    <Badge variant={getInventoryReturnStatus(props.status)}>
      {startCase(props.status)}
    </Badge>
  );
}
