import { SyntheticEvent, useState } from "react";
import { CustomerSku } from "@/types";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Repeat2 } from "lucide-react";
import { useRestock } from "@/api/projects";
import { useToast } from "@/components/ui/use-toast";
import RestockForm, { SkuQty } from "./RestockForm";

export default function RestockSheet({ skus }: { skus: CustomerSku[] }) {
  const [open, setOpen] = useState(false);
  const restockRequest = useRestock();
  const { toast } = useToast();

  const onSubmit = async (e: SyntheticEvent, skus: SkuQty[]) => {
    e.preventDefault();

    restockRequest
      .mutateAsync({ items: skus.map((sku) => ({ id: sku.id, qty: sku.qty })) })
      .then(() => {
        setOpen(false);
        toast({ title: "Project Created", variant: "success" });
      });
  };

  const skusWithQty = skus.map((sku) => ({
    ...sku,
    qty: Math.max((sku.par_level || 0) - sku.inventory_qty, 0),
  }));

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline">
          <Repeat2 className="mr-2 size-5" />
          Restock SKU{skus.length !== 1 && "s"}
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-auto sm:max-w-2xl">
        <SheetHeader>
          <SheetTitle>Restock SKU{skus.length !== 1 && "s"}</SheetTitle>
          <SheetDescription>
            Please enter the quantity you would like to restock for each SKU.
            The initial quantity is calculated based on the par level and
            current inventory.
          </SheetDescription>
        </SheetHeader>

        <RestockForm
          skus={skusWithQty}
          onSubmit={onSubmit}
          isLoading={restockRequest.isLoading}
        />
      </SheetContent>
    </Sheet>
  );
}
