import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useUploadRequest() {
  return useMutation((file: Blob) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post<{ file: string; url: string }>("upload", formData)
      .then(({ data }) => data);
  });
}

export function useGetFabricRequest(image: string) {
  return useQuery(
    ["fabricState", image],
    () =>
      axios
        .get<string | null>(`fabric-states/${encodeURIComponent(image)}`)
        .then(({ data }) => data),
    {
      retry: false,
    },
  );
}

export function useSaveFabricRequest() {
  return useMutation((data: { path: string; fabric: string }) => {
    return axios.post("fabric-states", data);
  });
}
