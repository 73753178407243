import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { GiftingTemplate } from "@/types";
import { useToast } from "@/components/ui/use-toast";
import { useDeleteGiftingTemplate } from "@/api/gifting-templates";
import { TrashIcon } from "lucide-react";

export default function GiftingTemplateDeleteButton({
  template,
}: {
  template: GiftingTemplate;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const deleteRequest = useDeleteGiftingTemplate();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger className="text-muted-foreground hover:text-destructive">
        <TrashIcon className="size-4" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Gifting Template</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want delete this template?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={deleteRequest.isLoading}
            onClick={() =>
              deleteRequest.mutateAsync(template.id).then(() => {
                setOpen(false);
                toast({
                  title: "Successfully deleted template",
                  variant: "success",
                });
              })
            }
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
