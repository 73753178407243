import { Input } from "@/components/ui/input";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { SyntheticEvent, useState } from "react";
import { ProjectOption, ProjectSelection } from "@/types";
import { curr } from "@/lib/curr";
import {
  useCreateProjectSelection,
  useDeleteProjectSelection,
  usePreviewProjectSelection,
} from "@/api/project-selections";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Label } from "@/components/ui/label";
import get from "lodash/get";
import { useProject } from "@/context/ProjectContext";

export default function OptionQuantitiesForm({
  onSuccess,
  option,
  selection,
}: {
  onSuccess: () => void;
  option: ProjectOption;
  selection: ProjectSelection | undefined;
}) {
  const { type } = useProject();
  const [quantities, setQuantities] = useState<number[]>(
    selection
      ? selection.quantities.slice(0, option.variants.length)
      : option.variants.map(() => 0),
  );
  const payload = {
    option_id: option.id,
    quantities,
  };

  const createSelectionRequest = useCreateProjectSelection();
  const deleteSelectionRequest = useDeleteProjectSelection();
  const previewSelectionRequest = usePreviewProjectSelection(payload);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    createSelectionRequest.mutateAsync(payload).then(() => {
      onSuccess();
    });
  };

  const error = get(createSelectionRequest.error, "response.data.message");

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-4 flex flex-col gap-4">
        {option.variants.length === 1 || type === "webstore" ? (
          <div>
            {type === "webstore" && (
              <Alert variant="info" className="mb-2">
                Since we are creating a webstore, simply estimate the quantity
                that will purchased.
              </Alert>
            )}
            <Label htmlFor="quantity-field">Quantity</Label>
            <Input
              id="quantity-field"
              type="number"
              inputMode="numeric"
              className="my-1"
              value={String(quantities[0])}
              step={1}
              min={option.min_qty}
              onFocus={(e) => e.currentTarget.select()}
              onChange={(e) => {
                setQuantities([Number(e.target.value)]);
              }}
            />
            {option.min_qty > 1 && (
              <p className="text-sm text-muted-foreground">
                Minimum Quantity: {option.min_qty}
              </p>
            )}
          </div>
        ) : (
          <Card>
            <Table className="min-w-xs">
              <TableHeader>
                <TableRow>
                  <TableHead className="h-10 px-6">Size</TableHead>
                  <TableHead className="h-10">Quantity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {option.variants.map((variant, index) => (
                  <TableRow key={variant.size}>
                    <TableCell className="px-6 font-medium">
                      {variant.size}
                      {variant.price - option.price !== 0 && (
                        <span className="text-sm text-slate-500">
                          {" "}
                          (+{curr(variant.price - option.price)})
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="py-2">
                      <Input
                        id={`quantities.${index}`}
                        type="number"
                        inputMode="numeric"
                        className="h-9"
                        value={String(quantities[index])}
                        step={1}
                        min={0}
                        onFocus={(e) => e.currentTarget.select()}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setQuantities((prev) => {
                            const next = [...prev];
                            next[index] = value;
                            return next;
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell>
                    {option.min_qty > 1 && (
                      <span>Minimum: {option.min_qty}</span>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        )}

        {previewSelectionRequest.isLoading && (
          <div className="grid grid-cols-2 justify-between gap-x-3 gap-y-2">
            <Skeleton className="h-4 w-32" />
            <Skeleton className="ml-auto h-4 w-16" />
            <Skeleton className="h-4 w-32" />
            <Skeleton className="ml-auto h-4 w-16" />
            <Skeleton className="h-4 w-32" />
            <Skeleton className="ml-auto h-4 w-16" />
          </div>
        )}

        {previewSelectionRequest.data && (
          <div className="grid grid-cols-2 items-start gap-x-3 gap-y-2">
            {option.variants.length > 1 && (
              <>
                <div>Quantity</div>
                <div className="text-right font-semibold">
                  {previewSelectionRequest.data.total_qty.toLocaleString()}
                </div>
              </>
            )}

            <div>Unit Cost</div>
            <div className="text-right font-semibold">
              {curr(
                previewSelectionRequest.data.variable_subtotal /
                  previewSelectionRequest.data.total_qty,
              )}
            </div>

            {previewSelectionRequest.data.fixed_subtotal > 0 && (
              <>
                <div>Fees</div>
                <div className="text-right font-semibold">
                  {curr(previewSelectionRequest.data.fixed_subtotal)}
                </div>
              </>
            )}

            <div>Estimated Subtotal</div>
            <div className="text-right font-semibold">
              {curr(previewSelectionRequest.data.subtotal)}
            </div>
          </div>
        )}

        {error && <Alert variant="destructive">{error}</Alert>}

        <div className="flex gap-3">
          <Button type="submit" isLoading={createSelectionRequest.isLoading}>
            {selection ? "Update in Order" : "Add to Order"}
          </Button>
          {selection && (
            <Button
              type="button"
              variant="outline"
              onClick={() =>
                deleteSelectionRequest.mutateAsync(selection.id).then(onSuccess)
              }
              isLoading={deleteSelectionRequest.isLoading}
            >
              Remove from Order
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
