import axios from "axios";
import { CustomerFulfillmentOrder, FulfillmentOrderPayload } from "@/types";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import Wizard from "@/components/wizard/Wizard";
import OrderItemsStep from "@/components/fulfillmentOrders/create/OrderItemsStep";
import OrderDetailsStep from "@/components/fulfillmentOrders/create/OrderDetailsStep";

export default function FulfillmentOrderForm() {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const createdOrderRef = useRef<CustomerFulfillmentOrder>();

  function onSubmit(values: FulfillmentOrderPayload) {
    setIsSending(true);
    if (values.address.method?.startsWith("third_party:")) {
      Object.assign(values.address, {
        method: "Third Party",
        third_party_account: values.address.method.split(":")[1],
      });
    }

    return axios
      .post<CustomerFulfillmentOrder>(`/fulfillment-orders`, values)
      .then(({ data }) => {
        createdOrderRef.current = data;

        if (!isSending) {
          onSendingComplete();
        }
      })
      .catch((e) => {
        setIsSending(false);
        throw e;
      });
  }

  function onSendingComplete() {
    setIsSending(false);
    if (createdOrderRef.current) {
      navigate(`/fulfillment-orders/${createdOrderRef.current.id}?created=1`);
    }
  }

  return (
    <Wizard
      isLoading={isSending}
      onSubmit={onSubmit}
      steps={[
        {
          name: "Items",
          description: "Add items to the order",
          component: OrderItemsStep,
          isComplete: (values) => Boolean(values.items?.length),
        },
        {
          name: "Details",
          description: "Enter customer details",
          component: OrderDetailsStep,
          isComplete: (values) => !!values.email && !!values.address,
        },
      ]}
    />
  );
}
