import { ProjectActivity } from "@/types";
import groupBy from "lodash/groupBy";
import { useAddReaction, useRemoveReaction } from "@/api/project-activity";
import { useUser } from "@/context/AppContext";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import AddReactionButton from "@/components/projects/activity/AddReactionButton";

export function ProjectActivityReactions({
  activity,
}: {
  activity: ProjectActivity;
}) {
  const { reactions } = activity;
  const user = useUser();
  const addReaction = useAddReaction(activity.id);
  const removeReaction = useRemoveReaction(activity.id);
  const grouped = groupBy(reactions, (r) => r.reaction);

  const handleReactionClick = (reaction: string) => () => {
    const existing = reactions.find(
      (r) => r.user.id === user.id && r.reaction === reaction,
    );
    if (existing) {
      removeReaction.mutate(existing.id);
    } else {
      addReaction.mutate(reaction);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {Object.entries(grouped).map(([reaction, items]) => (
        <SimpleTooltip
          key={reaction}
          tooltip={items.map((i) => i.user.name).join(", ")}
          asChild
        >
          <button
            type="button"
            className="flex h-7 items-center space-x-1 rounded-full border px-2 leading-none hover:bg-primary/10"
            onClick={handleReactionClick(reaction)}
          >
            <div className="text-sm font-medium">{reaction}</div>
            <div className="text-sm font-medium">{items.length}</div>
          </button>
        </SimpleTooltip>
      ))}
      <AddReactionButton
        activityId={activity.id}
        className="h-7 rounded-full border px-2 leading-none hover:bg-primary/10"
      />
    </div>
  );
}
