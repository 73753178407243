import { Progress } from "@/components/ui/progress";
import pluralize from "pluralize";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

function B({ children, className, ...props }: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span className={cn("font-medium", className)} {...props}>
      {children}
    </span>
  );
}

export default function GiftKitProgressBar({
  limit,
  current,
  type,
}: {
  limit: number;
  current: number;
  type: "price" | "count";
}) {
  return (
    <div className="min-w-48 space-y-1 text-xs">
      <div className="flex items-center">
        <span className="font-medium">
          {type === "price" ? "Credits" : "Items"}
        </span>
        <span className="ml-auto">
          {pluralize(type === "price" ? "credit" : "items", limit, true)}
        </span>
      </div>
      <Progress value={(current / limit) * 100} className="h-2" />
      <div className="text-muted-foreground">
        {type === "price" ? (
          <p>
            You have <B>{pluralize("credit", limit - current, true)}</B>{" "}
            remaining.
          </p>
        ) : (
          <p>
            You can choose{" "}
            <B>{pluralize("more item", limit - current, true)}</B>.
          </p>
        )}
      </div>
    </div>
  );
}
