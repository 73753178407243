import {
  useMutation,
  useQuery,
  useQueryClient,
  WithRequired,
} from "@tanstack/react-query";
import axios from "axios";
import {
  AddressPayload,
  Collection,
  Gift,
  GiftBulkCreatePayload,
  GiftingVariant,
  GiftLink,
  GiftPayload,
} from "@/types";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

export const GIFTS_KEY = "gifts";
export const GIFT_LINKS_KEY = "giftLinks";
export const GIFT_QUEUES_KEY = "giftQueues";

export function useGetGift(id: number) {
  return useQuery([GIFTS_KEY, "detail", id], () =>
    axios
      .get<WithRequired<Gift, "items">>(`gifts/${id}`)
      .then(({ data }) => data),
  );
}

export function useCreateGift() {
  const navigate = useNavigate();

  return useMutation((payload: GiftPayload) =>
    axios.post<Gift>("gifts", payload).then(({ data }) => {
      navigate(`/gifts/${data.id}?created=1`);
      return data;
    }),
  );
}

export function useBulkCreateGifts() {
  const queryClient = useQueryClient();

  return useMutation((payload: GiftBulkCreatePayload) =>
    axios.post<Gift[]>("gifts/bulk", payload).then(({ data }) => {
      queryClient.invalidateQueries([GIFTS_KEY]);
      return data;
    }),
  );
}

export function useGetGiftLink(id: string) {
  return useQuery([GIFT_LINKS_KEY, id], () =>
    axios.get<GiftLink>(`gift-links/${id}`).then(({ data }) => data),
  );
}

export type GiftSelections = GiftingVariant["id"] | KitSelections | null;

export type KitSelections = { [v: string]: GiftSelections };

export interface RedeemPayload {
  address: AddressPayload;
  items: { id: string; selections: GiftSelections }[];
}

export function useRedeemGift(id: string) {
  const queryClient = useQueryClient();

  return useMutation((payload: RedeemPayload) =>
    axios.put<GiftLink>(`gift-links/${id}`, payload).then(({ data }) => {
      queryClient.setQueryData([GIFT_LINKS_KEY, id], data);
      return data;
    }),
  );
}

export function useCancelGift(id: number) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.post(`gifts/${id}/cancel`).then(() => {
      queryClient.invalidateQueries([GIFTS_KEY]);
    }),
  );
}

export function useRetryGift(id: number) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.post(`gifts/${id}/retry`).then(() => {
      queryClient.invalidateQueries([GIFTS_KEY]);
    }),
  );
}

export function useSendGiftReminder() {
  const queryClient = useQueryClient();

  return useMutation((ids: number[]) =>
    axios.post<Gift>("gifts/send-reminder", { ids }).then(({ data }) => {
      queryClient.invalidateQueries([GIFTS_KEY]);
      return data;
    }),
  );
}

export function useGetGiftQueues() {
  return useQuery([GIFT_QUEUES_KEY], () =>
    axios
      .get<Collection<{ queue: string; gifts_count: number }>>("gift-queues")
      .then(({ data }) => data.data),
  );
}

export function useProcessGiftQueue() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((queue: string) =>
    axios.post(`gift-queues/${queue}`).then(() => {
      toast({
        title: "Gifts Processing",
        description: `Gifts in the ${queue} queue are being imported into the ERP system.`,
      });
      queryClient.invalidateQueries([GIFT_QUEUES_KEY]);
    }),
  );
}
