import {
  useGetGiftingProducts,
  useSortGiftingProducts,
} from "@/api/gifting-products";
import GiftingProductCard from "@/components/gifting/products/GiftingProductCard";
import { GripVerticalIcon, Loader, PlusCircleIcon } from "lucide-react";
import { Link } from "react-router-dom";
import Toolbar from "@/components/ui/toolbar";
import SearchInput from "@/components/ui/search-input";
import { useMemo, useState } from "react";
import EmptyState from "@/components/ui/empty-state";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { GiftingProduct } from "@/types";
import { CSS } from "@dnd-kit/utilities";

function GiftingProductItem({
  product,
  sortingDisabled,
}: {
  product: GiftingProduct;
  sortingDisabled?: boolean;
}) {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useSortable({ id: product.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="relative">
      {!sortingDisabled && (
        <button
          type="button"
          className="absolute right-1 top-1 z-10 cursor-grab p-2 text-muted-foreground hover:text-black"
          {...listeners}
          {...attributes}
        >
          <GripVerticalIcon className="size-4" />
        </button>
      )}
      <Link to={`/gifting-products/${product.id}`}>
        <GiftingProductCard
          product={product}
          className="hover:border-slate-400"
        />
      </Link>
    </div>
  );
}

export default function GiftingProductsGrid() {
  const [query, setQuery] = useState("");
  const { data: products = [], isLoading } = useGetGiftingProducts();
  const sortRequest = useSortGiftingProducts();

  const filteredProducts = useMemo(() => {
    return products.filter((d) =>
      d.name.toLowerCase().includes(query.toLowerCase()),
    );
  }, [products, query]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    const activeIndex = filteredProducts.findIndex((p) => p.id === active.id);
    const overIndex = filteredProducts.findIndex((p) => p.id === over.id);
    sortRequest.mutate(arrayMove(filteredProducts, activeIndex, overIndex));
  };

  const sortingDisabled = filteredProducts.length !== products.length;

  return (
    <div>
      {products.length > 0 && (
        <Toolbar className="mb-4">
          <SearchInput defaultValue={query} onChange={setQuery} />
        </Toolbar>
      )}

      {isLoading ? (
        <Loader className="size-6 animate-spin" />
      ) : products.length === 0 ? (
        <Card className="p-5">
          <EmptyState
            title="Empty Gifting Catalog"
            description="Get started by adding your first product"
          >
            <Link to="/gifting-products/create">
              <Button>
                <PlusCircleIcon className="mr-2 size-4" /> Add Product
              </Button>
            </Link>
          </EmptyState>
        </Card>
      ) : filteredProducts.length === 0 ? (
        <p className="text-muted-foreground">No results found</p>
      ) : (
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={filteredProducts.map((p) => p.id)}
            disabled={sortingDisabled}
          >
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredProducts.map((product) => (
                <GiftingProductItem
                  key={product.id}
                  product={product}
                  sortingDisabled={sortingDisabled}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
}
