import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { matchPath } from "react-router-dom";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function findLowestNonZeroNumber(arr: string[]) {
  const numbers = arr.map(Number);
  const nonZeroNumbers = numbers.filter((num) => !isNaN(num) && num !== 0);
  const lowestNonZeroNumber = Math.min(...nonZeroNumbers);

  return lowestNonZeroNumber;
}

export function isPermittedGuestLink(pathname: string) {
  const guestRoutes = ["orders/:code", "approvals/:code", "quotes/:code"];

  return (
    guestRoutes.some((path) => matchPath({ path }, pathname)) &&
    !pathname.startsWith("/guest")
  );
}

export function getGuestRoutePath(pathname: string) {
  if (!pathname.startsWith("/guest")) {
    return `/guest${pathname}`;
  }

  return pathname;
}

export function luhnCheck(cc: string) {
  let sum = 0;
  let alternate = false;

  for (let i = cc.length - 1; i >= 0; i--) {
    let digit = parseInt(cc[i] ?? "0", 10);

    if (alternate) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    alternate = !alternate;
  }

  return sum % 10 === 0;
}
