import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { useMaskito } from "@maskito/react";
import type { MaskitoOptions } from "@maskito/core";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressFormSection from "@/components/addresses/AddressFormSection";
import { addressPayloadSchema } from "@/types";
import { useRegisterCard } from "@/api/tango";
import { luhnCheck } from "@/lib/utils";

const cardNumberRegex = /^(?:\d{4}[\s]?){3,4}\d{3,4}$/;
const formSchema = z.object({
  creditCard: z.object({
    first_name: z.string(),
    last_name: z.string(),
    expiration: z
      .string()
      .regex(/^\d{4}-(0[1-9]|1[0-2])$/, "Invalid format. Use YYYY-MM"),
    number: z
      .string()
      .regex(cardNumberRegex, "Invalid credit card number format")
      .refine(
        (val) => {
          const sanitizedValue = val.replace(/\s+/g, "");
          return luhnCheck(sanitizedValue);
        },
        {
          message: "Invalid credit card checksum",
        },
      ),
    verification: z.string().regex(/^\d+$/, "CVV must be numeric"),
  }),
  address: addressPayloadSchema,
});

export default function TangoRegisterCardDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const registerCard = useRegisterCard();
  const cardMask: MaskitoOptions = {
    mask: [
      ...new Array(4).fill(/\d/),
      " ",
      ...new Array(4).fill(/\d/),
      " ",
      ...new Array(4).fill(/\d/),
      " ",
      ...new Array(4).fill(/\d/),
      " ",
      ...new Array(3).fill(/\d/),
    ],
  };
  const cardInputRef = useMaskito({ options: cardMask });
  const expirationMask = useMaskito({
    options: {
      mask: [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/],
    },
  });

  const onSubmit = (payload: z.infer<typeof formSchema>) => {
    const sanitizedPayload = {
      ...payload,
      creditCard: {
        ...payload.creditCard,
        number: payload.creditCard.number.replace(/\s+/g, ""),
      },
    };

    return registerCard.mutateAsync(sanitizedPayload).then(() => {
      setOpen(false);
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open, form]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="!mt-1">
        <Button variant="link" onClick={() => setOpen(!open)} className="pl-0">
          Add payment method
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl p-6">
        <div className="flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>Add Credit Card</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                return form.handleSubmit(onSubmit)(e);
              }}
              className="space-y-4"
            >
              <Label>Credit Card Details</Label>
              <Card className="p-5">
                <div className="flex flex-row">
                  <FormField
                    control={form.control}
                    name="creditCard.first_name"
                    render={({ field }) => (
                      <FormItem className="mr-4 w-1/2 max-w-full">
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} placeholder="John" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="creditCard.last_name"
                    render={({ field }) => (
                      <FormItem className="mr-4 w-1/2 max-w-full">
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} placeholder="Doe" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-row">
                  <FormField
                    control={form.control}
                    name="creditCard.number"
                    render={({ field }) => (
                      <FormItem className="mr-4 w-1/2 max-w-full">
                        <FormLabel>Card Number</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            ref={cardInputRef}
                            inputMode="numeric"
                            placeholder="1234 5678 9012 3456"
                            value={field.value}
                            onInput={(e: React.FormEvent<HTMLInputElement>) => {
                              form.setValue(
                                "creditCard.number",
                                e.currentTarget.value,
                              );
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="creditCard.expiration"
                    render={({ field }) => (
                      <FormItem className="mr-4 w-1/2 max-w-full">
                        <FormLabel>Expiration</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            ref={expirationMask}
                            placeholder="YYYY-MM"
                            value={field.value}
                            onInput={(e: React.FormEvent<HTMLInputElement>) => {
                              form.setValue(
                                "creditCard.expiration",
                                e.currentTarget.value,
                              );
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="creditCard.verification"
                  render={({ field }) => (
                    <FormItem className="mr-4 w-1/4 max-w-full">
                      <FormLabel>CVV</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          inputMode="numeric"
                          pattern="\d*"
                          {...field}
                          placeholder="CVV"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Card>
              <AddressFormSection form={form} size="sm" />
              <DialogFooter>
                <Button variant="outline" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button isLoading={registerCard.isLoading} type="submit">
                  Save Credit Card
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
