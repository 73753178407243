import { useEffect, useState } from "react";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { Loader, Link as LinkIcon, XIcon } from "lucide-react";
import { useSearchOrders } from "@/api/orders";
import { ProjectOption } from "@/types";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import ProjectOptionSampleOrderButton from "@/components/projects/ProjectOptionSampleOrderButton";

export default function ProjectOptionSampleOrderDialog({
  option,
}: {
  option: ProjectOption;
}) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>("");

  const { data: orders = [], isLoading } = useSearchOrders(query);

  useEffect(() => setQuery(""), [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex" onClick={() => setOpen(!open)}>
        <SimpleTooltip tooltip="Link sample order">
          <LinkIcon className="size-5 text-muted-foreground" />
        </SimpleTooltip>
      </DialogTrigger>
      <DialogContent>
        Link an order to this option:
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="e.g. SO-0012345"
            value={query}
            onValueChange={setQuery}
          />
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {option.sample_order ? (
              <ProjectOptionSampleOrderButton
                cb={() => setOpen(false)}
                mode="delete"
                option={option}
                orderId={option.sample_order.id}
              >
                <CommandItem
                  key="remove-order"
                  className="width-100 cursor-pointer overflow-hidden hover:opacity-50"
                >
                  {option.sample_order.increment_id}
                  <XIcon className="ml-4 size-4 text-slate-500" />
                </CommandItem>
              </ProjectOptionSampleOrderButton>
            ) : null}
            {isLoading && query ? (
              <Loader />
            ) : orders.length ? (
              orders.map((order) => {
                return (
                  <ProjectOptionSampleOrderButton
                    cb={() => setOpen(false)}
                    mode="add"
                    option={option}
                    orderId={order.id}
                  >
                    <CommandItem
                      key={order.id}
                      className="width-100 cursor-pointer overflow-hidden hover:opacity-50"
                    >
                      {order.increment_id}
                    </CommandItem>
                  </ProjectOptionSampleOrderButton>
                );
              })
            ) : (
              <CommandEmpty>No matching orders found.</CommandEmpty>
            )}
          </CommandGroup>
        </Command>
      </DialogContent>
    </Dialog>
  );
}
