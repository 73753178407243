import { IntegrationType } from "@/types";
import HubspotLogo from "@/assets/hubspot.svg?react";
import SalesforceLogo from "@/assets/salesforce.svg?react";
import ProliantLogo from "@/assets/proliant.svg?react";
import WorkdayLogo from "@/assets/workday.svg?react";
import SlackLogo from "@/assets/slack.svg?react";
import TeamsLogo from "@/assets/teams.svg?react";
import { ComponentType } from "react";

const ICONS: Record<IntegrationType, ComponentType<{ className?: string }>> = {
  hubspot: HubspotLogo,
  salesforce: SalesforceLogo,
  proliant: ProliantLogo,
  workday: WorkdayLogo,
  slack: SlackLogo,
  teams: TeamsLogo,
};

export default function IntegrationIcon({
  type,
  size = "w-8 h-8",
  url,
}: {
  type: IntegrationType;
  size?: string;
  url?: string | null;
}) {
  const Icon = ICONS[type];
  const iconElement = <Icon className={size} />;

  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {iconElement}
      </a>
    );
  }
  return iconElement;
}
