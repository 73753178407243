import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import { GiftBulkCreatePayload, giftBulkCreatePayloadSchema } from "@/types";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import Gate from "@/components/roles/Gate";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { FormErrors } from "@/components/ui/form-errors";
import { getSendAsExample, getSendingMethod } from "@/components/gifting/utils";
import uniq from "lodash/uniq";
import InsertFromTemplate from "@/components/gifting/templates/InsertFromTemplate";
import { Textarea } from "@/components/ui/textarea";
import InsertTemplateVariable from "@/components/gifting/templates/InsertTemplateVariable";
import SenderField from "@/components/gifting/SenderField";
import { useAppState } from "@/context/AppContext";
import { useMemberships } from "@/api/membership";
import GiftSignature from "@/components/gifting/links/GiftSignature";
import SendAsField from "@/components/gifting/SendAsField";

const formSchema = giftBulkCreatePayloadSchema
  .pick({
    should_send: true,
    subject_template: true,
    message_template: true,
    gift_note_template: true,
    queue: true,
    sender_id: true,
    send_as: true,
  })
  .refine(
    (schema) => {
      if (schema.should_send) {
        return (
          schema.subject_template != null &&
          schema.subject_template.trim().length > 0
        );
      }
      return true;
    },
    {
      message: "Subject is required when sending",
      path: ["subject_template"],
    },
  )
  .refine(
    (schema) => {
      if (schema.should_send) {
        return (
          schema.message_template != null &&
          schema.message_template.trim().length > 0
        );
      }
      return true;
    },
    {
      message: "Message is required when sending",
      path: ["message_template"],
    },
  );

type FormShape = z.infer<typeof formSchema>;

export default function GiftBulkSendStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<GiftBulkCreatePayload>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const {
    membership: { customer },
    user,
  } = useAppState();

  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      send_as: user.send_as,
      ...defaultValues,
    },
  });

  const shouldSend = form.watch("should_send");
  const sendAs = form.watch("send_as") || 0;
  const senderId = form.watch("sender_id");
  const { data: memberships = [] } = useMemberships();
  const sender = memberships.find((m) => m.user.id === senderId)?.user || user;

  const canSendGiftNote = defaultValues.items!.every(
    (item) => item.product.fulfillment_type === "inventory",
  );

  const canQueue = defaultValues.items!.every(
    (item) => item.product.fulfillment_type === "batch",
  );

  const methods = uniq(defaultValues.recipients!.map(getSendingMethod));

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>How do you want to send this gift?</Title>
            <Description>
              Choose how you want to send this gift to the recipient.
            </Description>
          </Toolbar>

          <div className="mt-4 max-w-4xl space-y-4">
            <FormField
              control={form.control}
              name="should_send"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <FormLabel variant="checkbox">
                      Send {methods.join(" or ")} to each recipient
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />

            {shouldSend && (
              <FormField
                control={form.control}
                name="subject_template"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center">
                      <FormLabel className="flex-grow">Subject</FormLabel>
                      <InsertTemplateVariable
                        value={field.value || ""}
                        onChange={field.onChange}
                        recipient={defaultValues.recipients![0]}
                      />
                      <InsertFromTemplate
                        type="subject"
                        variables={undefined}
                        setValue={field.onChange}
                      />
                    </div>
                    <FormControl>
                      <Input {...field} value={field.value || ""} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="message_template"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center">
                    <FormLabel className="flex-grow">
                      {!shouldSend && "Optional "}Message
                    </FormLabel>
                    <InsertTemplateVariable
                      value={field.value || ""}
                      onChange={field.onChange}
                      recipient={defaultValues.recipients![0]}
                    />
                    <InsertFromTemplate
                      type="message"
                      variables={undefined}
                      setValue={field.onChange}
                    />
                  </div>
                  <div>
                    <FormControl>
                      <Textarea
                        {...field}
                        value={field.value || ""}
                        className="relative z-10 min-h-[200px] rounded-b-none"
                      />
                    </FormControl>
                    <div className="flex items-center justify-between rounded-b-lg border border-t-0 bg-card p-3">
                      <GiftSignature
                        sender_name={getSendAsExample(sendAs, sender, customer)}
                        customer={customer}
                        sender={sender}
                      />
                      <SendAsField
                        value={sendAs}
                        onChange={(v) => form.setValue("send_as", v)}
                      />
                    </div>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />

            {canSendGiftNote && (
              <FormField
                control={form.control}
                name="gift_note_template"
                render={({ field }) => (
                  <>
                    <FormItem>
                      <div className="flex items-center space-x-2">
                        <Switch
                          checked={field.value != null}
                          onCheckedChange={(checked) =>
                            field.onChange(checked ? "" : null)
                          }
                        />
                        <FormLabel variant="checkbox">
                          Include a custom note card with the gift (+$1.00)
                        </FormLabel>
                      </div>
                    </FormItem>
                    {field.value != null && (
                      <FormItem>
                        <div className="flex items-center">
                          <FormLabel className="flex-grow">Gift Note</FormLabel>
                          <InsertTemplateVariable
                            value={field.value}
                            onChange={field.onChange}
                            recipient={defaultValues.recipients![0]}
                          />
                          <InsertFromTemplate
                            variables={undefined}
                            type="gift_note"
                            setValue={field.onChange}
                          />
                        </div>
                        <FormControl>
                          <Textarea
                            {...field}
                            value={field.value}
                            className="min-h-[200px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  </>
                )}
              />
            )}

            {canQueue && (
              <Gate role={["avail", "avail_incognito"]}>
                <FormField
                  control={form.control}
                  name="queue"
                  render={({ field }) => (
                    <>
                      <FormItem>
                        <div className="flex items-center space-x-2">
                          <Switch
                            checked={field.value != null}
                            onCheckedChange={(checked) =>
                              field.onChange(checked ? "default" : null)
                            }
                          />
                          <FormLabel variant="checkbox">
                            Specify a specific queue for this gift
                          </FormLabel>
                        </div>
                      </FormItem>
                      {field.value != null && (
                        <FormItem>
                          <FormLabel>Queue Name</FormLabel>
                          <FormControl>
                            <Input {...field} value={field.value || ""} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    </>
                  )}
                />
              </Gate>
            )}

            <SenderField form={form} />
          </div>

          <FormErrors form={form} />

          <WizardSubmitButton>
            {shouldSend ? "Send Gifts" : "Create Links"}
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
