import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { INPUT_CLASSES } from "@/components/ui/input";
import { Check, ChevronsUpDown } from "lucide-react";
import { Command, CommandItem } from "@/components/ui/command";
import { useState } from "react";
import { getSendAsTitle } from "@/components/gifting/utils";

export default function SendAsField({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("justify-between text-left", INPUT_CLASSES)}
        >
          Send as {getSendAsTitle(value)}
          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          {[0, 1, 2, 3].map((sendAs) => (
            <CommandItem
              key={sendAs}
              value={String(sendAs)}
              onSelect={(currentValue) => {
                onChange(Number(currentValue));
                setOpen(false);
              }}
            >
              <div>
                <p>{getSendAsTitle(sendAs)}</p>
              </div>
              <Check
                className={cn(
                  "ml-2 size-4",
                  value === sendAs ? "opacity-100" : "opacity-0",
                )}
              />
            </CommandItem>
          ))}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
