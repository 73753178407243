import { useHasFeature } from "@/context/AppContext";
import PriceTable from "@/components/account/PriceTable";

export default function StripeGate({
  feature,
  featureTitle,
  children,
}: {
  feature: string;
  featureTitle: string;
  children: React.ReactNode;
}) {
  const hasFeature = useHasFeature();

  if (import.meta.env.VITE_BYPASS_STRIPE_GATE === "true") {
    return <>{children}</>;
  }

  if (!hasFeature(feature)) {
    return <PriceTable featureTitle={featureTitle} />;
  }

  return <>{children}</>;
}
