import {
  ProductCollection,
  CustomerProjectField,
  CartProduct,
  Project,
  ProjectField,
  ProjectOption,
  ProjectOptionPayload,
  ProjectSelection,
} from "@/types";
import colors from "tailwindcss/colors";
import pluralize from "pluralize";
import uniqBy from "lodash/uniqBy";

export function canEdit(project: Project, allowAfterProofsRequested = false) {
  if (allowAfterProofsRequested) {
    return project.status !== "complete" && project.status !== "cancelled";
  }
  return ["draft", "options_requested", "options_provided"].includes(
    project.status,
  );
}

export function hasProofsToReview(project: Project) {
  return project.orders.some(
    (o) => o.outstanding_approvals && o.outstanding_approvals!.length > 0,
  );
}

export function isWaitingOnPO(project: Project) {
  return (
    project.orders.some((o) => o.waiting_on_po) &&
    project.orders.every((o) => o.status !== "Order Received")
  );
}

export function isWaitingOnPayment(project: Project) {
  return (
    project.orders.some((o) => o.waiting_on_payment) &&
    project.orders.every((o) => o.status !== "Order Received")
  );
}

export const PROJECT_STATUS_COLORS = {
  "Project Created": "gray",
  "Waiting on Options": "indigo",
  "Review Options": "blue",
  "Waiting on Proofs": "purple",
  "Waiting on PO": "amber",
  "Waiting on Payment": "amber",
  "Approve Proofs": "amber",
  "Order In Progress": "teal",
  Complete: "green",
  Canceled: "red",
} as const;

export function getProjectStatusLabel(project: Project) {
  switch (project.status) {
    case "draft":
      return "Project Created" as const;
    case "options_requested":
      return "Waiting on Options" as const;
    case "options_provided":
      return "Review Options" as const;
    case "proofs_requested":
      if (hasProofsToReview(project)) {
        return "Approve Proofs" as const;
      }
      if (isWaitingOnPO(project)) {
        return "Waiting on PO" as const;
      }
      if (isWaitingOnPayment(project)) {
        return "Waiting on Payment" as const;
      }
      return "Waiting on Proofs" as const;
    case "ordered":
      return "Order In Progress" as const;
    case "complete":
      return "Complete" as const;
    case "cancelled":
      return "Canceled" as const;
  }
}

export function getProjectStatusHexColor(
  label: keyof typeof PROJECT_STATUS_COLORS,
) {
  const variant = PROJECT_STATUS_COLORS[label];
  if (!variant) {
    return colors.gray[500];
  }
  return colors[variant][500];
}

export function getProjectStatusVariant(
  label: keyof typeof PROJECT_STATUS_COLORS,
) {
  const variant = PROJECT_STATUS_COLORS[label];
  if (!variant || variant === "gray") {
    return "outline";
  }
  return variant;
}

export function projectOptionToPayload(
  option: ProjectOption,
): ProjectOptionPayload {
  return {
    ...option,
    sample_order_id: option.sample_order?.id || null,
    tags: option.tags || {},
    product_id: option.product?.id,
    vendor_id: option.vendor?.id,
  };
}

export function getItemName(item: {
  collection?: ProductCollection;
  product?: CartProduct | null;
}): string {
  return item.product?.name || item.collection?.name || "Unknown";
}

export function getItemImage(item: {
  collection?: ProductCollection;
  product?: CartProduct | null;
}): string | undefined {
  return item.product?.image || item.collection?.image || undefined;
}

export function getOptionGroupingKey(option: ProjectOption): string {
  const design = option.layout_config?.id || option.type;
  if (option.product) {
    return `${option.product.style_number}.${option.product.vendor}.${design}`;
  }
  return `${option.number}.${option.vendor?.id || "none"}.${design}`;
}

export function getRequestProofsDescription(
  project: Project,
  selections: ProjectSelection[],
) {
  const when = `before the ${
    project.type === "webstore"
      ? "webstore is created"
      : `${pluralize("order", selections.length)} is placed`
  }`;
  return selections.length === 1
    ? `This will request a final proof for the option you selected. You will be able to approve the proof ${when}.`
    : `This will request a final proof for each of your selected options. You will be able to approve the proofs ${when}.`;
}

export function getProjectFieldModels(
  fromSettings: CustomerProjectField[],
  fromProject: ProjectField[],
): CustomerProjectField[] {
  return uniqBy(
    [...fromSettings, ...fromProject.map((f) => f.field)],
    (f) => f.id,
  );
}
