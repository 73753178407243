import { CustomerOrder } from "@/types";
import StatusProgress from "@/components/ui/status-progress";
import { Alert } from "@/components/ui/alert";

export default function OrderStatusSection({
  order,
}: {
  order: CustomerOrder;
}) {
  if (order.status === "Cancelled") {
    return <Alert variant="destructive">This order has been canceled.</Alert>;
  }

  const STEPS = [
    "Order Received",
    "Approved",
    "Ordered from Vendor",
    order.has_production === false ? null : "In Production",
    "Pending Shipment",
    "Complete",
  ];

  if (order.terms.is_upfront) {
    STEPS.splice(2, 0, "Waiting on Payment");
  } else if (order.terms.is_po_required) {
    STEPS.splice(2, 0, "Waiting on PO");
  }

  const filteredSteps = STEPS.filter((s) => !!s) as string[];

  return (
    <StatusProgress
      steps={filteredSteps.map((status) => ({
        status,
        title: status,
      }))}
      currentStatus={order.status}
    />
  );
}
