import { HTMLAttributes } from "react";
import { AddressPayload } from "@/types";
import { cn } from "@/lib/utils";

interface AddressBlockProps extends HTMLAttributes<HTMLDivElement> {
  address: AddressPayload;
}

export default function AddressText({
  address,
  className,
  ...other
}: AddressBlockProps) {
  return (
    <div className={cn("w-full text-left", className)} {...other}>
      <span>{address.address_1}</span>
      {address.address_2 && <span> {address.address_2}</span>}
      <span>, {address.city}</span>
      {address.state && <span>, {address.state}</span>}
      <span> {address.zip}</span>
      {address.country && address.country !== "US" ? (
        <span>, {address.country}</span>
      ) : null}
    </div>
  );
}
