import { CustomerSku } from "@/types";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ReactNode, useState } from "react";
import { Alert } from "@/components/ui/alert";
import { useArchiveVariants } from "@/api/variants";

export default function ArchiveSkuButton({
  children,
  disabled,
  onArchive,
  skus,
  title,
}: {
  children: ReactNode;
  disabled?: boolean;
  onArchive?: () => void;
  skus: CustomerSku[];
  title: string;
}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const archiveRequest = useArchiveVariants();
  const ids = skus.map((sku) => sku.id);

  const handleArchive = () => {
    archiveRequest
      .mutateAsync(ids)
      .then(() => {
        setOpen(false);
        if (onArchive) {
          onArchive();
        }
      })
      .catch((e) => {
        setError(
          e.response.data.message ||
            "An error occurred. Please try again later.",
        );
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger disabled={disabled} asChild>
        {children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone.
          </AlertDialogDescription>
          {error && <Alert variant="destructive">{error}</Alert>}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={archiveRequest.isLoading}
            onClick={handleArchive}
          >
            Archive
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
