import { HTMLAttributes } from "react";
import { Address as AddressModel } from "@/types";
import { cn } from "@/lib/utils";
import { RadioGroup } from "@headlessui/react";

interface AddressBlockProps extends HTMLAttributes<HTMLDivElement> {
  address: AddressModel;
  asLabel?: boolean;
  labelClassName?: string;
}

export default function AddressBlock({
  address,
  className,
  asLabel,
  labelClassName,
  ...other
}: AddressBlockProps) {
  const NameComponent = asLabel ? RadioGroup.Label : "div";
  return (
    <div
      className={cn("w-full truncate text-left [&>*]:truncate", className)}
      {...other}
    >
      <NameComponent className={labelClassName}>{address.name}</NameComponent>
      {address.company && <div>{address.company}</div>}
      <div>{address.address_1}</div>
      {address.address_2 && <div>{address.address_2}</div>}
      <div>
        <span>{address.city}</span>
        {address.state && <span>, {address.state}</span>}
        <span> {address.zip}</span>
      </div>
      {address.country && address.country !== "US" ? (
        <div>{address.country}</div>
      ) : null}
      {address.phone && <div>{address.phone}</div>}
    </div>
  );
}
