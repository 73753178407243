import { CustomerOrder, OrderItem } from "@/types";
import { Card } from "@/components/ui/card";
import { TableCell } from "@/components/ui/table";
import { curr } from "@/lib/curr";
import {
  TotalTable,
  TotalTableLabel,
  TotalTableRow,
  TotalTableValue,
} from "@/components/ui/total-table";
import { Separator } from "@/components/ui/separator";
import OrderItemsTable from "@/components/orders/OrderItemsTable";

export default function OrderItems({
  order,
  items,
}: {
  order: CustomerOrder;
  items: OrderItem[];
}) {
  return (
    <div>
      <Card>
        <OrderItemsTable items={items} />
      </Card>
      <div className="mt-4 flex justify-end">
        <Card className="px-6 py-3">
          <TotalTable>
            <TotalTableRow>
              <TotalTableLabel>Subtotal</TotalTableLabel>
              <TotalTableValue>{curr(order.subtotal)}</TotalTableValue>
            </TotalTableRow>
            <TotalTableRow>
              <TotalTableLabel>Shipping</TotalTableLabel>
              <TotalTableValue>
                {curr(order.shipping + order.inbound_freight)}
              </TotalTableValue>
            </TotalTableRow>
            {order.precollected_tax ? (
              <TotalTableRow>
                <TotalTableLabel>Tax Collected on Store</TotalTableLabel>
                <TotalTableValue>
                  {curr(order.precollected_tax)}
                </TotalTableValue>
              </TotalTableRow>
            ) : null}
            <TotalTableRow>
              <TotalTableLabel>Tax</TotalTableLabel>
              <TotalTableValue>{curr(order.tax)}</TotalTableValue>
            </TotalTableRow>
            <TotalTableRow>
              <TotalTableLabel>Total</TotalTableLabel>
              <TotalTableValue>{curr(order.total)}</TotalTableValue>
            </TotalTableRow>

            {order.paid != 0 && (
              <>
                <TotalTableRow>
                  <TableCell colSpan={2} className="px-0">
                    <Separator />
                  </TableCell>
                </TotalTableRow>
                <TotalTableRow>
                  <TotalTableLabel>Paid</TotalTableLabel>
                  <TotalTableValue>{curr(order.paid)}</TotalTableValue>
                </TotalTableRow>
                <TotalTableRow>
                  <TotalTableLabel>Balance</TotalTableLabel>
                  <TotalTableValue>{curr(order.balance)}</TotalTableValue>
                </TotalTableRow>
              </>
            )}
          </TotalTable>
        </Card>
      </div>
    </div>
  );
}
