import { GiftLinkProduct, GiftLinkVariant } from "@/types";
import GiftProductSelector from "@/components/gifting/links/GiftProductSelector";
import { GiftSelections, KitSelections } from "@/api/gifts";
import { useEffect, useMemo } from "react";
import GiftSelectableCard from "@/components/gifting/links/GiftSelectableCard";
import pluralize from "pluralize";
import QuantityInput from "@/components/gifting/links/QuantityInput";
import GiftKitProgressBar from "@/components/gifting/links/GiftKitProgressBar";

function getGcd(a: number, b: number) {
  while (b !== 0) {
    [a, b] = [b, a % b];
  }
  return a;
}

function dollarsToCents(dollars: number) {
  return Math.round(dollars * 100);
}

function getOptionPrice(variant: GiftLinkVariant) {
  return variant.kit_product?.max_price || 0;
}

function getVariantKey(variant: Pick<GiftLinkVariant, "id">) {
  // We are making this a non-integer string so that the object keys are in insertion order and not numerical order
  return `${variant.id}_variant`;
}

function getQtyKey(variant: Pick<GiftLinkVariant, "id">) {
  return `${variant.id}_qty`;
}

const getQtyForVariant = (
  selections: KitSelections,
  variant: Pick<GiftLinkVariant, "id">,
): number => {
  return Number(selections[getQtyKey(variant)] ?? 1);
};

/**
 * Remove the first item from the selections
 * This function mutates the selections object
 * @param selections
 */
const removeFromFront = (selections: KitSelections) => {
  for (const key in selections) {
    if (!key.endsWith("_qty")) {
      const qty = getQtyForVariant(selections, { id: Number(key) });
      if (qty > 1) {
        selections[getQtyKey({ id: Number(key) })] = qty - 1;
      } else {
        delete selections[key];
      }
      return;
    }
  }
};

export default function GiftKitSelector({
  kitProduct,
  onChange,
  value,
  path,
  isSelected,
  onSelect,
  price,
}: {
  kitProduct: GiftLinkProduct;
  onChange: (path: string[], selections: GiftSelections | undefined) => void;
  value: KitSelections;
  path: string[];
  isSelected: boolean;
  onSelect?: () => void;
  price?: number;
}) {
  if (kitProduct.type != "kit") {
    throw new Error("This component only supports kit products");
  }

  const canChoose = Boolean(
    kitProduct.kit_product_limit || kitProduct.kit_price_limit,
  );

  const canChooseQuantity = useMemo(() => {
    if (kitProduct.kit_price_limit) {
      return kitProduct.variants.some(
        (v) => getOptionPrice(v) * 2 <= kitProduct.kit_price_limit!,
      );
    }

    return Boolean(
      kitProduct.kit_product_limit && kitProduct.kit_product_limit > 1,
    );
  }, [kitProduct]);

  useEffect(() => {
    if (!canChoose && isSelected) {
      setTimeout(() => {
        kitProduct.variants.forEach((v) => {
          onChange(
            [...path, getVariantKey(v)],
            v.kit_product!.type === "kit" ? {} : null,
          );
        });
      });
    }
  }, [canChoose, isSelected]);

  /**
   * Get the total price of the selected items to check that it is within the limits
   */
  const getTotalPrice = (selections: KitSelections) => {
    return kitProduct.variants
      .filter((v) => getVariantKey(v) in selections)
      .reduce(
        (acc, v) => acc + getOptionPrice(v) * getQtyForVariant(selections, v),
        0,
      );
  };

  /**
   * Get the total number selected items to check that it is within the limits
   */
  const getTotalCount = (selections: KitSelections) => {
    return kitProduct.variants
      .filter((v) => getVariantKey(v) in selections)
      .reduce((acc, v) => acc + getQtyForVariant(selections, v), 0);
  };

  /**
   * Handle selecting a variant.  If there is a limit and the new selection exceeds that limit,
   * shift items off the list until the selections are within the limit
   */
  const handleSelect = (variant: GiftLinkVariant, qty?: number) => {
    if (getVariantKey(variant) in value && !qty) {
      // Deselect
      onChange([...path, getVariantKey(variant)], undefined);
    } else {
      const newValues = { ...value };
      if (qty) {
        newValues[getQtyKey(variant)] = qty;
      } else {
        newValues[getVariantKey(variant)] = null;
      }
      while (
        kitProduct.kit_product_limit &&
        getTotalCount(newValues) > kitProduct.kit_product_limit
      ) {
        removeFromFront(newValues);
      }
      while (
        kitProduct.kit_price_limit &&
        getTotalPrice(newValues) > kitProduct.kit_price_limit
      ) {
        removeFromFront(newValues);
      }
      onChange(path, newValues);
    }
  };

  const gcd = useMemo(
    () =>
      kitProduct.variants.reduce(
        (acc, v) => getGcd(acc, dollarsToCents(v.kit_product!.max_price)),
        dollarsToCents(kitProduct.kit_price_limit || 1),
      ),
    [kitProduct],
  );

  return (
    <div
      style={{ marginLeft: `${path.length > 0 ? path.length - 1 : 0}rem` }}
      className="mb-1"
    >
      <GiftSelectableCard onSelect={onSelect} isSelected={isSelected}>
        <div className="flex flex-col gap-x-8 gap-y-4 md:flex-row md:items-end">
          <div className="min-w-0 flex-grow">
            <h4 className="text-lg font-semibold">{kitProduct.name}</h4>
            {price != null && <div>{pluralize("credit", price, true)}</div>}
            {isSelected && (
              <p className="mt-2 text-sm text-muted-foreground">
                {canChoose ? "You can choose from" : "You get each of"} the
                following:
              </p>
            )}
          </div>
          {isSelected && kitProduct.kit_product_limit ? (
            <GiftKitProgressBar
              limit={kitProduct.kit_product_limit}
              current={getTotalCount(value)}
              type="count"
            />
          ) : null}
          {isSelected && kitProduct.kit_price_limit ? (
            <GiftKitProgressBar
              limit={dollarsToCents(kitProduct.kit_price_limit) / gcd}
              current={dollarsToCents(getTotalPrice(value)) / gcd}
              type="price"
            />
          ) : null}
        </div>
      </GiftSelectableCard>

      {isSelected && (
        <div className="mt-3 flex flex-col gap-3">
          {kitProduct.variants.map((v) => {
            if (!v.kit_product) {
              throw new Error("Kit product is missing");
            }
            return (
              <GiftProductSelector
                key={v.id}
                product={v.kit_product}
                value={value[getVariantKey(v)] || null}
                onChange={onChange}
                onSelect={canChoose ? () => handleSelect(v) : undefined}
                isSelected={getVariantKey(v) in value}
                path={[...path, getVariantKey(v)]}
                price={
                  kitProduct.kit_price_limit == null
                    ? undefined
                    : dollarsToCents(v.kit_product.max_price) / gcd
                }
              >
                {canChooseQuantity && getVariantKey(v) in value && (
                  <div className="absolute bottom-4 right-5">
                    <QuantityInput
                      value={getQtyForVariant(value, v)}
                      onChange={(qty) => handleSelect(v, qty)}
                    />
                  </div>
                )}
              </GiftProductSelector>
            );
          })}
        </div>
      )}
    </div>
  );
}
