import { ReactNode, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Button } from "../ui/button";
import { useUpdateCustomerBrandFile } from "@/api/brand-files";
import { CustomerBrandFile } from "@/types";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  name: z.string(),
});

export default function UpdateFileDialog({
  file,
  children,
}: {
  file: CustomerBrandFile;
  children?: ReactNode;
}) {
  const updateFile = useUpdateCustomerBrandFile(file);
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: file.name,
    },
  });

  const handleCreate = (values: z.infer<typeof formSchema>) =>
    updateFile.mutateAsync(values).then((data) => {
      form.reset({
        name: data.name,
      });
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rename Logo</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleCreate)}
            className="space-y-5"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Update
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
