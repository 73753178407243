import { ReactNode } from "react";
import { createColumnHelper, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerOrder } from "@/types";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import { UseQueryResult } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";
import { formatDate } from "@/lib/dates";

const columnHelper = createColumnHelper<CustomerOrder>();
const COLUMNS = [
  columnHelper.accessor("increment_id", {
    header: "#",
    cell: ({ getValue, row }) => (
      <Link to={`/orders/${row.original.code}`} className="font-medium">
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("placed_at", {
    header: "Date",
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor("description", {
    header: "Description",
  }),
  columnHelper.accessor("order_type.name", {
    header: "Order Type",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: ({ getValue }) => <Badge>{getValue()}</Badge>,
  }),
  columnHelper.accessor("total", {
    header: "Total",
    cell: ({ getValue }) => curr(getValue()),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <Button asChild variant="link" size="sm">
        <Link to={`/orders/${row.original.code}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </Button>
    ),
  }),
];

export default function OrdersTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerOrder>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerOrder>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS);

  return (
    <DataTable
      header={header && header(table)}
      table={table}
      isLoading={query.isLoading}
    />
  );
}
