import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AddressPayload, CustomerFulfillmentOrder, ShippoRate } from "@/types";

export const FULFILLMENT_ORDERS_KEY = "fulfillmentOrders";

export function useGetFulfillmentOrder(id: number) {
  return useQuery([FULFILLMENT_ORDERS_KEY, "detail", id], () =>
    axios
      .get<CustomerFulfillmentOrder>(`/fulfillment-orders/${id}`)
      .then(({ data }) => data),
  );
}

export function useShippingEstimate(address: AddressPayload | undefined) {
  return useQuery(
    [FULFILLMENT_ORDERS_KEY, "shippingEstimate", address?.zip],
    () =>
      axios
        .post<ShippoRate>(`/fulfillment-orders/estimate-shipping`, {
          address,
        })
        .then(({ data }) => data),
    {
      enabled: Boolean(address?.zip),
    },
  );
}
