import { z, ZodTypeAny } from "zod";
import {
  accountTypeSchema,
  approvalReasonCategorySchema,
  artRequestActivityTypeSchema,
  artRequestTypeSchema,
  commissionHitSchema,
  commissionMethodSchema,
  complexityUnitSchema,
  costCategorySchema,
  decorationMethodSchema,
  giftingProductTypeSchema,
  integrationTypeSchema,
  intervalSchema,
  inventoryLocationTypeSchema,
  itemClassificationSchema,
  paymentTypeSchema,
  productTypeSchema,
  projectBudgetTypeSchema,
  projectTypeSchema,
  rebateCalculationMethodSchema,
  shippingModeSchema,
  shipToPartySchema,
  shoppingModeSchema,
  transactionTypeSchema,
  vendorPaymentMethodSchema,
} from "./enumSchemas";

export const zodNullableNumber = <T extends ZodTypeAny>(zodPipe: T) =>
  z.coerce
    .string()
    .nullish()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .refine((value) => value == null || !isNaN(Number(value)), {
      message: "Invalid Number",
    })
    .transform((value) => (value == null ? value : Number(value)))
    .pipe(zodPipe);

export const genericModelReferenceSchema = z.object({
  id: z.number(),
});

export const accountPayloadSchema = z.object({
  name: z.string().min(1),
  number: z.string().min(1),
  type: accountTypeSchema,
  parent_id: z.number().nullish(),
  description: z.string().nullish(),
  account_number: z.string().nullish(),
  routing_number: z.string().nullish(),
  cost_category: costCategorySchema.nullish(),
});

export const addressPayloadSchema = z.object({
  name: z.string().min(2).max(46),
  company: z.string().max(46).nullish(),
  address_1: z.string().min(2).max(46),
  address_2: z.string().nullish(),
  city: z.string().min(2).max(50),
  state: z.string().nullish(),
  zip: z.string().min(1),
  country: z.string().length(2),
  phone: z.string().nullish(),
  is_billing: z.boolean().optional(),
  require_signature: z.boolean().optional(),
  is_taxable_address: z.boolean().nullish(),
  is_residential: z.boolean().nullish(),
  description: z.string().nullish(),
  method: z.string().nullish(),
  service_level: z.string().nullish(),
  third_party_account: z.string().nullish(),
  reference_1: z.string().nullish(),
  reference_2: z.string().nullish(),
  customer_label: z.string().nullish(),
  save_address: z.boolean().optional(),
  bypass_validation: z.boolean().optional(),
  inventory_variant_id: z.number().nullish(),
});

export const approvalApprovePayloadSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
});

export const approvalDeclinePayloadSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  reason: z.string().min(1),
  reason_category: approvalReasonCategorySchema,
});

export const artCostItemPayloadSchema = z.object({
  name: z.string().min(1),
  cost: z.coerce.number().min(0),
  add_after_activity: z.string().nullish(),
  rebate_lob: z.boolean().optional(),
});

export const artRequestActivityPayloadSchema = z.object({
  art_request_id: z.number(),
  type: artRequestActivityTypeSchema,
  design_id: z.number(),
  note: z.string().nullish(),
});

export const artRequestPayloadSchema = z.object({
  type: artRequestTypeSchema,
  note: z.string().nullish(),
  send_approval_on_complete: z.boolean().optional(),
  only_art_approval: z.boolean().optional(),
  assigned_to: z.number().nullish(),
});

export const attachmentPayloadSchema = z.object({
  file: z.string().min(1),
  name: z.string().nullish(),
  group: z.string().nullish(),
  size: z.number().nullish(),
  created_by: z.number().nullish(),
  id: z.number().nullish(),
});

export const bankTransactionCreatePayloadSchema = z.object({
  transaction_type: transactionTypeSchema,
  account_id: z.number(),
  vendor_id: z.number().nullish(),
  rule_create: z.boolean().optional(),
  rule_auto_create: z.boolean().optional(),
});

export const bankTransactionMatchPayloadSchema = z.object({
  transaction_id: z.number().nullish(),
  deposit_id: z.number().nullish(),
});

export const bankTransactionPayloadSchema = z.object({
  date: z.string().date(),
  description: z.string().min(1),
  amount: z.coerce.number(),
  account_owner: z.string().nullish(),
  category: z.array(z.string()).nullish(),
  plaid_id: z.string().nullish(),
});

export const bankingRulePayloadSchema = z.object({
  name: z.string().min(1),
  account: genericModelReferenceSchema,
  match_description: z.string().min(1),
  transaction_type: transactionTypeSchema.optional(),
  vendor: genericModelReferenceSchema.nullish(),
  match_amount: z.string().nullish(),
  match_account_owner: z.string().nullish(),
  auto_create: z.boolean().optional(),
});

export const boxTypePayloadSchema = z.object({
  name: z.string().min(1),
  length: z.coerce.number().positive(),
  width: z.coerce.number().positive(),
  height: z.coerce.number().positive(),
  sort_order: zodNullableNumber(z.number().int().nullish()),
});

export const brandPayloadSchema = z.object({
  name: z.string().min(1),
  size_chart_url: z.string().url().nullish(),
  description: z.string().nullish(),
  image: z.string().nullish(),
});

export const businessCreditPayloadSchema = z.object({
  business: genericModelReferenceSchema,
  amount: z.coerce.number(),
  description: z.string().min(1),
});

export const businessPayloadSchema = z.object({
  name: z.string().min(1),
  statement_descriptor: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  admin_email: z.string().email().nullish(),
  url: z.string().nullish(),
  address: addressPayloadSchema.nullish(),
  vendor: genericModelReferenceSchema.nullish(),
  internal_name: z.string().nullish(),
  tin: z.string().nullish(),
  color: z.string().nullish(),
  logo: z.string().nullish(),
  icon: z.string().nullish(),
  about: z.string().nullish(),
  is_house: z.boolean().nullish(),
});

export const commissionModelLevelSchema = z.object({
  min_margin: zodNullableNumber(z.number().nullable()),
  max_margin: zodNullableNumber(z.number().nullable()),
  percent: z.coerce.number(),
});

export const commissionModelDingSchema = z.object({
  min_days_late: z.coerce.number().int(),
  percent: z.coerce.number(),
});

export const commissionModelPayloadSchema = z.object({
  name: z.string().min(1),
  levels: z.array(commissionModelLevelSchema),
  dings: z.array(commissionModelDingSchema),
  method: commissionMethodSchema.optional(),
  business: genericModelReferenceSchema.nullish(),
});

export const commissionPayloadSchema = z.object({
  user: genericModelReferenceSchema,
  commission_model: genericModelReferenceSchema.nullish(),
  percent: zodNullableNumber(z.number().min(0).max(100).nullish()),
  id: z.number().nullish(),
});

export const customerContactPayloadSchema = z.object({
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  title: z.string().nullish(),
  department: genericModelReferenceSchema.nullish(),
  external_id: z.string().nullish(),
  external_source: z.string().nullish(),
  is_invoice_contact: z.boolean().optional(),
  is_statement_contact: z.boolean().optional(),
  is_approval_contact: z.boolean().optional(),
  id: z.number().nullish(),
});

export const customerBrandColorPayloadSchema = z.object({
  id: z.number().nullish(),
  ink_color: z.string().nullish(),
  thread_color: z.string().nullish(),
  rgb: z.string().nullish(),
  cmyk: z.string().nullish(),
  hex: z.string().nullish(),
});

export const customerBrandFilePayloadSchema = z.object({
  id: z.number().nullish(),
  name: z.string().min(1),
  type: z.union([z.literal("logo"), z.literal("font")]),
  file: z.string().url(),
});

export const customerBulkUpdatePayloadSchema = z.object({
  from_rep: genericModelReferenceSchema,
  to_rep: genericModelReferenceSchema,
  preview: z.boolean(),
  commissions: z.array(commissionPayloadSchema),
});

export const customerDomainPayloadSchema = z.object({
  domain: z.string().min(1),
  is_auto: z.boolean().optional(),
});

export const customerFulfillmentPricePayloadSchema = z.object({
  id: z.number().nullish(),
  number: z.string().min(1),
  price: z.coerce.number(),
});

export const customerProjectFieldPayloadSchema = z.object({
  id: z.number().nullish(),
  label: z.string().min(1),
  type: z.string().min(1),
  options: z.array(z.string()).nullish(),
  help: z.string().nullish(),
  required: z.boolean().optional(),
});

export const customerSegmentPayloadSchema = z.object({
  name: z.string().min(1),
  parent_id: z.number().nullish(),
  department_set: genericModelReferenceSchema.nullish(),
  sort_order: zodNullableNumber(z.number().nullish()),
});

export const cycleCountPayloadSchema = z.object({
  variant_id: z.number(),
  location_id: z.number(),
  qty: z.coerce.number().int(),
  expense_account_id: z.number().nullish(),
  note: z.string().nullish(),
});

export const dashboardPayloadSchema = z.object({
  name: z.string().min(1),
  roles: z.array(genericModelReferenceSchema),
});

export const decorationLocationPayloadSchema = z.object({
  id: z.number().nullish(),
  name: z.string().min(1),
  sort_order: z.coerce.number().int(),
});

export const decorationTypePayloadSchema = z.object({
  name: z.string().min(1),
  event_type: genericModelReferenceSchema.nullish(),
  external_event_type: genericModelReferenceSchema.nullish(),
  locations: z.array(decorationLocationPayloadSchema).nullish(),
  needs_sew_out: z.boolean().optional(),
  has_drops: z.boolean().optional(),
  sort_order: z.coerce.number().int().optional(),
});

export const departmentPayloadSchema = z.object({
  id: z.number().nullish(),
  name: z.string().min(1),
});

export const departmentSetPayloadSchema = z.object({
  name: z.string().min(1),
  departments: z.array(departmentPayloadSchema).nullish(),
});

export const depositPayloadSchema = z.object({
  deposit_date: z.string().date(),
  bank_account: genericModelReferenceSchema,
  payments: z.array(genericModelReferenceSchema).nullish(),
  memo: z.string().nullish(),
  additional_fees: z.coerce.number().optional(),
  stripe_id: z.string().nullish(),
});

export const designColorPayloadSchema = z.object({
  id: z.number().nullish(),
  code: z.string().nullish(),
  color: z.string().min(1),
  flash: z.boolean().nullish(),
  squeegee: z.string().nullish(),
  mesh: zodNullableNumber(z.number().int().nullish()),
});

export const designPayloadSchema = z.object({
  name: z.string().min(1),
  location: z.string().min(1),
  decoration_type: z.object({
    id: z.number(),
    name: z.string().min(1),
    unit: complexityUnitSchema.nullable(),
    has_drops: z.boolean(),
    is_transfer: z.boolean(),
  }),
  complexity: zodNullableNumber(z.number().int().nullish()),
  request_notes: z.string().nullish(),
  customer: genericModelReferenceSchema.nullish(),
  special_design_type: genericModelReferenceSchema.nullish(),
  product: genericModelReferenceSchema.nullish(),
  colors: z.array(designColorPayloadSchema).nullish(),
  completed_via: z.number().nullish(),
  design_code: z.string().nullish(),
  width: z.string().nullish(),
  height: z.string().nullish(),
  has_underbase: z.boolean().optional(),
  description: z.string().nullish(),
  image: z.string().nullish(),
  product_colors: z.string().nullish(),
  decoration: z.string().nullish(),
  attachments: z.array(attachmentPayloadSchema).nullish(),
});

export const designLibraryItemPayloadSchema = z.object({
  name: z.string().min(1),
  description: z.string().nullish(),
  image: z.string().url(),
});

export const fulfillmentBackorderItemPayloadSchema = z.object({
  id: z.number(),
  qty: z.coerce.number().int(),
});

export const fulfillmentBackorderPayloadSchema = z
  .object({
    items: z.array(fulfillmentBackorderItemPayloadSchema),
  })
  .transform((v) => ({
    items: v.items.filter((i) => i.qty > 0),
  }));

export const fulfillmentOrderItemPayloadSchema = z.object({
  sku: z.string().nullish(),
  variant_id: z.number().nullish(),
  description: z.string().nullish(),
  qty: z.coerce.number().int().min(1),
  notes: z.string().nullish(),
});

export const fulfillmentOrderPayloadSchema = z.object({
  number: z.string().min(1),
  date: z.string().min(1),
  address: addressPayloadSchema,
  customer: genericModelReferenceSchema,
  items: z.array(fulfillmentOrderItemPayloadSchema).min(1),
  external_id: z.string().nullish(),
  external_source: z.string().nullish(),
  webstore_id: z.number().nullish(),
  webstore_name: z.string().nullish(),
  name: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  customer_po: z.string().nullish(),
  earliest_ship_date: z.string().nullish(),
  send_confirmation: z.boolean().optional(),
});

export const groupPayloadSchema = z.object({
  name: z.string().min(1),
  business: genericModelReferenceSchema.nullish(),
  users: z.array(genericModelReferenceSchema),
  taggable: z.boolean().optional(),
});

export const inboundShipmentPayloadSchema = z.object({
  shipment_date: z.string().min(1),
  cost: z.coerce.number(),
  invoice_date: z.string().nullish(),
  shipper: z.string().nullish(),
  shipper_address: z.string().nullish(),
  po_number: z.string().nullish(),
  service_type: z.string().nullish(),
  true_cost: zodNullableNumber(z.number().nullish()),
  recipient_name: z.string().nullish(),
  recipient_company: z.string().nullish(),
  recipient_address: z.string().nullish(),
  recipient_city: z.string().nullish(),
  recipient_state: z.string().nullish(),
  recipient_zip: z.string().nullish(),
  tracking_number: z.string().nullish(),
  carrier: z.string().nullish(),
  invoice_number: z.string().nullish(),
  from_import: z.string().nullish(),
  hash: z.string().nullish(),
  purchase_order: genericModelReferenceSchema.nullish(),
});

export const inkChangePayloadSchema = z.object({
  id: z.number().nullish(),
  design_color_id: z.number(),
  color: z.string().min(1),
});

export const inventoryAdjustmentPayloadSchema = z.object({
  start_location: genericModelReferenceSchema
    .extend({ path: z.string() })
    .nullish(),
  end_location: genericModelReferenceSchema
    .extend({ path: z.string() })
    .nullish(),
  variant: genericModelReferenceSchema,
  expense_account: genericModelReferenceSchema.nullish(),
  qty: z.coerce.number().int().min(1),
  note: z.string().nullish(),
  is_scrap: z.boolean().optional(),
});

export const inventoryLocationGroupPayloadSchema = z.object({
  name: z.string().min(1),
  business: genericModelReferenceSchema.nullish(),
});

export const inventoryLocationPayloadSchema = z.object({
  location_group: genericModelReferenceSchema,
  path: z.string().min(1),
  type: inventoryLocationTypeSchema,
  description: z.string().nullish(),
});

export const issuePayloadSchema = z.object({
  reason: z.string(),
  details: z.string(),
  tracking_numbers: z.array(z.string()).nullish(),
  location: z.string().nullish(),
  fields: z.record(z.union([z.string(), z.number(), z.boolean()])).nullish(),
  order_item_ids: z.array(z.number()).nullish(),
  purchase_order_item_ids: z.array(z.number()).nullish(),
});

export const journalEntryItemPayloadSchema = z.object({
  id: z.number().nullish(),
  account: genericModelReferenceSchema,
  description: z.string().nullish(),
  debit_amount: z.coerce.number().optional(),
  credit_amount: z.coerce.number().optional(),
});

export const journalEntryPayloadSchema = z.object({
  journal_date: z.string().min(1),
  items: z.array(journalEntryItemPayloadSchema),
  memo: z.string().nullish(),
});

export const kitPayloadSchema = z.object({
  name: z.string().nullish(),
  number: z.string().nullish(),
  group: z.string().nullish(),
  email: z.string().email().nullish(),
});

export const notePayloadSchema = z.object({
  note: z.string().min(1),
  created_by: z.number().nullish(),
  id: z.number().nullish(),
  attachments: z.array(attachmentPayloadSchema).nullish(),
});

export const orderApprovalPayloadSchema = z.object({
  is_order: z.boolean().optional(),
  is_art: z.boolean().optional(),
  to: z.string().email().nullish(),
  note: z.string().nullish(),
});

export const orderCommissionUpdatePayloadSchema = z.object({
  commission_users: z.array(commissionPayloadSchema),
});

export const orderCostPayloadSchema = z.object({
  cost: z.coerce.number(),
  description: z.string().min(1),
  category: costCategorySchema,
  id: z.number().nullish(),
});

export const orderCostsUpdatePayloadSchema = z.object({
  costs: z.array(orderCostPayloadSchema),
});

export const orderCreateApiPayloadSchema = z.object({
  customer: genericModelReferenceSchema,
  order_type: genericModelReferenceSchema,
  description: z.string().max(60),
  department: genericModelReferenceSchema,
  earliest_ship_date: z.string().nullish(),
  drop_dead_date: z.string().nullish(),
  contact_first_name: z.string().nullish(),
  contact_last_name: z.string().nullish(),
  contact_email: z.string().email().nullish(),
  contact_phone: z.string().nullish(),
  shipping: z.coerce.number().optional(),
  inbound_freight: z.coerce.number().optional(),
});

export const orderDesignCreatePayloadSchema = z.object({
  design_layout_id: z.number(),
  design_id: z.number().nullish(),
  name: z.string().nullish(),
  decoration_type_id: z.number().nullish(),
  complexity: zodNullableNumber(z.number().nullish()),
  ink_changes: z.array(inkChangePayloadSchema).nullish(),
});

export const orderDesignReferenceSchema = z.object({
  hash: z.string().min(1),
  layout_uuid: z.string().nullish(),
  drops: z.array(z.string()).nullish(),
  qty: zodNullableNumber(z.number().nullish()),
});

export const orderDesignUpdatePayloadSchema = z.object({
  design: genericModelReferenceSchema,
  ink_changes: z.array(inkChangePayloadSchema),
});

export const orderItemPayloadSchema = z.object({
  is_custom: z.boolean(),
  qty: z.coerce.number().int(),
  description: z.string().max(150),
  variant: genericModelReferenceSchema.nullish(),
  number: z.string().max(40).nullish(),
  color: z.string().nullish(),
  size: z.string().nullish(),
  vendor: genericModelReferenceSchema.nullish(),
  price: z.coerce.number(),
  cost: z.coerce.number(),
  is_purchasable: z.boolean().optional(),
  show_on_invoice: z.boolean().optional(),
  can_apply_designs: z.boolean().optional(),
  can_drop_ship: z.boolean().optional(),
  is_shippable: z.boolean().optional(),
  is_customer_supplied: z.boolean().optional(),
  is_tax_exempt: z.boolean().optional(),
  note: z.string().nullish(),
  inventory_unit_conversion: zodNullableNumber(z.number().nullish()),
  qty_per_kit: zodNullableNumber(z.number().nullish()),
  classification: itemClassificationSchema.optional(),
  upc: z.string().nullish(),
  bin: z.string().nullish(),
  discount: zodNullableNumber(z.number().nullish()),
  inventory_variant: genericModelReferenceSchema.nullish(),
  fee: z.boolean().nullish(),
  image: z.string().nullish(),
  order_designs: z.array(genericModelReferenceSchema).nullish(),
  uuid: z.string().nullish(),
  weight: z.coerce.number().nullish(),
});

export const orderTypePayloadSchema = z.object({
  name: z.string().min(1),
  short_name: z.string().nullish(),
  color: z.string().nullish(),
  decoration_discount: zodNullableNumber(z.number().nullish()),
  avail_split_max: zodNullableNumber(z.number().nullish()),
  min_access_level: zodNullableNumber(z.number().nullish()),
  revenueAccount: genericModelReferenceSchema.nullish(),
  cogsAccount: genericModelReferenceSchema.nullish(),
  default_show_on_invoice: z.boolean().optional(),
  default_can_apply_designs: z.boolean().optional(),
  default_is_purchasable: z.boolean().optional(),
  default_is_shippable: z.boolean().optional(),
  default_can_drop_ship: z.boolean().optional(),
  is_blank: z.boolean().optional(),
  is_webstore: z.boolean().optional(),
  has_children: z.boolean().optional(),
  production_disabled: z.boolean().optional(),
  is_public: z.boolean().optional(),
  sort_order: z.coerce.number().int(),
});

export const orderUpdatePayloadSchema = z.object({
  customer: genericModelReferenceSchema.optional(),
  order_type: genericModelReferenceSchema.optional(),
  department: genericModelReferenceSchema.nullish(),
  terms: genericModelReferenceSchema.optional(),
  billing_address: genericModelReferenceSchema.optional(),
  sales_rep: genericModelReferenceSchema.optional(),
  description: z.string().optional(),
  shipping: z.coerce.number().optional(),
  inbound_freight: z.coerce.number().optional(),
  contact_first_name: z.string().nullish(),
  contact_last_name: z.string().nullish(),
  contact_email: z.string().email().nullish(),
  contact_phone: z.string().nullish(),
  contact_title: z.string().nullish(),
  customer_po: z.string().nullish(),
  memo: z.string().nullish(),
  webstore_id: z.number().nullish(),
  placed_at: z.string().nullish(),
});

export const packoutDestroyPayloadSchema = z.object({
  address: addressPayloadSchema,
});

export const paymentApplyPayloadSchema = z.object({
  payment_id: z.number(),
  amount: z.coerce.number(),
  order_id: z.number().nullish(),
  account_id: z.number().nullish(),
  commission_hit: commissionHitSchema.nullish(),
});

export const paymentDeductionPayloadSchema = z.object({
  order_id: z.number(),
  amount: z.coerce.number(),
  commission_hit: commissionHitSchema.nullish(),
});

export const paymentPayloadSchema = z.object({
  payment_date: z.string().date(),
  payment_type: paymentTypeSchema,
  method: z.string().min(1),
  amount: z.coerce.number(),
  payment_number: z.string().nullish(),
  memo: z.string().nullish(),
  card_name: z.string().nullish(),
  email: z.string().email().nullish(),
  card_last_four: z.string().nullish(),
  card_exp_date: z.string().nullish(),
  card_brand: z.string().nullish(),
  debit_account: genericModelReferenceSchema.nullish(),
  business: genericModelReferenceSchema.nullish(),
  customer: genericModelReferenceSchema.nullish(),
  discount: zodNullableNumber(z.number().nullish()),
  processing_fee: z.coerce.number().optional(),
  avail_fee: z.coerce.number().optional(),
  stripe_id: z.string().nullish(),
  source_id: z.string().nullish(),
  rebate_id: z.number().nullish(),
  webstore_id: z.number().nullish(),
});

export const productImagePayloadSchema = z.object({
  src: z.string().nullish(),
  image: z.string().nullish(),
  view: z.string().nullish(),
  id: z.number().nullish(),
});

export const productPayloadSchema = z.object({
  name: z.string().min(1),
  short_name: z.string().nullish(),
  style_number: z.string().nullish(),
  description: z.string().nullish(),
  color: z.string().nullish(),
  color_code: z.string().nullish(),
  color_man: z.string().nullish(),
  color_hex: z.string().nullish(),
  swatch_image: z.string().nullish(),
  vendor_category: z.string().nullish(),
  vendor_subcategory: z.string().nullish(),
  gender: z.string().nullish(),
  popularity: zodNullableNumber(z.number().nullish()),
  min_qty: zodNullableNumber(z.number().nullish()),
  style_group: z.string().nullish(),
  vendor: genericModelReferenceSchema,
  images: z.array(productImagePayloadSchema).nullish(),
  tags: z.array(genericModelReferenceSchema).nullish(),
  brand: genericModelReferenceSchema.nullish(),
  customer: genericModelReferenceSchema.nullish(),
  business: genericModelReferenceSchema.nullish(),
  preferred_decoration: decorationMethodSchema.nullish(),
  type: productTypeSchema.optional(),
  classification: itemClassificationSchema.optional(),
  is_quick_ship: z.boolean().optional(),
  default_show_on_invoice: z.boolean().optional(),
  default_can_apply_designs: z.boolean().optional(),
  default_is_purchasable: z.boolean().optional(),
  default_is_shippable: z.boolean().optional(),
  default_can_drop_ship: z.boolean().optional(),
});

export const productVariantPayloadSchema = z.object({
  size: z.string().min(1),
  price: z.coerce.number(),
  wholesale: zodNullableNumber(z.number().nullish()),
  value: zodNullableNumber(z.number().nullish()),
  cost: z.coerce.number(),
  sku: z.string().min(1).nullish(),
  mpn: z.string().nullish(),
  upc: z.string().nullish(),
  gtin: z.string().nullish(),
  weight: zodNullableNumber(z.number().nullish()),
  customs_descriptor: z.string().nullish(),
  sort_order: zodNullableNumber(z.number().int().nullish()),
  inventory_threshold: zodNullableNumber(z.number().int().nullish()),
  alert_level: zodNullableNumber(z.number().int().nullish()),
  par_level: zodNullableNumber(z.number().int().nullish()),
});

export const productionEventUpdatePayloadSchema = z.object({
  machine: genericModelReferenceSchema.optional(),
  quantity: z.coerce.number().optional(),
  priority: z.coerce.number().optional(),
});

export const productionMachineTimePayloadSchema = z.object({
  rate: z.coerce.number().int(),
  minutes_setup: z.coerce.number().int().optional(),
  num_colors: zodNullableNumber(z.number().int().nullish()),
  seconds_between_runs: z.coerce.number().int().optional(),
  id: z.number().nullish(),
});

export const projectFieldPayloadSchema = z.object({
  customer_project_field_id: z.number(),
  value: z.string().max(191).nullish(),
  id: z.number().nullish(),
});

export const variantAndQtySchema = z.object({
  variant_id: z.number(),
  qty: z.coerce.number().int(),
});

export const projectOptionVariantSchema = z.object({
  size: z.string().min(1),
  price: zodNullableNumber(z.number().nullish()),
  cost: zodNullableNumber(z.number().nullish()),
  variant_id: z.number().nullish(),
  create_sku: z.boolean().optional(),
});

export const adSpecialtyFeeSchema = z.object({
  name: z.string().min(1),
  price: z.coerce.number(),
  cost: zodNullableNumber(z.number().nullish()),
  qty: zodNullableNumber(z.number().int().nullish()),
});

export const decorationConfigSchema = z.object({
  decoration: z.string().nullish(),
  location: z.string().nullish(),
  complexity: zodNullableNumber(z.number().nullish()),
});

export const layoutConfigSchema = z.object({
  id: z.number().nullish(),
  name: z.string().nullish(),
  locations: z.array(decorationConfigSchema).optional(),
});

export const projectItemPayloadSchema = z.object({
  collection_id: z.number(),
  product_id: z.number().nullish(),
  notes: z.string().max(2000).nullish(),
  variants: z.array(variantAndQtySchema).nullish(),
  decoration: decorationConfigSchema.nullish(),
});

export const projectOptionPayloadSchema = z.object({
  project_item_id: z.number(),
  name: z.string().min(1),
  nickname: z.string().nullish(),
  type: decorationMethodSchema,
  description: z.string().min(1),
  images: z.array(z.string()),
  min_qty: z.coerce.number().min(1),
  estimated_qty: zodNullableNumber(z.number().min(1).nullish()),
  number: z.string().nullish(),
  color: z.string().nullish(),
  product_id: z.number().nullish(),
  vendor_id: z.number().nullish(),
  variants: z.array(projectOptionVariantSchema),
  fees: z.array(adSpecialtyFeeSchema),
  layout_config: layoutConfigSchema.nullish(),
  use_dynamic_pricing: z.boolean(),
  is_pinned: z.boolean().nullish(),
  target_margin: z.coerce.number().positive(),
  enable_color_options: z.boolean(),
  external_id: z.string().nullish(),
  external_source: z.string().nullish(),
  tags: z.record(z.boolean().optional()).nullish(),
  sample_order_id: z.number().nullish(),
  inventory_product_id: z.number().nullish(),
  create_inventory_product: z.boolean().optional(),
});

export const projectUpdatePayloadSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  budget_type: projectBudgetTypeSchema.optional(),
  budget_amount: z.coerce.number().optional(),
  budget_qty: z.coerce.number().optional(),
  user_id: z.number().optional(),
  in_hands_date: z.coerce.string().date().optional(),
  shipping_mode: shippingModeSchema.optional(),
  address: addressPayloadSchema.nullish(),
  attachments: z.array(attachmentPayloadSchema).nullish(),
  fields: z.array(projectFieldPayloadSchema).nullish(),
  type: projectTypeSchema.optional(),
});

export const purchaseOrderItemPayloadSchema = z.object({
  qty: z.coerce.number().int(),
  cost: z.coerce.number(),
  variant_id: z.number().nullish(),
  number: z.string().nullish(),
  color: z.string().nullish(),
  size: z.string().nullish(),
  description: z.string().nullish(),
  gl_account: genericModelReferenceSchema.nullish(),
  note: z.string().nullish(),
  inventory_unit_conversion: zodNullableNumber(z.number().nullish()),
  is_inventory: z.boolean().optional(),
  order_design_id: z.number().nullish(),
  order_item_id: z.number().nullish(),
});

export const purchaseOrderPayloadSchema = z.object({
  description: z.string().min(1).nullish(),
  vendor: genericModelReferenceSchema,
  business: genericModelReferenceSchema.nullish(),
  address: addressPayloadSchema.nullish(),
  discount_gl_account: genericModelReferenceSchema.nullish(),
  invoice_number: z.string().nullish(),
  vendor_contact: z.string().nullish(),
  issued_date: z.string().nullish(),
  requested_ship_date: z.string().nullish(),
  drop_dead_date: z.string().nullish(),
  expected_date: z.string().nullish(),
  fee_override: zodNullableNumber(z.number().nullish()),
  memo: z.string().nullish(),
  shipping: z.coerce.number().optional(),
  tax: z.coerce.number().optional(),
  discount: z.coerce.number().optional(),
  fee_include_discount: z.boolean().optional(),
  fee_is_sports_inc: z.boolean().optional(),
  party: shipToPartySchema.nullish(),
  design_layouts: z.array(genericModelReferenceSchema).nullish(),
});

export const purchaseOrderStatusPayloadSchema = z.object({
  factory_order_number: z.string().min(1),
  status_id: z.coerce.number(),
  expected_ship_date: z.string().nullish(),
  trackings: z.array(
    z.object({
      id: z.number().nullish(),
      tracking_number: z.string().nullish(),
      carrier: z.string().min(1),
    }),
  ),
  items: z.array(
    z.object({
      id: z.number().nullish(),
      mpn: z.string().min(1),
      qty: z.coerce.number().int(),
    }),
  ),
});

export const rebateCreatePayloadSchema = z.object({
  order: genericModelReferenceSchema,
  calculation_method: rebateCalculationMethodSchema,
});

export const rebateUpdatePayloadSchema = z.object({
  amount: z.coerce.number(),
  credit_amount: z.coerce.number(),
  address: addressPayloadSchema.nullish(),
  vendor: genericModelReferenceSchema.nullish(),
});

export const receivingItemPayloadSchema = z.object({
  purchase_order_item_id: z.number(),
  qty_received: z.coerce.number().int(),
});

export const receivingPayloadSchema = z.object({
  items: z.array(receivingItemPayloadSchema),
  images: z.array(z.string()).nullish(),
  barcodes: z.array(z.string()).nullish(),
  order_id: z.number().nullish(),
  purchase_order_id: z.number().nullish(),
});

export const reportPayloadSchema = z.object({
  name: z.string().min(1),
  title: z.string().nullish(),
  roles: z.array(genericModelReferenceSchema).nullish(),
  description: z.string().nullish(),
  group: z.string().nullish(),
  class: z.string().nullish(),
  query: z.string().nullish(),
  summary: z.string().nullish(),
  columns: z.array(z.any()).nullish(),
  filters: z.array(z.any()).nullish(),
  is_global: z.boolean().optional(),
  type: z.string().optional(),
});

export const scrapPayloadSchema = z.object({
  is_scrap: z.boolean(),
  scrap_owner: costCategorySchema.nullish(),
});

export const singleAddressPayloadSchema = z.object({
  address: addressPayloadSchema,
});

export const parcelSchema = z.object({
  length: z.coerce.number(),
  width: z.coerce.number(),
  height: z.coerce.number(),
  weight: z.coerce.number(),
});
export const customsItemPayloadSchema = z.object({
  description: z.string().min(1),
  quantity: z.coerce.number().int(),
  net_weight: z.coerce.number(),
  value_amount: z.coerce.number(),
});

export const customsPayloadSchema = z.object({
  incoterm: z.string().min(1),
  items: z.array(customsItemPayloadSchema),
});

export const shipmentItemPayloadSchema = z.object({
  kit_id: z.number().nullish(),
  order_item_id: z.number().nullish(),
  qty_shipped: z.number().int().default(1),
});

export const shipmentBasePayloadSchema = z.object({
  shippable_id: z.number(),
  shippable_type: z.union([
    z.literal("order"),
    z.literal("fulfillment_order"),
    z.literal("return"),
  ]),
  address_id: z.number().nullish(),
  inventory_pick_id: z.number().nullish(),
  items: z.array(shipmentItemPayloadSchema).optional(),
});

export const shipmentCreatePayloadSchema = shipmentBasePayloadSchema.extend({
  rate_id: z.string().nullish(),
  tracking_number: z.string().nullish(),
  carrier: z.string().nullish(),
  cost: zodNullableNumber(z.number().nullish()),
  file: z.string().url().nullish(),
  label_type: z.string().optional(),
});

export const shipmentQuotePayloadSchema = shipmentBasePayloadSchema.extend({
  from_address_id: z.number().nullish(),
  third_party_account_id: z.number().nullish(),
  service_level: z.string().nullish(),
  provider: z.string().nullish(),
  require_signature: z.boolean().nullish(),
  reference_1: z.string().nullish(),
  reference_2: z.string().nullish(),
  customs: customsPayloadSchema.nullish(),
  parcels: z.array(parcelSchema),
  items: z.array(shipmentItemPayloadSchema).optional(),
});

export const customerThirdPartyAccountPayloadSchema = z.object({
  name: z.string().min(1),
  carrier: z.string().min(1),
  account_number: z.string().min(1),
  zip: z.string().nullish(),
  country: z.string().length(2).nullish(),
});

export const transactionItemPayloadSchema = z.object({
  amount: z.coerce.number(),
  description: z.string().nullish(),
  transactable: genericModelReferenceSchema,
  id: z.number().nullish(),
});

export const transactionPayloadSchema = z.object({
  type: transactionTypeSchema,
  transaction_date: z.string().date(),
  vendor: z
    .object({
      id: z.number(),
      default_payment_method: z.string().nullable(),
      default_accounts: z.array(genericModelReferenceSchema).optional(),
    })
    .nullish(),
  bank_account: genericModelReferenceSchema.nullish(),
  terms: genericModelReferenceSchema.nullish(),
  purchase_order: genericModelReferenceSchema.nullish(),
  items: z.array(transactionItemPayloadSchema).nullish(),
  memo: z.string().nullish(),
  ref_number: z.string().nullish(),
  po_number: z.string().nullish(),
  due_date: z.string().nullish(),
  check_payee: z.string().nullish(),
  payment_method: vendorPaymentMethodSchema.nullish(),
  rebate_id: z.number().nullish(),
});

export const userPoolPayloadSchema = z.object({
  id: z.number().nullish(),
  pool: z.string().min(1),
});

export const vendorContactPayloadSchema = z.object({
  id: z.number().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  title: z.string().nullish(),
  department: z.string().nullish(),
});

export const vendorPayloadSchema = z.object({
  name: z.string().min(1),
  code: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  url: z.string().url().nullish(),
  auto_po_email: z.string().email().nullish(),
  ppai_number: z.string().nullish(),
  asi_number: z.string().nullish(),
  account_number: z.string().nullish(),
  notes_internal: z.string().nullish(),
  notes_to_purchasing: z.string().nullish(),
  notes_requirements: z.string().nullish(),
  days_to_receive: zodNullableNumber(z.number().nullish()),
  default_payment_method: vendorPaymentMethodSchema.nullish(),
  bank_account_number: z.string().nullish(),
  bank_routing_number: z.string().nullish(),
  tin: z.string().nullish(),
  form_1099_type: z.string().nullish(),
  terms: genericModelReferenceSchema.nullish(),
  contacts: z.array(vendorContactPayloadSchema).nullish(),
  default_accounts: z.array(genericModelReferenceSchema).nullish(),
  is_sports_inc: z.boolean().optional(),
});

export const addToCartRequestSchema = z.object({
  product_id: z.number(),
  collection: z.string().nullish(),
  variants: z.array(variantAndQtySchema),
  design_layout_id: z.number().nullish(),
  decoration: decorationConfigSchema.nullish(),
});

export const customerPayloadSchema = z.object({
  name: z.string().min(1),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  website: z.string().nullish(),
  logo: z.string().nullish(),
  color: z.string().nullish(),
  drawer_color: z.string().nullish(),
  portal_shopping_mode: shoppingModeSchema.nullish(),
  blank_products: z.array(genericModelReferenceSchema).nullish(),
  blank_vendors: z.array(genericModelReferenceSchema).nullish(),
  blank_discount: zodNullableNumber(z.number().nullish()),
  target_margin: zodNullableNumber(z.number().nullish()),
  inbound_freight_percent: zodNullableNumber(z.number().nullish()),
  outbound_freight_percent: zodNullableNumber(z.number().nullish()),
  credit_card_limit: zodNullableNumber(z.number().nullish()),
  sku_prefix: z.string().nullish(),
  terms: genericModelReferenceSchema.nullish(),
  sales_rep: genericModelReferenceSchema.nullish(),
  customer_segment: genericModelReferenceSchema.nullish(),
  franchise_parent: genericModelReferenceSchema.nullish(),
  business: genericModelReferenceSchema.nullish(),
  commissions: z.array(commissionPayloadSchema).nullish(),
  fulfillment_prices: z.array(customerFulfillmentPricePayloadSchema).nullish(),
  brand_colors: z.array(customerBrandColorPayloadSchema).nullish(),
  brand_files: z.array(customerBrandFilePayloadSchema).nullish(),
  project_fields: z.array(customerProjectFieldPayloadSchema).nullish(),
  mail_invoices: z.boolean().optional(),
  exempt_from_pms_charges: z.boolean().optional(),
});

export const designLayoutPayloadSchema = z.object({
  name: z.string().nullish(),
  design_layout_id: z.number().nullish(),
  subcontractor: genericModelReferenceSchema.nullish(),
  exempt_from_proofs: z.boolean().optional(),
  sew_out_requested: z.boolean().optional(),
  exclude_from_closet: z.boolean().optional(),
  designs: z.array(designPayloadSchema).nullish(),
  bring_proofs: z.boolean().optional(),
});

export const orderCreatePayloadSchema = z.object({
  customer_id: z.number(),
  order_type_id: z.number(),
  description: z.string().min(1),
  department_id: z.number().nullish(),
  terms_id: z.number().nullish(),
  shipping: z.coerce.number().optional(),
  inbound_freight: z.coerce.number().optional(),
  project_id: z.number().nullish(),
  webstore_parent_id: z.number().nullish(),
  parent_id: z.number().nullish(),
  total_qty: z.number().nullish(),
  external_id: z.union([z.string().min(1), z.number()]).nullish(),
  external_source: z.string().nullish(),
  contact_first_name: z.string().nullish(),
  contact_last_name: z.string().nullish(),
  contact_email: z.string().email().nullish(),
  contact_phone: z.string().nullish(),
  contact_title: z.string().nullish(),
  customer_po: z.string().nullish(),
  memo: z.string().nullish(),
  earliest_ship_date: z.string().nullish(),
  drop_dead_date: z.string().nullish(),
  shipping_mode: shippingModeSchema.optional(),
  no_commissions: z.boolean().optional(),
  billing_address_id: z.number().nullish(),
  rep_id: z.number().nullish(),
  business_id: z.number().nullish(),
  shipping_address: addressPayloadSchema.nullish(),
  items: z.array(orderItemPayloadSchema).nullish(),
  addresses: z.array(addressPayloadSchema).nullish(),
  notes: z.array(notePayloadSchema).nullish(),
  attachments: z.array(attachmentPayloadSchema).nullish(),
  costs: z.array(orderCostPayloadSchema).nullish(),
  design_layouts: z.array(designLayoutPayloadSchema).nullish(),
  bypass_hold: z.boolean().optional(),
});

export const orderItemBulkCreateSchema = z.object({
  items: z.array(orderItemPayloadSchema),
});

export const productionMachinePayloadSchema = z.object({
  name: z.string().min(1),
  max_heads: zodNullableNumber(z.number().int().nullish()),
  event_types: z.array(genericModelReferenceSchema).nullish(),
  times: z.array(productionMachineTimePayloadSchema).nullish(),
});

export const projectCreatePayloadSchema = z.object({
  name: z.string().min(1),
  description: z.string().max(2000),
  budget_type: projectBudgetTypeSchema,
  budget_amount: z.coerce.number(),
  budget_qty: z.coerce.number().int(),
  in_hands_date: z.coerce.string().date(),
  shipping_mode: shippingModeSchema,
  address: addressPayloadSchema.nullish(),
  items: z.array(projectItemPayloadSchema),
  attachments: z.array(attachmentPayloadSchema).optional(),
  fields: z.array(projectFieldPayloadSchema).optional(),
  type: projectTypeSchema.optional(),
  cart_id: z.number().nullish(),
});

export const userPayloadSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  title: z.string().nullish(),
  access_level: zodNullableNumber(z.number().nullish()),
  avatar: z.string().nullish(),
  business: genericModelReferenceSchema.nullish(),
  account_managers: z.array(genericModelReferenceSchema).nullish(),
  roles: z.array(genericModelReferenceSchema).nullish(),
  groups: z.array(genericModelReferenceSchema).nullish(),
  artist: genericModelReferenceSchema.nullish(),
  commission_model: genericModelReferenceSchema.nullish(),
  pools: z.array(userPoolPayloadSchema).nullish(),
  taggable: z.boolean().optional(),
  commission_percent: z.coerce.number().optional(),
});

export const giftingProductReference = z.object({
  id: z.number(),
  name: z.string(),
  fulfillment_type: giftingProductTypeSchema,
  images: z.array(z.string()),
  min_price: z.number(),
  max_price: z.number(),
});

export const giftItemPayloadSchema = z.object({
  product: giftingProductReference,
});

export const giftPayloadSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  company: z.string().nullish(),
  email: z.string().email(),
  subject: z.string().min(1).nullish(),
  message: z.string().min(1).nullish(),
  should_send: z.boolean(),
  external_id: z.coerce.string().nullish(),
  external_source: integrationTypeSchema.nullish(),
  address: addressPayloadSchema.nullish(),
  items: z.array(giftItemPayloadSchema).min(1),
  gift_note: z.string().nullish(),
  queue: z.string().optional(),
  sender_id: z.number().nullish(),
  send_as: z.number().int().optional(),
});

export const giftingVariantPayloadSchema = z.object({
  kit_product_id: z.number().nullish(),
  variant_id: z.number().nullish(),
  inventory_variant_id: z.number().nullish(),
  size: z.string().nullish(),
  price: z.coerce.number().nullish(),
  cost: z.coerce.number().nullish(),
  hide: z.boolean().optional(),
});

export const giftingProductPayloadSchema = z.object({
  name: z.string().min(1),
  images: z.array(z.string()),
  type: giftingProductTypeSchema,
  is_active: z.boolean().optional(),
  description: z.string().nullish(),
  kit_product_limit: zodNullableNumber(z.number().int().nullish()),
  kit_price_limit: zodNullableNumber(z.number().nullish()),
  inventory_product_id: z.number().nullish(),
  product_id: z.number().nullish(),
  number: z.string().nullish(),
  color: z.string().nullish(),
  vendor_id: z.number().nullish(),
  design_layout_id: z.number().nullish(),
  tango_utid: z.string().nullish(),
  variants: z.array(giftingVariantPayloadSchema),
});

export const giftingTemplatePayloadSchema = z.object({
  name: z.string().min(1),
  field: z.string().min(1),
  template: z.string().min(1),
});

export const giftingImagePayloadSchema = z.object({
  name: z.string().min(1),
  image: z.string().min(1),
});

export const giftRecipientPayloadSchema = giftPayloadSchema.pick({
  first_name: true,
  last_name: true,
  company: true,
  email: true,
  address: true,
  external_id: true,
  external_source: true,
});

export const giftBulkCreatePayloadSchema = giftPayloadSchema
  .pick({
    should_send: true,
    items: true,
    queue: true,
    sender_id: true,
    send_as: true,
  })
  .extend({
    subject_template: z.string().min(1).nullish(),
    message_template: z.string().min(1).nullish(),
    gift_note_template: z.string().nullish(),
    recipients: z.array(giftRecipientPayloadSchema).min(1),
  });

export const customerTeamPayloadSchema = z.object({
  name: z.string().min(1),
  budget: z.coerce.number(),
  interval: intervalSchema,
  fiscal_month: z.number().int().min(1).max(12),
  timezone: z.string().min(1).optional(),
  is_default: z.boolean().optional(),
});
