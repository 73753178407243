import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as fabric from "fabric";
import { useDropzone } from "react-dropzone";
import { Attachment } from "@/types";
import { Button } from "@/components/ui/button";
import {
  CornerLeftDown,
  CornerRightDown,
  ImageIcon,
  ImageOff,
  Loader,
  PaintBucket,
  UploadIcon,
} from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { imageUrl } from "@/lib/image";
import {
  useGetFabricRequest,
  useSaveFabricRequest,
  useUploadRequest,
} from "@/api/upload";
import EmptyState from "@/components/ui/empty-state";

const MAX_CANVAS_SIZE = 800;

const getCanvasDimensionsForImage = (img: fabric.Image) => {
  let canvasSize = MAX_CANVAS_SIZE;
  const ih = img.height || 0;
  const iw = img.width || 0;

  if (ih < MAX_CANVAS_SIZE && iw < MAX_CANVAS_SIZE) {
    canvasSize = Math.max(ih, iw);
  }

  if (ih > iw) {
    const ratio = canvasSize / ih;
    return [iw * ratio, canvasSize];
  }
  const ratio = canvasSize / iw;
  return [canvasSize, ih * ratio];
};

function dataURItoBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0]!.indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]!);
  else byteString = unescape(dataURI.split(",")[1]!);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0]!.split(":")[1]!.split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function ImageEditor({
  onSave,
  image,
  designImages,
}: {
  onSave: (newUrl: string) => void;
  image: string;
  designImages?: Attachment[];
}) {
  const uploadRequest = useUploadRequest();
  const getFabricRequest = useGetFabricRequest(image);
  const saveFabricRequest = useSaveFabricRequest();

  const [loading, setLoading] = useState(false);
  const fabricInstance = useRef<fabric.Canvas>();

  const onKeyUp: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const canvas = fabricInstance.current as fabric.Canvas;
      const { key } = e;
      if (key === "Backspace" || key === "Delete") {
        e.preventDefault();
        const obj = canvas.getActiveObject();
        if (obj) {
          canvas.remove(obj);
        }
      }
    },
    [fabricInstance],
  );

  const initCanvas = useCallback(
    (prevCanvasState: string | null) => {
      setLoading(true);
      const canvas = new fabric.Canvas("c", {
        width: MAX_CANVAS_SIZE,
        height: MAX_CANVAS_SIZE,
      });
      fabricInstance.current = canvas;

      if (prevCanvasState) {
        canvas
          .loadFromJSON(prevCanvasState, (_, object) => {
            if (object) {
              // @ts-ignore
              canvas.setActiveObject(object);
            }
          })
          .then(() => {
            const { backgroundImage } = canvas;
            if (backgroundImage instanceof fabric.Image) {
              if (
                backgroundImage.height &&
                backgroundImage.scaleY &&
                backgroundImage.width &&
                backgroundImage.scaleX
              ) {
                canvas.setDimensions({
                  height: backgroundImage.height * backgroundImage.scaleY,
                  width: backgroundImage.width * backgroundImage.scaleX,
                });
              }
            }
            canvas.renderAll();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const imageUrl = image.includes("files.availerp.com")
          ? image
          : `https://availerp.com/cors/${encodeURIComponent(image)}`;
        fabric.Image.fromURL(
          imageUrl,
          { crossOrigin: "anonymous" },
          { left: 0, top: 0 },
        )
          .then((img) => {
            img.set({
              left: 0,
              top: 0,
            });
            const [width, height] = getCanvasDimensionsForImage(img);
            canvas.setDimensions({
              height,
              width,
            });
            canvas.backgroundImage = img;
            canvas.backgroundImage.scaleX = canvas.width / img.width!;
            canvas.backgroundImage.scaleY = canvas.height / img.height!;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [fabricInstance, setLoading],
  );

  useEffect(() => {
    if (getFabricRequest.status !== "loading") {
      let prevState = getFabricRequest.data || null;
      if (!prevState) {
        prevState = localStorage.getItem(`image-editor-${image}`);
      }
      initCanvas(prevState);
    }
  }, [initCanvas, image, getFabricRequest.status]);

  const skew = (amount: number) => {
    const activeObject = fabricInstance.current?.getActiveObject();
    if (activeObject) {
      const updateTo = (activeObject.skewY || 0) + amount;
      activeObject.set("skewY", updateTo).setCoords();
      fabricInstance.current?.requestRenderAll();
    }
  };

  const handleFillChange = () => {
    const activeObject = fabricInstance.current?.getActiveObject();
    const color = prompt("What hex color?");
    if (color && activeObject) {
      activeObject.set("fill", color);
      if (activeObject instanceof fabric.Group) {
        activeObject._objects.forEach((obj) => {
          obj.set("fill", color);
        });
      }
      fabricInstance.current?.requestRenderAll();
    }
  };

  const addImage = useCallback(
    (url: string) => {
      const canvas = fabricInstance.current as fabric.Canvas;
      setLoading(true);
      const promise =
        url.split("?")[0]!.endsWith(".svg") || url.startsWith("data:image/svg")
          ? fabric
              .loadSVGFromURL(url, undefined, {
                crossOrigin: "anonymous",
              })
              .then(({ objects }) => {
                // @ts-ignore
                return fabric.util.groupSVGElements(objects);
              })
          : fabric.Image.fromURL(
              url,
              { crossOrigin: "anonymous" },
              { left: 100, top: 100 },
            );

      promise.then((img) => {
        img.set({
          left: 100,
          top: 100,
        });
        img.scaleToHeight(100);
        img.scaleToWidth(100);

        // Add to Canvas, then make it active
        canvas.add(img);
        canvas.setActiveObject(img);
        setLoading(false);
      });
    },
    [setLoading, fabricInstance],
  );
  const readFile = useCallback((file: File) => {
    const a = new FileReader();
    a.onload = (e) => {
      if (typeof e.target?.result === "string") {
        addImage(e.target.result);
      }
    };
    a.readAsDataURL(file);
  }, []);

  const onUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        readFile(e.target.files[0]!);
      }
    },
    [readFile],
  );

  const handleSaveClick = () => {
    if (fabricInstance.current) {
      fabricInstance.current!.discardActiveObject();
      const dataURL = fabricInstance.current!.toDataURL({
        format: "png",
        quality: 1,
        multiplier: 2,
      });
      const blob = dataURItoBlob(dataURL);
      const fabricState = JSON.stringify(fabricInstance.current!.toJSON());
      uploadRequest.mutateAsync(blob).then((data) => {
        saveFabricRequest.mutate({
          path: data.file,
          fabric: fabricState,
        });
        onSave(data.url);
      });
    }
  };

  const onDrop = useCallback(
    (files: File[]) => {
      readFile(files[0]!);
    },
    [readFile],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    noClick: true,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyUp={onKeyUp}
      style={{
        width: "100%",
        height: "calc(100vh - 100px)",
      }}
    >
      <div className="mb-4 flex gap-2">
        <Button size="sm" asChild variant="outline">
          <label>
            <input
              type="file"
              onChange={onUpload}
              style={{ display: "none" }}
              accept="image/*"
            />
            Upload <UploadIcon className="ml-2 size-4" />
          </label>
        </Button>
        <Button
          onClick={() => skew(-1)}
          size="sm"
          variant="outline"
          disabled={!fabricInstance.current?.getActiveObject()}
        >
          Skew Left <CornerLeftDown className="ml-2 size-4" />
        </Button>
        <Button
          onClick={() => skew(1)}
          size="sm"
          variant="outline"
          disabled={!fabricInstance.current?.getActiveObject()}
        >
          Skew Right <CornerRightDown className="ml-2 size-4" />
        </Button>
        {fabricInstance.current?.getActiveObject() instanceof fabric.Group && (
          <Button onClick={handleFillChange} size="sm" variant="outline">
            Change Color <PaintBucket className="ml-2 size-4" />
          </Button>
        )}
        <Button
          isLoading={uploadRequest.isLoading}
          onClick={handleSaveClick}
          variant="default"
          size="sm"
        >
          Save
        </Button>
        {loading && <Loader className="size-4 animate-spin" />}
      </div>

      <div className="flex">
        <div id="canvasColumn" className="flex-grow">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <canvas style={{ border: "1px solid #eee" }} id="c" />
          </div>
        </div>
        <div id="imagesColumn" className="w-88 px-3">
          {designImages && designImages.length > 0 ? (
            <div>
              <h3 className="mb-2 font-medium">Saved Image Files</h3>
              <ul className="-mx-1 space-y-2">
                {designImages.map((att) => (
                  <li key={att.url}>
                    <button
                      className="flex w-full items-center gap-4 px-3 py-1 text-left text-sm hover:bg-background"
                      onClick={() => addImage(att.url)}
                    >
                      <Avatar>
                        <AvatarImage src={imageUrl(att.url, { w: 200 })} />
                        <AvatarFallback>
                          <ImageIcon />
                        </AvatarFallback>
                      </Avatar>

                      <div>{att.name || att.file}</div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <EmptyState
              title="No Saved Images"
              description="Unable to find any images from existing design layout or brand guidelines."
              Icon={ImageOff}
              className="mt-8"
            />
          )}
        </div>
      </div>
    </div>
  );
}
