import { Gift, GiftStatus } from "@/types";
import { Badge, BadgeProps } from "@/components/ui/badge";
import startCase from "lodash/startCase";

function getGiftStatusVariant(status: GiftStatus): BadgeProps["variant"] {
  switch (status) {
    case "pending":
      return "amber";
    case "email_failed":
      return "destructive";
    case "viewed":
      return "blue";
    case "redeemed":
      return "indigo";
    case "shipped":
    case "delivered":
      return "green";
    case "cancelled":
      return "destructive";
    default:
      return "outline";
  }
}

export default function GiftStatusBadge({ gift }: { gift: Gift }) {
  return (
    <Badge variant={getGiftStatusVariant(gift.status)}>
      {startCase(gift.status)}
    </Badge>
  );
}
