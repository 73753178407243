import { Project } from "@/types";
import { Badge } from "@/components/ui/badge";
import pluralize from "pluralize";
import { differenceInDays } from "date-fns";

export default function ProjectDueBadge({ project }: { project: Project }) {
  const dueDate =
    project.due_date && project.balance ? new Date(project.due_date) : null;

  const now = new Date();

  if (project.balance === 0) {
    return null;
  }

  if (dueDate && dueDate < now) {
    return (
      <Badge variant="destructive">
        Payment Due {pluralize("Day", differenceInDays(now, dueDate), true)} Ago
      </Badge>
    );
  }

  if (dueDate && dueDate > now) {
    return (
      <Badge variant="outline">
        Payment Due in {pluralize("Day", differenceInDays(dueDate, now), true)}
      </Badge>
    );
  }
  return null;
}
