import { GiftLink } from "@/types";

export default function GiftSignature(
  gift: Pick<GiftLink, "sender" | "sender_name" | "customer">,
) {
  const isMyself = gift.sender_name.includes(gift.sender.name);

  return (
    <div className="flex items-center gap-3">
      {isMyself && gift.sender.avatar ? (
        <img
          src={gift.sender.avatar}
          className="size-10 rounded-full object-cover"
        />
      ) : gift.customer.logo ? (
        <img
          src={gift.customer.logo}
          className="size-12 rounded-full object-contain"
        />
      ) : null}
      <div className="min-w-0 flex-grow">
        {isMyself ? (
          <>
            <p>{gift.sender_name}</p>
            {gift.sender.title && (
              <p className="text-muted-foreground">{gift.sender.title}</p>
            )}
            <p className="text-sm">
              <a href={`mailto:${gift.sender.email}`} className="text-primary">
                {gift.sender.email}
              </a>
            </p>
          </>
        ) : (
          <p>{gift.sender_name}</p>
        )}
      </div>
    </div>
  );
}
