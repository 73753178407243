import { CartProduct } from "@/types";
import { curr } from "@/lib/curr";
import { Badge } from "@/components/ui/badge";
import { useIsShopping } from "@/context/ShopContext";

interface ProductHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  product: CartProduct;
}

export default function ProductHeader({
  product,
  ...props
}: ProductHeaderProps) {
  const isShopping = useIsShopping();
  return (
    <div {...props}>
      <Badge>{product.decoration_method.replace("_", " ").toUpperCase()}</Badge>
      <h3 className="mb-2 mt-2 text-3xl font-semibold">{product.name}</h3>
      {isShopping ? (
        <div className="mb-2 text-lg">{curr(product.price)}</div>
      ) : (
        <div className="mb-2 text-lg">As low as {curr(product.min_price)}</div>
      )}
      <ul className="flex flex-wrap gap-x-3 text-sm text-muted-foreground">
        <li>
          {product.vendor} Style #{product.style_number}
        </li>
        {product.color && (
          <>
            <li>&bull;</li>
            <li>
              <span>Color: {product.color}</span>
              {product.color_code &&
                product.color_code !== product.style_number &&
                !product.color.includes(product.color_code) && (
                  <span> ({product.color_code})</span>
                )}
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
