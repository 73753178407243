import { PencilIcon } from "lucide-react";
import { ProjectOption } from "@/types";
import ProjectOptionDuplicateButton from "@/components/projects/ProjectOptionDuplicateButton";
import ProjectOptionArchiveButton from "@/components/projects/ProjectOptionArchiveButton";
import ProjectOptionSampleOrderDialog from "@/components/projects/ProjectOptionSampleOrderDialog";
import Gate from "@/components/roles/Gate";

export default function ProjectOptionGroupMenu({
  onEditOption,
  option,
}: {
  onEditOption: (o: ProjectOption) => void;
  option: ProjectOption;
}) {
  return (
    <div
      className="invisible absolute right-3 top-3 flex space-x-3 group-hover:visible"
      onClick={(e) => e.stopPropagation()}
    >
      <Gate role={["avail", "avail_incognito"]}>
        <button type="button" onClick={() => onEditOption(option)}>
          <PencilIcon className="size-5 text-muted-foreground" />
        </button>
        <ProjectOptionDuplicateButton option={option} />
        <ProjectOptionSampleOrderDialog option={option} />
      </Gate>
      <ProjectOptionArchiveButton option={option} />
    </div>
  );
}
