import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { CustomerMembership } from "@/types";
import { useUpdateMembershipRequest } from "@/api/membership";
import { useUser } from "@/context/AppContext";
import startCase from "lodash/startCase";
import Gate from "@/components/roles/Gate";

export default function MembershipRole({
  membership,
  canInvite = true,
}: {
  membership: CustomerMembership;
  canInvite?: boolean;
}) {
  const updateMembershipRequest = useUpdateMembershipRequest();
  const user = useUser();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={!canInvite || membership.user.id === user.id}
      >
        <Badge variant="gray">{startCase(membership.role)}</Badge>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuRadioGroup
          value={membership.role}
          onValueChange={(role) =>
            updateMembershipRequest.mutate({
              id: membership.id,
              role,
            })
          }
        >
          <Gate role={["avail", "avail_incognito"]}>
            <DropdownMenuRadioItem
              value="avail_incognito"
              children="Avail Incognito"
            />
            <DropdownMenuRadioItem value="avail" children="Avail" />
            <DropdownMenuSeparator />
          </Gate>
          <DropdownMenuRadioItem value="admin" children="Admin" />
          <DropdownMenuRadioItem value="member" children="Member" />
          <DropdownMenuRadioItem value="guest" children="Guest" />
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
