import { CustomerQuote } from "@/types";
import { Button } from "@/components/ui/button";
import { useAcceptQuote, useDeclineQuote } from "@/api/quotes";
import { Badge } from "@/components/ui/badge";
import startCase from "lodash/startCase";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";

export default function QuoteActions({ quote }: { quote: CustomerQuote }) {
  const acceptRequest = useAcceptQuote(quote.code);
  const declineRequest = useDeclineQuote(quote.code);
  const [reason, setReason] = useState("");

  if (quote.status !== "open") {
    return (
      <Badge variant={quote.status === "declined" ? "red" : "green"}>
        {startCase(quote.status)}
      </Badge>
    );
  }

  return (
    <>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="default">Accept</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Accept Quote</AlertDialogTitle>
            <AlertDialogDescription>
              This will convert the quote to an order.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              variant="default"
              onClick={() => acceptRequest.mutate()}
              isLoading={acceptRequest.isLoading}
            >
              Accept
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="outline">Decline</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              declineRequest.mutateAsync({ reason });
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle>Decline Quote</AlertDialogTitle>
            </AlertDialogHeader>
            <Textarea
              placeholder="Please provide a reason for declining the quote"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="my-4"
            />
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button
                type="submit"
                variant="destructive"
                isLoading={declineRequest.isLoading}
              >
                Decline
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
