import { Pencil, Tags, Trash } from "lucide-react";
import { Link } from "react-router-dom";
import ConfirmationButton from "@/components/ui/confirmation-button";
import { ProjectItem } from "@/types";
import { useDeleteItem } from "@/api/projects";
import { useProject } from "@/context/ProjectContext";
import { canEdit, getItemImage, getItemName } from "@/lib/projects";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { imageUrl } from "@/lib/image";
import { CardTitle } from "@/components/ui/card";
import EnhancedText from "@/components/ui/enhanced-text";

export default function ProjectItemRow({ item }: { item: ProjectItem }) {
  const project = useProject();
  const deleteItem = useDeleteItem(item.id);

  return (
    <div className="group relative">
      <div className="flex items-start gap-4">
        <Avatar className="size-10 border">
          <AvatarImage src={imageUrl(getItemImage(item), { w: 100 })} />
          <AvatarFallback>
            <Tags className="size-6" />
          </AvatarFallback>
        </Avatar>
        <div>
          <CardTitle className="mb-2 text-lg font-semibold">
            {getItemName(item)}
          </CardTitle>
          <EnhancedText className="text-sm text-muted-foreground">
            {item.notes || "There are no notes for this item"}
          </EnhancedText>
        </div>

        {canEdit(project) && (
          <div className="ml-auto flex gap-3 print:hidden">
            <Link to={`./edit-item/${item.id}`}>
              <Pencil className="size-4 text-muted-foreground" />
            </Link>
            {project.items.length > 1 && (
              <ConfirmationButton
                isLoading={deleteItem.isLoading}
                onClick={() => deleteItem.mutate()}
                title="Delete Project Item"
              >
                <Trash className="size-4 text-muted-foreground" />
              </ConfirmationButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
