import { useState } from "react";
import { XIcon } from "lucide-react";
import { useDeleteAddress } from "@/api/addresses";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Address } from "@/types";
import { useToast } from "@/components/ui/use-toast";
import AddressBlock from "@/components/addresses/AddressBlock";

export default function AddressDeleteDialog({ address }: { address: Address }) {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const deleteRequest = useDeleteAddress();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <XIcon className="size-5 text-slate-500" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Address</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want delete this address?
            <AddressBlock address={address} className="mt-4" />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={deleteRequest.isLoading}
            onClick={() =>
              deleteRequest.mutateAsync(address.id).then(() => {
                setOpen(false);
                toast({
                  title: "Successfully deleted address",
                  variant: "success",
                });
              })
            }
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
