import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Webstore } from "@/types";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { curr } from "@/lib/curr";
import { ArrowRight } from "lucide-react";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { formatDate } from "@/lib/dates";

export default function WebstoreDetails({ webstore }: { webstore: Webstore }) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="link" size="sm">
          View details <ArrowRight className="ml-2 size-4" />
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-y-auto sm:max-w-2xl">
        <SheetHeader>
          <SheetTitle>{webstore.name}</SheetTitle>
        </SheetHeader>

        {webstore.orders.length > 0 && (
          <Table className="mt-6">
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>
                  <SimpleTooltip tooltip="Total credit card payments we processed on the store">
                    Payments
                  </SimpleTooltip>
                </TableHead>
                <TableHead>
                  <SimpleTooltip tooltip="Sales tax collected from store orders that we owe to the state">
                    Sales Tax
                  </SimpleTooltip>
                </TableHead>
                <TableHead>
                  <SimpleTooltip tooltip="All fees charged to run this store and fulfillment operation (storage, picking, shipping, merchant fees, etc.)">
                    Fulfillment Fees
                  </SimpleTooltip>
                </TableHead>
                <TableHead>
                  <SimpleTooltip tooltip="Payments - Sales Tax - Fulfillment Fees = Rebate">
                    Rebate
                  </SimpleTooltip>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {webstore.orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{formatDate(order.created_at)}</TableCell>
                  <TableCell>{curr(order.revenue)}</TableCell>
                  <TableCell>{curr(order.sales_tax)}</TableCell>
                  <TableCell>{curr(order.fulfillment_fees)}</TableCell>
                  <TableCell>{curr(order.rebate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter className="border-t bg-muted text-muted-foreground">
              <TableRow className="font-semibold">
                <TableCell></TableCell>
                <TableCell>{curr(webstore.revenue)}</TableCell>
                <TableCell>{curr(webstore.sales_tax)}</TableCell>
                <TableCell>{curr(webstore.fulfillment_fees)}</TableCell>
                <TableCell>{curr(webstore.rebate)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </SheetContent>
    </Sheet>
  );
}
