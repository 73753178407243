import { CartItem } from "@/types";
import { imageUrl } from "@/lib/image";
import { Loader, ShoppingBag, XIcon } from "lucide-react";
import { useRemoveItem } from "@/api/cart";
import EmptyState from "@/components/ui/empty-state";

export default function CartItems({ items }: { items: CartItem[] }) {
  const removeItemRequest = useRemoveItem();

  return (
    <div className="relative mt-6 flex-1 space-y-4 overflow-y-auto">
      {removeItemRequest.isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <Loader className="size-10 animate-spin text-muted-foreground" />
        </div>
      )}

      {items.map((item) => (
        <div key={item.id} className="flex items-center gap-3">
          {item.product.image && (
            <img
              alt=""
              src={imageUrl(item.product.image, {
                w: 100,
                h: 100,
                fit: "contain",
              })}
              className="flex-0 size-14 object-contain"
            />
          )}
          <div className="flex-1 text-sm">
            <div className="mb-1 font-medium">{item.product.name}</div>
            <div className="text-muted-foreground">Quantity: {item.qty}</div>
          </div>
          <div className="flex-0 ml-auto">
            <button
              type="button"
              disabled={removeItemRequest.isLoading}
              onClick={() => removeItemRequest.mutate(item.id)}
            >
              <XIcon className="size-4 text-muted-foreground" />
            </button>
          </div>
        </div>
      ))}

      {items.length === 0 && (
        <EmptyState
          Icon={ShoppingBag}
          title="Your cart is empty"
          description="Add a product to your cart to get started."
        />
      )}
    </div>
  );
}
