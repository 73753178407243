import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerBrandFile, CustomerBrandFilePayload } from "@/types";
import { useAppContext } from "@/context/AppContext";

export const BRAND_FILES_KEY = "brandFiles";

export function useGetCustomerBrandFiles() {
  const { membership } = useAppContext();
  return useQuery(
    [BRAND_FILES_KEY, "list"],
    () =>
      axios
        .get<{ data: CustomerBrandFile[] }>("brand-files")
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(membership),
    },
  );
}

export function useCreateCustomerBrandFile(type: "logo" | "font") {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Omit<CustomerBrandFilePayload, "type">) =>
      axios
        .post<CustomerBrandFile>("brand-files", { ...payload, type })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandFile[]>(
          [BRAND_FILES_KEY, "list"],
          (prev) => {
            if (!prev) return prev;
            return [...prev, data];
          },
        );
      },
    },
  );
}

export function useUpdateCustomerBrandFile(file: CustomerBrandFile) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<CustomerBrandFilePayload>) =>
      axios
        .put<CustomerBrandFile>(`brand-files/${file.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandFile[]>(
          [BRAND_FILES_KEY, "list"],
          (prev) => {
            if (!prev) return prev;
            return prev.map((file) => {
              if (file.id === data.id) {
                return data;
              }
              return file;
            });
          },
        );
      },
    },
  );
}

export function useDeleteCustomerBrandFile() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-files/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<CustomerBrandFile[]>(
        [BRAND_FILES_KEY, "list"],
        (prev) => {
          if (!prev) return prev;
          return prev.filter((file) => file.id !== id);
        },
      );
    },
  });
}
