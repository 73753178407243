import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { GiftingProduct } from "@/types";
import { useEffect } from "react";

export default function GiftProductSizeSelector({
  product,
  value,
  onChange,
}: {
  product: GiftingProduct;
  value: number | null;
  onChange: (value: number | null) => void;
}) {
  const hasSizes = product.variants.length > 1;

  useEffect(() => {
    if (!value && !hasSizes) {
      setTimeout(() => {
        onChange(product.variants[0]?.id || null);
      });
    }
  }, [hasSizes, value]);

  if (!hasSizes) {
    return null;
  }

  return (
    <Select
      value={value ? String(value) : undefined}
      onValueChange={(v) => onChange(Number(v))}
    >
      <SelectTrigger className="w-[280px]">
        <SelectValue placeholder="Select a size" />
      </SelectTrigger>
      <SelectContent>
        {product.variants.map((v) => (
          <SelectItem key={v.id} value={String(v.id)} disabled={!v.in_stock}>
            {v.size}
            {!v.in_stock && " (Out of Stock)"}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
