import { useGetAddresses } from "@/api/addresses";
import AddressBlock from "@/components/addresses/AddressBlock";
import { Address } from "@/types";
import { BlankRadioOption, RadioOption } from "@/components/ui/radio-option";
import { RadioGroup } from "@headlessui/react";
import { Loader } from "lucide-react";
import { cn } from "@/lib/utils";

export default function AddressSelectList({
  value,
  onChange,
  size = "md",
}: {
  value: number | "new";
  onChange: (address: Address | "new") => void;
  size?: "sm" | "md" | "lg";
}) {
  const { data: addresses = [], isLoading } = useGetAddresses();

  const onValueChange = (value: number | "new") => {
    if (value === "new") {
      onChange("new");
    } else {
      onChange(addresses.find((a) => a.id === value)!);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (addresses.length === 0 && value === "new") {
    return null;
  }

  return (
    <RadioGroup value={value} onChange={onValueChange}>
      <ul
        className={cn("grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3", {
          "gap-4 text-sm 2xl:grid-cols-4": size === "lg",
          "gap-3 text-sm": size === "md",
          "gap-2 text-xs": size === "sm",
        })}
      >
        {addresses.length > 0 && (
          <>
            {addresses.slice(0, 11).map((address) => (
              <li>
                <BlankRadioOption
                  value={address.id}
                  className={size === "sm" ? "p-3" : "p-4"}
                >
                  <AddressBlock
                    address={address}
                    asLabel
                    labelClassName="font-medium"
                  />
                </BlankRadioOption>
              </li>
            ))}
          </>
        )}
        <li>
          <RadioOption
            value="new"
            label="New Address"
            description="Enter a new address"
            className={size === "sm" ? "p-3" : "p-4"}
          />
        </li>
      </ul>
    </RadioGroup>
  );
}
