import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import pluralize from "pluralize";
import { useToast } from "@/components/ui/use-toast";
import { CustomerSku, CustomerUser, InventoryEntry } from "@/types";

export const VARIANTS_KEY = "variants";

export function useSearchVariants(query: string) {
  const queryClient = useQueryClient();

  return useQuery([VARIANTS_KEY, "search", query], () =>
    axios
      .get<{ data: CustomerSku[] }>(`/variants?query=${query}`)
      .then((res) => {
        return res.data.data.map((variant) => {
          queryClient.setQueryData(
            [VARIANTS_KEY, "detail", variant.id],
            variant,
          );
          return variant;
        });
      }),
  );
}

export function useGetVariant(variantId: number | null | undefined) {
  return useQuery(
    [VARIANTS_KEY, "detail", variantId],
    () =>
      axios.get<CustomerSku>(`/variants/${variantId}`).then((res) => res.data),
    {
      enabled: variantId != null,
      staleTime: 5 * 60 * 1000,
    },
  );
}

export function useUpdateVariant(variantId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    (
      values: Partial<{
        alert_level: number | null;
        par_level: number | null;
        value: number | null;
      }>,
    ) =>
      axios
        .put<CustomerUser>(`/variants/${variantId}`, {
          alert_level: values.alert_level || null,
          par_level: values.par_level || null,
          value: values.value || null,
        })
        .then(() => {
          queryClient.invalidateQueries([VARIANTS_KEY]);
        }),
  );
}

export function useArchiveVariants() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((ids: number[]) =>
    axios.delete(`/variants/${ids.join(",")}`).then(() => {
      queryClient.invalidateQueries([VARIANTS_KEY]);
      toast({
        title: `${pluralize("SKU", ids.length)} Successfully Archived`,
        variant: "success",
      });
    }),
  );
}

export function useVariantInventoryEntries(
  variantId: number | null | undefined,
) {
  return useQuery(
    [VARIANTS_KEY, "inventoryEntries", variantId],
    () =>
      axios
        .get<{
          data: InventoryEntry[];
        }>(`/variants/${variantId}/entries`)
        .then((res) => res.data.data),
    {
      enabled: variantId != null,
    },
  );
}
