import Title from "@/components/ui/title";
import ProjectsTable from "@/components/projects/ProjectsTable";
import {
  useExport,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { PlusCircle } from "lucide-react";
import { Project } from "@/types";
import { Button } from "@/components/ui/button";
import { Table } from "@tanstack/react-table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ProjectsEmptyState from "@/components/projects/ProjectsEmptyState";
import { useTitle } from "@/lib/title";
import { DataTableActions } from "@/components/ui/data-table-actions";
import Toolbar from "@/components/ui/toolbar";
import { Link } from "react-router-dom";
import { PROJECTS_KEY } from "@/api/projects";

export default function Projects() {
  useTitle("Projects");
  const tableState = useManualTableState(
    [
      {
        id: "in_hands_date",
        desc: true,
      },
    ],
    {
      open_status: "open",
    },
  );
  const { setQuery } = tableState;

  const result = useManualTableQuery<Project>(
    PROJECTS_KEY,
    "/projects",
    tableState,
  );
  const downloadRequest = useExport("/projects", tableState);

  return (
    <div className="flex-1 space-y-6">
      <Toolbar
        actions={
          <Button asChild>
            <Link to="/create-project">
              <PlusCircle className="mr-2 size-4" />
              New Project
            </Link>
          </Button>
        }
      >
        <Title>Projects</Title>
      </Toolbar>

      <Tabs
        value={tableState.filters.open_status}
        onValueChange={(v) =>
          tableState.setFilters((prev) => ({ ...prev, open_status: v }))
        }
      >
        <TabsList className="mb-6">
          <TabsTrigger value="open">Open</TabsTrigger>
          <TabsTrigger value="complete">Complete</TabsTrigger>
          <TabsTrigger value="unpaid">Unpaid</TabsTrigger>
          <TabsTrigger value="all">All</TabsTrigger>
        </TabsList>
      </Tabs>

      {result.data && result.data.data.length === 0 ? (
        <ProjectsEmptyState />
      ) : (
        <ProjectsTable
          header={(table: Table<Project>) => (
            <DataTableActions
              downloadRequest={downloadRequest}
              placeholder="Search by name"
              setQuery={setQuery}
              table={table}
            />
          )}
          tableState={tableState}
          query={result}
        />
      )}
    </div>
  );
}
