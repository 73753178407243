import {
  GiftingProduct,
  GiftingProductType,
  giftingProductTypeSchema,
} from "@/types";
import { Loader, SearchIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Input } from "@/components/ui/input";
import Pagination from "@/components/ui/pagination";
import AddProductIcon from "@/components/products/AddProductIcon";
import GiftingProductCard from "@/components/gifting/products/GiftingProductCard";
import { useActiveGiftingProducts } from "@/api/gifting-products";
import Toolbar from "@/components/ui/toolbar";
import EmptyState from "@/components/ui/empty-state";
import { Card } from "@/components/ui/card";
import FilterDropdown from "@/components/ui/filter-dropdown";
import { getGiftingProductTypeTitle } from "@/components/gifting/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import ProductImages from "@/components/products/ProductImages";
import Title from "@/components/ui/title";
import EnhancedText from "@/components/ui/enhanced-text";
import { Badge } from "@/components/ui/badge";
import PriceDetails from "@/components/products/PriceDetails";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@radix-ui/react-dialog";

export default function GiftItemSelector({
  selected,
  onSelect,
  fulfillmentType,
}: {
  selected: GiftingProduct | undefined;
  onSelect: (product: GiftingProduct) => void;
  fulfillmentType?: GiftingProductType;
}) {
  const { data: products = [], isLoading } = useActiveGiftingProducts();
  const [query, setQuery] = useState("");
  const [productTypes, setProductTypes] = useState<GiftingProductType[]>([]);
  const [page, setPage] = useState(0);
  const perPage = 10;

  const filteredProducts = useMemo(
    () =>
      products.filter((product) => {
        if (!product.name.toLowerCase().includes(query.toLowerCase())) {
          return false;
        }
        if (productTypes.length > 0 && !productTypes.includes(product.type)) {
          return false;
        }
        if (fulfillmentType && product.fulfillment_type !== fulfillmentType) {
          return false;
        }
        return true;
      }),
    [products, productTypes, query, fulfillmentType],
  );

  const totalPages = Math.ceil(filteredProducts.length / perPage);

  useEffect(() => {
    setPage(0);
  }, [query]);

  return (
    <div className={cn(`relative space-y-8 py-1`)}>
      {isLoading ? (
        <Loader className="size-10 animate-spin" />
      ) : products.length === 0 ? (
        <Card className="p-5">
          <EmptyState
            title="No Products"
            description="Get started by added a product to your gifting catalog."
          />
        </Card>
      ) : (
        <div>
          <Toolbar
            className="mb-4"
            actions={
              <FilterDropdown
                id="product-type"
                name="Type"
                options={giftingProductTypeSchema.options.map((o) => ({
                  label: getGiftingProductTypeTitle(o.value),
                  value: o.value,
                }))}
                values={productTypes}
                onChange={setProductTypes}
              />
            }
          >
            <Input
              type="search"
              defaultValue={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for products..."
              className="max-w-xs"
            />
          </Toolbar>

          {filteredProducts.length > 0 ? (
            <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredProducts
                .slice(page * perPage, (page + 1) * perPage)
                .map((product) => {
                  const addProduct = (e: SyntheticEvent) => {
                    e.stopPropagation();
                    onSelect(product);
                  };
                  return (
                    <Dialog key={product.id}>
                      <DialogTrigger>
                        <GiftingProductCard
                          product={product}
                          className="hover:border-slate-400"
                        >
                          <AddProductIcon
                            selected={product.id === selected?.id}
                            outOfStock={!product.in_stock}
                            onClick={product.in_stock ? addProduct : undefined}
                          />
                        </GiftingProductCard>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-4xl">
                        <div className="grid grid-cols-2">
                          <div className="pr-12">
                            <ProductImages images={product.images} />
                          </div>
                          <div className="flex flex-col items-start gap-1">
                            <div className="flex gap-1">
                              {product.type === "kit" && <Badge>Kit</Badge>}
                              <Badge>
                                {getGiftingProductTypeTitle(
                                  product.fulfillment_type,
                                )}
                              </Badge>
                            </div>
                            <Title className="mt-1 text-2xl">
                              {product.name}
                            </Title>
                            <PriceDetails
                              unitPrice={product.min_price}
                              isStartingAt={
                                product.min_price < product.max_price
                              }
                              size="lg"
                            />

                            <div className="my-4 w-full">
                              {product.in_stock ? (
                                <DialogClose asChild>
                                  <Button
                                    onClick={addProduct}
                                    className="w-full"
                                  >
                                    Select Product
                                  </Button>
                                </DialogClose>
                              ) : (
                                <Button
                                  disabled
                                  variant="destructive"
                                  className="w-full"
                                >
                                  Out of Stock
                                </Button>
                              )}
                            </div>

                            <EnhancedText className="text-sm text-muted-foreground">
                              {product.description}
                            </EnhancedText>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  );
                })}
            </div>
          ) : (
            <EmptyState
              Icon={SearchIcon}
              title="No Products Found"
              description="We couldn't find any products that match your search."
            />
          )}

          {totalPages > 1 && (
            <Pagination
              pageIndex={page}
              totalPages={totalPages}
              canGetPrevious={page > 0}
              onPrevious={() => setPage((p) => p - 1)}
              canGetNextPage={page < totalPages - 1}
              onNext={() => setPage((p) => p + 1)}
            />
          )}
        </div>
      )}
    </div>
  );
}
