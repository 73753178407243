import { TrashIcon } from "lucide-react";
import { Loader } from "lucide-react";
import { useUnregisterCard } from "@/api/tango";
import { useEffect } from "react";

export default function TangoDeleteCardButton({
  cardToken,
  setIsDeleting,
}: {
  cardToken: string;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const unregisterCard = useUnregisterCard();

  useEffect(() => {
    setIsDeleting(unregisterCard.isLoading);
  }, [unregisterCard.isLoading]);

  return (
    <>
      {unregisterCard.isLoading ? (
        <Loader />
      ) : (
        <button
          type="button"
          onClick={() => {
            if (confirm("Are you sure you want to delete this card?")) {
              unregisterCard.mutate(cardToken);
            }
          }}
        >
          <TrashIcon className="size-4 text-muted-foreground" />
        </button>
      )}
    </>
  );
}
