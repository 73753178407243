import { useParams } from "react-router-dom";
import SplashScreen from "@/pages/SplashScreen";
import { Link } from "react-router-dom";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import InventoryReturnStatus from "@/components/inventory/InventoryReturnStatus";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import ShipmentsTable from "@/components/orders/ShipmentsTable";
import { Card } from "@/components/ui/card";
import {
  useDownloadLabel,
  useGetInventoryReturn,
} from "@/api/inventory-return";
import { useTitle } from "@/lib/title";
import { formatDate } from "@/lib/dates";
import { CustomerInventoryReturnItem, Shipment } from "@/types";
import { Button } from "@/components/ui/button";
import { FileText } from "lucide-react";

export default function InventoryReturnDetail() {
  const { id } = useParams<{ id: string }>();
  const { data: inventoryReturn, isLoading } = useGetInventoryReturn(
    Number(id),
  );
  useTitle(inventoryReturn?.number, "Inventory Return");
  const { mutate: downloadLabel, isLoading: isDownloading } =
    useDownloadLabel();

  const handleDownloadLabel = (shipmentId: number) => {
    downloadLabel({ returnId: Number(id), shipmentId });
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!inventoryReturn) {
    return null;
  }

  return (
    <div className="flex-1 space-y-8">
      <div>
        <Title className="flex items-center gap-4">
          {inventoryReturn.number}
          <InventoryReturnStatus status={inventoryReturn.status} />
        </Title>
        <Description>
          <span>Return placed on {formatDate(inventoryReturn.created_at)}</span>
          {inventoryReturn.fulfillment_order && (
            <>
              <span> for original order: </span>
              <Link
                to={`/fulfillment-orders/${inventoryReturn.fulfillment_order.id}`}
                className="underline hover:no-underline"
              >
                {inventoryReturn.fulfillment_order.number}
              </Link>
            </>
          )}
        </Description>
      </div>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>SKU</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Quantity</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {inventoryReturn.items
              ?.filter((i) => i.qty != 0)
              .map((item: CustomerInventoryReturnItem) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <span className="font-medium">{item.variant.sku}</span>
                  </TableCell>
                  <TableCell>{item.variant.description}</TableCell>
                  <TableCell>{item.qty.toLocaleString()}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>

      <ShipmentsTable
        shipments={inventoryReturn.shipments ?? []}
        actions={(shipment: Shipment) => (
          <Button
            variant="outline"
            onClick={() => handleDownloadLabel(shipment.id)}
            isLoading={isDownloading}
          >
            <FileText className="mr-2 size-4" /> Download Shipping Label
          </Button>
        )}
      />
    </div>
  );
}
