import { CustomerOrderApproval } from "@/types";
import { Alert } from "@/components/ui/alert";
import { SectionTitle } from "@/components/ui/title";
import AddressBlock from "@/components/addresses/AddressBlock";
import { Card } from "@/components/ui/card";
import { curr } from "@/lib/curr";
import Disclaimers from "@/components/approvals/Disclaimers";
import ApprovalLayoutCard from "@/components/approvals/ApprovalLayoutCard";
import {
  TotalTable,
  TotalTableLabel,
  TotalTableRow,
  TotalTableValue,
} from "@/components/ui/total-table";
import OrderItemsTable from "@/components/orders/OrderItemsTable";

export default function ApprovalSection({
  approval,
}: {
  approval: CustomerOrderApproval;
}) {
  const { order } = approval;
  const {
    contact,
    shipping_address: shippingAddress,
    billing_address: billingAddress,
    kit_addresses: kitAddresses,
  } = order;

  return (
    <>
      {approval.note && (
        <Alert className="whitespace-pre-line">{approval.note}</Alert>
      )}

      {approval.is_order && (
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 2xl:grid-cols-4">
            <div>
              <SectionTitle>Ordered By</SectionTitle>
              <p>
                <div>
                  {contact.first_name} {contact.last_name}
                </div>
                {contact.email && <div>{contact.email}</div>}
                {contact.phone && <div>{contact.phone}</div>}
              </p>
            </div>
            <div>
              <SectionTitle>Payment</SectionTitle>
              {order.customer_po && <p>Purchase Order: {order.customer_po}</p>}
              <p>Terms: {order.terms}</p>
            </div>
            <div>
              <SectionTitle>Shipping Address</SectionTitle>
              {kitAddresses != null && <p>{kitAddresses} Addresses</p>}
              {kitAddresses == null && shippingAddress && (
                <AddressBlock address={shippingAddress} />
              )}
            </div>
            <div>
              <SectionTitle>Billing Address</SectionTitle>
              {billingAddress && <AddressBlock address={billingAddress} />}
            </div>
            {order.memo && (
              <div className="col-span-3">
                <SectionTitle>Memo</SectionTitle>
                <p className="whitespace-pre-line">{order.memo}</p>
              </div>
            )}
          </div>

          <Card>
            <OrderItemsTable items={order.items} />
          </Card>
          <div className="!mt-4 flex justify-end">
            <Card className="px-6 py-3">
              <TotalTable>
                <TotalTableRow>
                  <TotalTableLabel>Subtotal</TotalTableLabel>
                  <TotalTableValue>{curr(order.subtotal)}</TotalTableValue>
                </TotalTableRow>
                <TotalTableRow>
                  <TotalTableLabel>Shipping</TotalTableLabel>
                  <TotalTableValue>
                    {curr(order.shipping + order.inbound_freight)}
                  </TotalTableValue>
                </TotalTableRow>
                <TotalTableRow>
                  <TotalTableLabel>Tax</TotalTableLabel>
                  <TotalTableValue>{curr(order.tax)}</TotalTableValue>
                </TotalTableRow>
                <TotalTableRow>
                  <TotalTableLabel>Total</TotalTableLabel>
                  <TotalTableValue>{curr(order.total)}</TotalTableValue>
                </TotalTableRow>
              </TotalTable>
            </Card>
          </div>
        </div>
      )}

      {approval.is_art && (
        <>
          {order.layouts.map((l) => (
            <ApprovalLayoutCard key={l.id} layout={l} />
          ))}
        </>
      )}

      <Disclaimers approval={approval} />
    </>
  );
}
