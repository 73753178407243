import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerInventoryReturn } from "@/types";
import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import InventoryReturnStatus from "@/components/inventory/InventoryReturnStatus";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { formatDate } from "@/lib/dates";
import { INVENTORY_RETURNS_KEY } from "@/api/inventory-return";

const columnHelper = createColumnHelper<CustomerInventoryReturn>();
const COLUMNS = [
  columnHelper.accessor("number", {
    header: "Return Number",
    cell: ({ getValue, row }) => (
      <Link
        to={`/inventory-returns/${row.original.id}`}
        className="font-medium"
      >
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("created_at", {
    header: "Date Created",
    cell: ({ getValue }) => <span>{formatDate(getValue())}</span>,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ getValue }) => {
      return <InventoryReturnStatus status={getValue()} />;
    },
  }),
  columnHelper.accessor((r) => r.shipments[0]?.tracking_number, {
    header: "Tracking",
    cell: ({ row }) => (
      <>
        {row.original.shipments.map((s) => (
          <a
            className="block text-primary hover:underline"
            key={s.id}
            href={s.tracking_url!}
            target="_blank"
            rel="noopener noreferrer"
          >
            {s.tracking_number}
          </a>
        ))}
      </>
    ),
    enableSorting: false,
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="space-x-2">
        <Link to={`/inventory-returns/${row.original.id}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </div>
    ),
  }),
];

export default function InventoryReturnTable() {
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const tableState = useManualTableState(
    [
      {
        id: "created_at",
        desc: true,
      },
    ],
    {
      filter: {
        created_at: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    },
  );

  useEffect(() => {
    tableState.setFilters((prev) => ({
      ...prev,
      filter: {
        created_at: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const query = useManualTableQuery<CustomerInventoryReturn>(
    INVENTORY_RETURNS_KEY,
    "/inventory-returns",
    tableState,
  );

  const table = useManualTable(tableState, query, COLUMNS);

  return (
    <DataTable
      table={table}
      isLoading={query.isLoading}
      header={
        <DataTableActions
          dateRange={dateRange}
          setDateRange={setDateRange}
          filters={tableState.filters}
          setFilters={tableState.setFilters}
          placeholder="Search by Return Number"
          setQuery={tableState.setQuery}
          table={table}
        />
      }
    />
  );
}
