import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { GiftingProduct, GiftingProductPayload, Collection } from "@/types";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

export const GIFTING_PRODUCTS_KEY = "giftingProducts";

export function useGetGiftingProducts(params: Record<string, string> = {}) {
  return useQuery(
    [GIFTING_PRODUCTS_KEY, "list", params],
    () =>
      axios
        .get<Collection<GiftingProduct>>("gifting-products", { params })
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  );
}

export function useActiveGiftingProducts() {
  return useGetGiftingProducts({ "filter[is_active]": "1" });
}

export function useGetGiftingProduct(id: number | null | undefined) {
  return useQuery(
    [GIFTING_PRODUCTS_KEY, "detail", id],
    () =>
      axios
        .get<GiftingProduct>(`gifting-products/${id}`)
        .then(({ data }) => data),
    { enabled: id != null },
  );
}

export function useCreateGiftingProduct() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation((payload: GiftingProductPayload) =>
    axios.post<GiftingProduct>("gifting-products", payload).then(({ data }) => {
      queryClient.invalidateQueries([GIFTING_PRODUCTS_KEY]);
      navigate(`/gifting-products/${data.id}?created=1`);
      return data;
    }),
  );
}

export function useUpdateGiftingProduct(giftingProductId: number) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (payload: GiftingProductPayload) =>
      axios
        .put<GiftingProduct>(`gifting-products/${giftingProductId}`, payload)
        .then(({ data }) => {
          toast({
            title: "Product Updated",
            variant: "success",
          });
          return data;
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GIFTING_PRODUCTS_KEY]);
      },
    },
  );
}

export function useDeleteGiftingProduct() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`gifting-products/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([GIFTING_PRODUCTS_KEY]);
    },
  });
}

export function useSortGiftingProducts() {
  const queryClient = useQueryClient();

  return useMutation(
    (products: GiftingProduct[]) =>
      axios
        .post<Collection<GiftingProduct>>(`gifting-products/sort`, {
          ids: products.map((p) => p.id),
        })
        .then(({ data }) => data.data),
    {
      onMutate: (products) => {
        queryClient.setQueryData<GiftingProduct[]>(
          [GIFTING_PRODUCTS_KEY, "list", {}],
          products,
        );
      },
      onSuccess: (res) => {
        queryClient.setQueryData<GiftingProduct[]>(
          [GIFTING_PRODUCTS_KEY, "list", {}],
          res,
        );
      },
    },
  );
}
