import { useTitle } from "@/lib/title";
import { useCreateGift } from "@/api/gifts";
import { GiftPayload } from "@/types";
import GiftItemsStep from "@/components/gifting/send/GiftItemsStep";
import GiftRecipientStep from "@/components/gifting/send/GiftRecipientStep";
import GiftSendStep from "@/components/gifting/send/GiftSendStep";
import Wizard, { WizardStep } from "@/components/wizard/Wizard";

const steps: WizardStep<GiftPayload>[] = [
  {
    name: "Item",
    description: (v) =>
      v.items && v.items.length > 0
        ? v.items.map((i) => i.product.name).join(", ")
        : "Which item would you like to send?",
    isComplete: (v) => Boolean(v.items && v.items.length > 0),
    component: GiftItemsStep,
  },
  {
    name: "Recipient",
    description: (v) =>
      v.first_name
        ? `${v.first_name} ${v.last_name}`
        : "Who is the recipient of this gift?",
    isComplete: (v) => !!v.first_name,
    component: GiftRecipientStep,
  },
  {
    name: "Send",
    description: () => "How do you want to send this gift?",
    isComplete: (v) => v.should_send != null,
    component: GiftSendStep,
  },
];

export default function GiftCreate() {
  useTitle("Send Gift");
  const createGiftRequest = useCreateGift();

  return (
    <div className="flex-1 space-y-6">
      <Wizard
        isLoading={createGiftRequest.isLoading}
        onSubmit={createGiftRequest.mutateAsync}
        defaultValues={{
          should_send: true,
          items: [],
        }}
        steps={steps}
      />
    </div>
  );
}
