import { CustomerOrder } from "@/types";
import { useGetOrder } from "@/api/orders";
import uniq from "lodash/uniq";
import { Link } from "react-router-dom";

export default function OrderSkus({ order }: { order: CustomerOrder }) {
  const { data } = useGetOrder(order.code);

  const variants =
    data?.items
      .filter((i) => i.inventory_variant)
      .map((i) => i.inventory_variant!) || [];
  const skus = uniq(variants.map((v) => v.sku));

  if (skus.length === 0) {
    return null;
  }

  return (
    <div className="mt-3 space-y-1 text-sm leading-tight">
      {skus.map((s) => (
        <Link
          to={`/inventory?query=${s}`}
          key={s}
          className="block text-primary hover:underline"
        >
          {s}
        </Link>
      ))}
    </div>
  );
}
