import { z } from "zod";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CustomerProjectField, ProjectFieldPayload } from "@/types";

const formSchema = z.object({
  fields: z.array(
    z.object({
      customer_project_field_id: z.number(),
      value: z.string().nullable(),
    }),
  ),
});

export default function ProjectFieldForm({
  onSubmit,
  submitLabel,
  initialFields = [],
  customerProjectFields,
}: {
  onSubmit: (values: { fields: ProjectFieldPayload[] }) => Promise<void>;
  submitLabel: string;
  initialFields?: ProjectFieldPayload[];
  customerProjectFields: CustomerProjectField[];
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fields: customerProjectFields.map((pf) => ({
        customer_project_field_id: pf.id,
        value:
          initialFields?.find((f) => f.customer_project_field_id === pf.id)
            ?.value || null,
      })),
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {customerProjectFields.map((pf, i) => (
          <FormField
            key={pf.id}
            control={form.control}
            name={`fields.${i}.value`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{pf.label}</FormLabel>
                <FormControl>
                  {pf.type === "select" && pf.options ? (
                    <Select
                      onValueChange={field.onChange}
                      value={field.value || ""}
                      required={pf.required}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        {pf.options.map((o) => (
                          <SelectItem value={o}>{o}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      {...field}
                      type={pf.type}
                      value={field.value || ""}
                      required={pf.required}
                    />
                  )}
                </FormControl>
                {pf.help && <FormDescription>{pf.help}</FormDescription>}
                <FormMessage />
              </FormItem>
            )}
          />
        ))}

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          {submitLabel}
        </Button>
      </form>
    </Form>
  );
}
