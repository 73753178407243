import { GiftRecipientPayload, IntegrationType } from "@/types";
import useDebounce from "@/hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import pickBy from "lodash/pickBy";

export const INTEGRATION_RECIPIENTS_KEY = "integrationRecipients";

export type IntegrationRecipient = GiftRecipientPayload & {
  id: string;
  name: string;
  external_url: string | null;
};

export function useGetIntegrationRecipients({
  query,
  type,
}: {
  query?: string;
  type?: IntegrationType;
}) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery(
    [INTEGRATION_RECIPIENTS_KEY, type, debouncedQuery],
    () =>
      axios
        .get<{ data: IntegrationRecipient[] }>("integration-recipients", {
          params: pickBy({ query: debouncedQuery, type: type }),
        })
        .then(({ data }) => data.data),
    {
      enabled: Boolean(debouncedQuery || type),
      staleTime: Infinity,
    },
  );
}
