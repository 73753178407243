import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Collection, CustomerTeam, CustomerTeamPayload } from "@/types";
import { useToast } from "@/components/ui/use-toast";
import { MEMBERSHIPS_KEY } from "@/api/membership";

export const TEAMS_KEY = "teams";

export function useGetTeams() {
  return useQuery(
    [TEAMS_KEY, "list"],
    () =>
      axios
        .get<Collection<CustomerTeam>>(`teams?count=100`)
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 60,
    },
  );
}

export function useGetTeam(id: number) {
  return useQuery([TEAMS_KEY, "detail", id], () =>
    axios.get<CustomerTeam>(`teams/${id}`).then(({ data }) => data),
  );
}

export function useCreateTeam() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CustomerTeamPayload) =>
      axios.post<CustomerTeam>("teams", payload).then(({ data }) => data),
    {
      onSuccess: (team) => {
        queryClient.invalidateQueries([TEAMS_KEY]);
        queryClient.setQueryData([TEAMS_KEY, "detail", team.id], team);
      },
    },
  );
}

export function useUpdateTeam() {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  return useMutation(
    ({ id, ...payload }: CustomerTeamPayload & { id: number }) =>
      axios.put<CustomerTeam>(`teams/${id}`, payload).then(({ data }) => data),
    {
      onSuccess: (team) => {
        toast({
          title: "Budget Updated",
          variant: "success",
        });
        queryClient.invalidateQueries([MEMBERSHIPS_KEY]);
        queryClient.invalidateQueries([TEAMS_KEY]);
        queryClient.setQueryData([TEAMS_KEY, "detail", team.id], team);
      },
    },
  );
}

export function useDeleteTeam() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`teams/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([MEMBERSHIPS_KEY]);
      queryClient.invalidateQueries([TEAMS_KEY]);
    },
  });
}
