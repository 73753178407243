import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import useDebounce from "@/hooks/useDebounce";
import { INPUT_CLASSES } from "@/components/ui/input";
import UserAvatar from "@/components/account/UserAvatar";
import { useMemberships } from "@/api/membership";
import { CustomerMembership } from "@/types";

export default function MemberSelect({
  value,
  onChange,
  asUserId,
}: {
  value: number | null;
  onChange: (value: number) => void;
  asUserId?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);
  const getId = (m: CustomerMembership) => (asUserId ? m.user.id : m.id);

  const { data: allMembers = [], isLoading } = useMemberships();
  const selectedMember = allMembers.find((m) => getId(m) === value);
  const members = useMemo(
    () =>
      allMembers.filter((m) =>
        m.user.name.toLowerCase().includes(debouncedQuery),
      ),
    [allMembers, debouncedQuery],
  );

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between text-left", INPUT_CLASSES)}
        >
          <span className="flex min-w-0 flex-grow items-center">
            {selectedMember ? (
              <>
                <UserAvatar
                  user={selectedMember.user}
                  className="mr-3 size-8"
                />
                <span className="min-w-0 flex-grow truncate">
                  {selectedMember.user.name}
                </span>
              </>
            ) : (
              "Search for user..."
            )}
          </span>

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search Members..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {isLoading ? "Loading..." : "No Member found."}
          </CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {members.map((member) => (
              <CommandItem
                key={member.id}
                value={String(getId(member))}
                onSelect={(currentValue) => {
                  onChange(Number(currentValue));
                  setOpen(false);
                }}
              >
                <UserAvatar user={member.user} className="mr-3 size-8" />
                {member.user.name}
                <Check
                  className={cn(
                    "ml-2 size-4",
                    value === getId(member) ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
