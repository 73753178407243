import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Collection, GiftingImage, GiftingImagePayload } from "@/types";

export const GIFTING_IMAGES_KEY = "giftingImages";

export function useGetGiftingImages() {
  return useQuery(
    [GIFTING_IMAGES_KEY, "list"],
    () =>
      axios
        .get<Collection<GiftingImage>>(`gifting-images?count=100`)
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 60,
    },
  );
}

export function useCreateGiftingImage() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: GiftingImagePayload) =>
      axios
        .post<GiftingImage>("gifting-images", payload)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GIFTING_IMAGES_KEY]);
      },
    },
  );
}

export function useUpdateGiftingImage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, ...payload }: GiftingImagePayload & { id: number }) =>
      axios
        .put<GiftingImage>(`gifting-images/${id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GIFTING_IMAGES_KEY]);
      },
    },
  );
}

export function useDeleteGiftingImage() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`gifting-images/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([GIFTING_IMAGES_KEY]);
    },
  });
}
