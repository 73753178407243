import { ChangeEvent, SyntheticEvent, useState } from "react";
import { CustomerSku } from "@/types";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { SheetFooter } from "@/components/ui/sheet";
import SkuItem from "@/components/inventory/SkuItem";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import SkuLevels from "@/components/inventory/SkuLevels";
import { cn } from "@/lib/utils";

export type SkuQty = CustomerSku & { qty: number };

export default function RestockForm({
  onSubmit,
  skus,
  isLoading,
}: {
  onSubmit: (e: SyntheticEvent, skus: SkuQty[]) => Promise<void>;
  skus: SkuQty[];
  isLoading: boolean;
}) {
  const [skusQty, setSkusQty] = useState<SkuQty[]>(skus);

  const onChange = ({
    e,
    skuId,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    skuId: number;
  }) => {
    const qty = JSON.parse(e.target.value);
    setSkusQty((prev) =>
      prev.map((sku) => {
        if (skuId === sku.id) {
          return { ...sku, qty };
        }
        return sku;
      }),
    );
  };

  return (
    <form onSubmit={(e) => onSubmit(e, skusQty)}>
      <Table className="my-4 w-full">
        <TableHeader>
          <TableRow>
            <TableHead colSpan={2}>SKU</TableHead>
            <TableHead>Quantity</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {skusQty.map((sku) => (
            <TableRow key={sku.id}>
              <TableCell className={cn(sku.qty === 0 && "opacity-50")}>
                <SkuItem sku={sku} withQty={false} />
              </TableCell>

              <TableCell>
                <SkuLevels sku={sku} />
              </TableCell>

              <TableCell>
                <Input
                  onChange={(e) => onChange({ e, skuId: sku.id })}
                  value={sku.qty}
                  step={1}
                  inputMode="numeric"
                  className="mt-1 w-24"
                  type="number"
                  onFocus={(e) => e.target.select()}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SheetFooter>
        <Button type="submit" isLoading={isLoading}>
          Create Project
        </Button>
      </SheetFooter>
    </form>
  );
}
