import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CustomerOrderApproval } from "@/types";
import { useSetBusiness } from "@/context/BusinessContext";

export const APPROVALS_KEY = "approvals";

export function useGetApprovals() {
  return useQuery([APPROVALS_KEY, "list"], () =>
    axios
      .get<{ data: CustomerOrderApproval[] }>(`/approvals`)
      .then(({ data }) => data.data),
  );
}

export function useGetApproval(code: string) {
  const setBusiness = useSetBusiness();

  return useQuery([APPROVALS_KEY, "detail", code], () =>
    axios.get<CustomerOrderApproval>(`/approvals/${code}`).then(({ data }) => {
      setBusiness(data.business);
      return data;
    }),
  );
}
