import { curr } from "@/lib/curr";
import { Loader } from "lucide-react";
import { useGetTangoAccount } from "@/api/tango";
import TangoAddFundsDialog from "@/components/gifting/TangoAddFundsDialog";

export default function TangoAccount() {
  const { data: account, isLoading } = useGetTangoAccount();

  if (isLoading) {
    return <Loader />;
  }

  if (!account) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="min-w-0 flex-grow">
        <div>{account.name}</div>
        <div className="text-sm text-muted-foreground">
          Balance: {curr(account.balance)}
        </div>
      </div>
      <TangoAddFundsDialog account={account} />
    </div>
  );
}
