import { useTitle } from "@/lib/title";
import { useBulkCreateGifts } from "@/api/gifts";
import { GiftBulkCreatePayload } from "@/types";
import pluralize from "pluralize";
import GiftItemsStep from "@/components/gifting/send/GiftItemsStep";
import Wizard, { WizardStep } from "@/components/wizard/Wizard";
import GiftBulkRecipientStep from "@/components/gifting/send/GiftBulkRecipientStep";
import GiftBulkSendStep from "@/components/gifting/send/GiftBulkSendStep";
import { useNavigate } from "react-router-dom";

const steps: WizardStep<GiftBulkCreatePayload>[] = [
  {
    name: "Item",
    description: (v) =>
      v.items && v.items.length > 0
        ? v.items.map((i) => i.product.name).join(", ")
        : "Which item would you like to send?",
    isComplete: (v) => Boolean(v.items && v.items.length > 0),
    component: GiftItemsStep,
  },
  {
    name: "Recipients",
    description: (v) =>
      v.recipients && v.recipients.length > 0
        ? pluralize("recipient", v.recipients.length, true)
        : "Who are the recipients of this gift?",
    isComplete: (v) => Boolean(v.recipients && v.recipients.length),
    component: GiftBulkRecipientStep,
  },
  {
    name: "Send",
    description: () => "How do you want to send these gifts?",
    isComplete: (v) => v.should_send != null,
    component: GiftBulkSendStep,
  },
];

export default function GiftBulkCreate() {
  useTitle("Send Gifts in Bulk");

  const createGiftRequest = useBulkCreateGifts();
  const navigate = useNavigate();

  return (
    <div className="flex-1 space-y-6">
      <Wizard
        isLoading={createGiftRequest.isLoading}
        onSubmit={(v) =>
          createGiftRequest.mutateAsync(v).then(() => {
            navigate("/gifts");
          })
        }
        defaultValues={{
          should_send: true,
          items: [],
          recipients: [],
        }}
        steps={steps}
      />
    </div>
  );
}
