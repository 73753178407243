import { UseFormReturn } from "react-hook-form";
import { CartProduct, GiftingProductPayload } from "@/types";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import ProductSelect from "@/components/selects/ProductSelect";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "@/components/ui/input";
import VendorSelect from "@/components/selects/VendorSelect";
import VariantTable from "@/components/projects/optionForm/VariantTable";
import DesignLayoutSelect from "@/components/selects/DesignLayoutSelect";
import Gate from "@/components/roles/Gate";
import { Alert } from "@/components/ui/alert";
import { getProductQueryKey } from "@/api/products";

export default function MadeToOrderType({
  form,
  isCreate,
  defaultType,
}: {
  form: UseFormReturn<GiftingProductPayload>;
  isCreate?: boolean;
  defaultType: "database" | "custom";
}) {
  const queryClient = useQueryClient();
  const [type, setType] = useState<"database" | "custom">(defaultType);
  const handleTypeChange = (newType: "database" | "custom") => {
    setType(newType);

    if (newType === "database") {
      form.setValue("vendor_id", null);
    } else {
      form.setValue("product_id", null);
      form.setValue("variants", [{ size: "OS", price: 0, cost: 0 }]);
    }
  };

  const handleProductChange = (id: number) => {
    form.setValue("product_id", id);

    const product = queryClient.getQueryData<CartProduct>(
      getProductQueryKey(id),
    );

    if (!product) {
      console.warn(`Product #${id} not found in cache`);
      return;
    }

    form.setValue("name", product.name);
    form.setValue("description", product.description);
    form.setValue(
      "images",
      product.images.map((i) => i.url),
    );
    form.setValue("number", product.style_number);
    form.setValue("color", product.color);
    form.setValue(
      "variants",
      product.variants.map((v) => ({
        variant_id: v.id,
        size: v.size,
        price: v.price,
        cost: v.cost!,
      })),
    );
  };

  return (
    <Gate
      role={["avail", "avail_incognito"]}
      fallback={
        <Alert variant="warning">
          Assistance is required from an Avail team member to configure this
          product.
        </Alert>
      }
    >
      <div className="space-y-4">
        <Tabs
          value={type}
          onValueChange={(type) =>
            handleTypeChange(type as "database" | "custom")
          }
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="database">Database</TabsTrigger>
            <TabsTrigger value="custom">Custom</TabsTrigger>
          </TabsList>
          <TabsContent value="database" className="space-y-4">
            <FormField
              control={form.control}
              name="product_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Database Product</FormLabel>
                  <ProductSelect
                    value={field.value || null}
                    onChange={handleProductChange}
                    params={{
                      all: true,
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </TabsContent>
          <TabsContent value="custom" className="space-y-4">
            {isCreate && (
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value || ""} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Number</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="color"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Color</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="vendor_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vendor</FormLabel>
                  <VendorSelect
                    value={field.value || null}
                    onChange={field.onChange}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </TabsContent>
        </Tabs>

        <FormField
          control={form.control}
          name="design_layout_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Design Layout</FormLabel>
              <DesignLayoutSelect
                value={field.value || null}
                onChange={field.onChange}
                clearable
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <VariantTable />
      </div>
    </Gate>
  );
}
