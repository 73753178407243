import { GiftLink } from "@/types";
import { z } from "zod";
import GiftProductSelector from "@/components/gifting/links/GiftProductSelector";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";
import unset from "lodash/unset";
import { SyntheticEvent, useState } from "react";
import { GiftSelections } from "@/api/gifts";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";

const formSchema = z.object({
  items: z.array(
    z.object({
      id: z.string(),
      selections: z.record(z.any()),
    }),
  ),
});

function hasNullValues(obj: unknown) {
  if (obj === null) {
    return true;
  }

  if (typeof obj === "object") {
    for (const key in obj) {
      // @ts-expect-error - TS doesn't know that key of obj
      if (hasNullValues(obj[key])) {
        return true;
      }
    }
  }

  return false;
}

export default function MakeSelectionsForm({
  gift,
  onSubmit,
  isLoading,
}: {
  gift: GiftLink;
  onSubmit: (values: z.infer<typeof formSchema>) => Promise<void>;
  isLoading: boolean;
}) {
  const [selections, setSelections] = useState<{
    [item: string]: GiftSelections;
  }>(Object.fromEntries(gift.items.map((i) => [i.id, null])));

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (hasNullValues(selections)) {
      alert("Please make a selection for each item");
      return;
    }

    onSubmit({
      items: gift.items.map((i) => ({ ...i, selections })),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="mb-4 text-2xl font-semibold">Choose Your Gift</h1>
      <div className="space-y-6">
        {gift.items.map((i) => (
          <div key={i.id}>
            <GiftProductSelector
              product={i.product}
              value={selections[i.id]!}
              onChange={(path, selections) => {
                setSelections((prev) => {
                  if (path.length === 0) {
                    return {
                      ...prev,
                      [i.id]: selections || null,
                    };
                  }
                  const prevForItem = prev[i.id];
                  const clone = cloneDeep(
                    prevForItem && typeof prevForItem === "object"
                      ? prevForItem
                      : {},
                  );
                  if (selections === undefined) {
                    unset(clone, path.join("."));
                  } else {
                    setWith(clone, path.join("."), selections, Object);
                  }
                  return {
                    ...prev,
                    [i.id]: clone,
                  };
                });
              }}
              path={[]}
              isSelected
            />
          </div>
        ))}
      </div>

      <WizardSubmitButton isLoading={isLoading} className="mt-6">
        Submit
      </WizardSubmitButton>
    </form>
  );
}
