import { useParams } from "react-router-dom";
import { ReactNode } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetProduct } from "@/api/products";
import { useTitle } from "@/lib/title";
import { Card } from "@/components/ui/card";
import { CartProduct } from "@/types";
import ProductColors from "@/components/products/ProductColors";
import ProductImages from "@/components/products/ProductImages";
import ProductHeader from "@/components/products/ProductHeader";
import ProductDescription from "@/components/products/ProductDescription";

export default function ProductDetail({
  children,
}: {
  children?: (product: CartProduct) => ReactNode;
}) {
  const { product: slug } = useParams<{ product: string }>();
  const { data, isLoading } = useGetProduct(slug!);

  useTitle(data?.data.name, "Product");

  const product = data?.data;

  if (isLoading) {
    return <ProductDetailSkeleton />;
  }
  if (!product) {
    return null;
  }

  return (
    <Card className="-mx-5 p-5 lg:grid lg:auto-rows-min lg:grid-cols-5 lg:gap-x-8 2xl:mx-0">
      <ProductHeader
        product={product}
        className="lg:col-span-3 lg:col-start-3"
      />

      <div className="my-8 lg:col-span-2 lg:row-span-3 lg:row-start-1 lg:my-0">
        <ProductImages
          key={product.id}
          images={product.images.map((i) => i.url)}
        />

        <ProductDescription
          description={product.description}
          className="mt-8"
        />
      </div>

      <div className="lg:col-span-3 lg:col-start-3 lg:row-start-2">
        {data.colors.length > 1 && (
          <ProductColors product={product} colors={data.colors} />
        )}

        {children && children(product)}
      </div>
    </Card>
  );
}

export function ProductDetailSkeleton() {
  return (
    <Card className="-mx-5 p-5 2xl:mx-0">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <div>
          <Skeleton className="h-96 w-full" />
        </div>
        <div className="space-y-3 lg:col-span-2">
          <Skeleton className="h-8 w-1/2" />
          <Skeleton className="h-4 w-2/3" />
          <Skeleton className="h-4 w-36" />
          <Skeleton className="h-4 w-36" />
          <Skeleton className="h-4 w-36" />
        </div>
      </div>
    </Card>
  );
}
