import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { CustomerMembership } from "@/types";
import { useUpdateMembershipRequest } from "@/api/membership";
import { useGetTeams } from "@/api/teams";

export default function MembershipTeam({
  membership,
}: {
  membership: CustomerMembership;
}) {
  const { data: teams = [] } = useGetTeams();
  const updateMembershipRequest = useUpdateMembershipRequest();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={teams.length === 0}>
        <Badge variant="gray">{membership.team?.name ?? "No Budget"}</Badge>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuRadioGroup
          value={membership.team ? String(membership.team.id) : ""}
          onValueChange={(teamId) =>
            updateMembershipRequest.mutate({
              id: membership.id,
              team_id: teamId ? Number(teamId) : null,
            })
          }
        >
          <DropdownMenuRadioItem value="">No Budget</DropdownMenuRadioItem>
          {teams.map((team) => (
            <DropdownMenuRadioItem key={team.id} value={String(team.id)}>
              {team.name}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
