import { CustomerUser } from "@/types";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { imageUrl } from "@/lib/image";
import { sha256 } from "js-sha256";

export default function UserAvatar({
  user,
  className,
  fallbackClassName = "bg-primary text-white",
}: {
  user: Pick<CustomerUser, "avatar" | "name"> & { email?: string | null };
  className?: string;
  fallbackClassName?: string;
}) {
  const getGravatarUrl = () => {
    if (!user.email) {
      return null;
    }
    const email = user.email.trim().toLowerCase();
    return `https://www.gravatar.com/avatar/${sha256(email)}?d=404`;
  };

  const avatarUrl = user.avatar
    ? imageUrl(user.avatar, { w: 250 })
    : getGravatarUrl();

  return (
    <Avatar className={className}>
      {avatarUrl && <AvatarImage className="object-cover" src={avatarUrl} />}
      <AvatarFallback className={fallbackClassName}>
        {user.name[0]}
      </AvatarFallback>
    </Avatar>
  );
}
