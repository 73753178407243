import { CustomerSku } from "@/types";
import { Badge } from "@/components/ui/badge";

export default function SkuLevels({ sku }: { sku: CustomerSku }) {
  return (
    <div className="flex items-center space-x-3">
      {sku.inventory_scrapped > 0 && (
        <Badge variant="amber">{sku.inventory_scrapped} scrapped</Badge>
      )}

      {sku.inventory_on_order > 0 && (
        <Badge variant="teal">{sku.inventory_on_order} on order</Badge>
      )}

      {sku.alert_level && sku.inventory_qty < sku.alert_level ? (
        <Badge variant="destructive">Low</Badge>
      ) : null}

      {sku.alert_level != null && <Badge>Min: {sku.alert_level}</Badge>}

      {sku.par_level != null && <Badge>Par: {sku.par_level}</Badge>}
    </div>
  );
}
