import {
  CustomerPreview,
  CustomerUser,
  Gift,
  GiftingProductType,
  GiftPayload,
} from "@/types";

export function canCancelGift(gift: Gift) {
  return !["delivered", "shipped", "redeemed", "cancelled"].includes(
    gift.status,
  );
}

export function getGiftingProductTypeTitle(type: GiftingProductType) {
  switch (type) {
    case "kit":
      return "Kit";
    case "inventory":
      return "Inventory";
    case "on_demand":
      return "On-Demand";
    case "batch":
      return "Batch";
    case "gift_card":
      return "Gift Card";
  }
}

export function getSendingMethod(
  gift: Pick<Partial<Gift>, "external_source" | "should_send">,
) {
  if (gift.should_send === false) {
    return "Magic Link";
  }
  return gift.external_source === "slack"
    ? "Slack Message"
    : gift.external_source === "teams"
    ? "Teams Message"
    : "Email";
}

export function getIsDigitalGift(
  gift: Partial<Pick<GiftPayload | Gift, "items">>,
) {
  return (
    gift.items?.some((i) => i.product.fulfillment_type === "gift_card") || false
  );
}

export function getSendAsTitle(sendAs: number) {
  switch (sendAs) {
    case 0:
      return "Myself";
    case 1:
      return "Myself at Company";
    case 2:
      return "Company";
    case 3:
      return "Team";
    default:
      return "Invalid Send As";
  }
}

export function getSendAsExample(
  sendAs: number,
  sender: CustomerUser,
  customer: CustomerPreview,
) {
  switch (sendAs) {
    case 0:
      return sender.name;
    case 1:
      return `${sender.name} at ${customer.name}`;
    case 2:
      return customer.name;
    case 3:
      return `The ${customer.name} Team`;
    default:
      return "Invalid Send As";
  }
}
