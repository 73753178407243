import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import { GiftingProduct, GiftPayload } from "@/types";
import GiftItemSelector from "@/components/gifting/GiftItemSelector";
import GiftingBudgetProgressBar from "@/components/gifting/GiftingBudgetProgressBar";
import { SheetFooter } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import {
  ArrowRightIcon,
  PlusIcon,
  ShoppingCartIcon,
  XIcon,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Badge } from "@/components/ui/badge";
import GiftingProductImage from "@/components/gifting/products/GiftingProductImage";
import { curr } from "@/lib/curr";
import sumBy from "lodash/sumBy";
import { useHideBeacon } from "@/lib/beacon";

export default function GiftItemsStep({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<GiftPayload>;
  onSubmit: (v: Pick<GiftPayload, "items">) => Promise<void>;
}) {
  const [product, setProduct] = useState<GiftingProduct>();
  const [cart, setCart] = useState<
    Pick<
      GiftingProduct,
      "id" | "name" | "fulfillment_type" | "images" | "min_price" | "max_price"
    >[]
  >(defaultValues.items ? defaultValues.items.map((i) => i.product) : []);
  useHideBeacon();

  return (
    <WizardStep>
      <Toolbar
        actions={
          <>
            {cart.length > 0 && (
              <Popover>
                <PopoverTrigger className="flex h-11 items-center px-4">
                  <ShoppingCartIcon className="size-5" />
                  <Badge>{cart.length}</Badge>
                </PopoverTrigger>
                <PopoverContent align="end">
                  <h3 className="-mt-1 mb-4 text-lg font-semibold">
                    Selected Items
                  </h3>
                  <div className="space-y-4">
                    {cart.map((item, i) => (
                      <div
                        key={item.id}
                        className="flex items-center justify-between gap-2"
                      >
                        <GiftingProductImage
                          product={item}
                          className="size-10"
                          iconClassName="p-2"
                        />
                        <div>
                          <div className="text-sm font-medium">{item.name}</div>
                          <div className="text-xs text-muted-foreground">
                            {item.min_price < item.max_price && "As low as "}
                            {curr(item.min_price)}
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setCart(cart.filter((_, j) => i !== j))
                          }
                          className="ml-auto pl-2 text-muted-foreground hover:text-slate-800"
                        >
                          <XIcon className="size-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                  {cart.length > 1 && (
                    <div className="mt-4 flex gap-2 text-center text-sm text-muted-foreground">
                      <span className="w-10">Total: </span>
                      <b className="font-medium">
                        {curr(sumBy(cart, (i) => i.min_price))}
                      </b>
                    </div>
                  )}
                </PopoverContent>
              </Popover>
            )}
            <GiftingBudgetProgressBar />
          </>
        }
      >
        <Title>
          Which {cart.length > 0 && "additional "}item do you want to send?
        </Title>
        <Description>
          Choose from any of the items in your catalog to send as a gift.
        </Description>
      </Toolbar>

      <GiftItemSelector
        selected={product}
        onSelect={setProduct}
        fulfillmentType={cart[0]?.fulfillment_type}
      />

      <SheetFooter>
        {product && product.fulfillment_type !== "gift_card" && (
          <Button
            variant="ghost"
            onClick={() => {
              setCart([...cart, product]);
              setProduct(undefined);
            }}
          >
            Add Another <PlusIcon className="-mr-1 ml-2 size-4" />
          </Button>
        )}
        <Button
          onClick={() =>
            onSubmit({
              items: [...cart, ...(product ? [product] : [])].map(
                (product) => ({
                  product,
                }),
              ),
            })
          }
          disabled={!product && cart.length === 0}
        >
          Next <ArrowRightIcon className="-mr-1 ml-2 size-4" />
        </Button>
      </SheetFooter>
    </WizardStep>
  );
}
