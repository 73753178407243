import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { SyntheticEvent, useState } from "react";
import { useMemberships } from "@/api/membership";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useCreateProjectActivity } from "@/api/project-activity";
import Attachments from "@/components/attachments/Attachments";
import { AttachmentPayload, CustomerUser } from "@/types";
import { useAppContext } from "@/context/AppContext";
import UserAvatar from "@/components/account/UserAvatar";
import { useGetProjectUsers } from "@/api/project-users";
import orderBy from "lodash/orderBy";

export default function ProjectMessageTextarea() {
  const [value, setValue] = useState("");
  const [attachments, setAttachments] = useState<AttachmentPayload[]>([]);
  const { data: memberships = [] } = useMemberships();
  const { data: projectUsers } = useGetProjectUsers();
  const { membership } = useAppContext();

  const sendMessageRequest = useCreateProjectActivity();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    sendMessageRequest.mutateAsync({ message: value, attachments }).then(() => {
      setValue("");
      setAttachments([]);
    });
  };

  const users = orderBy(
    memberships.filter((m) => m.id !== membership?.id).map((m) => m.user),
    [(u) => projectUsers?.some((pu) => pu.id === u.id), (a) => a.name],
    ["desc", "asc"],
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="-ml-2 space-y-1 rounded-md border px-3 pb-2 pt-3 focus-within:ring-2 focus-within:ring-primary print:hidden"
    >
      <MentionsInput
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        placeholder="Write a message"
        className="mentions"
      >
        <Mention
          trigger="@"
          data={users.map((u) => ({
            id: u.id,
            display: u.name,
            user: u,
          }))}
          className="mentions__mention"
          renderSuggestion={(
            i,
            // @ts-ignore
            search,
            // @ts-ignore
            highlightedDisplay,
            // @ts-ignore
            index,
            focused,
          ) => {
            const item = i as SuggestionDataItem & { user: CustomerUser };
            return (
              <div
                className={cn(
                  "min-w-xs flex items-center gap-3 px-3 py-1 text-left text-sm",
                  {
                    "bg-slate-100": focused,
                  },
                )}
                key={item.id}
              >
                <UserAvatar user={item.user} className="size-7" />
                {highlightedDisplay}
                {projectUsers &&
                  !projectUsers.some((u) => u.id === item.user.id) && (
                    <span className="ml-auto text-xs text-muted-foreground">
                      Not in project
                    </span>
                  )}
              </div>
            );
          }}
        />
      </MentionsInput>
      <div className="flex w-full items-center justify-between">
        <Attachments
          placeholder={null}
          className="ml-px flex-grow"
          attachments={attachments}
          onNew={(a) => setAttachments((prev) => [...prev, ...a])}
          onDelete={(a) =>
            setAttachments((prev) => prev.filter((p) => p.file !== a.file))
          }
        />
        <Button
          type="submit"
          size="sm"
          isLoading={sendMessageRequest.isLoading}
          variant="outline"
        >
          Send
        </Button>
      </div>
    </form>
  );
}
