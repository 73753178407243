import { useState } from "react";
import { Button } from "@/components/ui/button";
import * as z from "zod";
import { curr } from "@/lib/curr";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import CurrencyInput from "@/components/ui/currency-input";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AccountType, useAddFunds, useGetCreditCards } from "@/api/tango";
import { Label } from "@/components/ui/label";
import TangoRegisterCardDialog from "@/components/gifting/TangoRegisterCardDialog";
import MasterCardLogo from "@/assets/mastercard.svg?react";
import VisaLogo from "@/assets/visa.svg?react";
import AmexLogo from "@/assets/amex.svg?react";
import CreditCardLogo from "@/assets/credit-card.svg?react";
import DiscoverLogo from "@/assets/discover.svg?react";
import { Loader } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import TangoDeleteCardButton from "./TangoDeleteCardButton";

const formSchema = z.object({
  cardId: z.string(),
  funds: z.coerce.number(),
});

export default function TangoAddFundsDialog({
  account,
}: {
  account: AccountType;
}) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [tab, setTab] = useState<"credit" | "transfer">("credit");
  const [open, setOpen] = useState<boolean>(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const addFunds = useAddFunds(() => {
    form.reset();
    setOpen(false);
  });

  const { data: cards, isLoading } = useGetCreditCards();

  const cardLogo = (label: string) => {
    const compareLabel = label.toLowerCase();

    const logos: { [key: string]: JSX.Element } = {
      visa: <VisaLogo className="mr-3 size-6" />,
      discover: <DiscoverLogo className="mr-3 size-6" />,
      "american express": <AmexLogo className="mr-3 size-6" />,
      mastercard: <MasterCardLogo className="mr-3 size-6" />,
    };

    const matchedKey = Object.keys(logos).find((key) =>
      compareLabel.includes(key),
    );

    return matchedKey ? (
      logos[matchedKey]
    ) : (
      <CreditCardLogo className="mr-3 size-6" />
    );
  };

  const onSubmit = (payload: z.infer<typeof formSchema>) => {
    addFunds.mutate(payload);
  };

  const fundAmount = form.watch("funds") ?? 0;
  const fee = fundAmount * 0.035;
  const total = Number(fundAmount) + Number(fee);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size="sm" variant="ghost" onClick={() => setOpen(!open)}>
          Fund
        </Button>
      </DialogTrigger>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>Funding</DialogTitle>
            <div className="pt-2">
              <div className="text-md text-muted-foreground">Account</div>
              <div>
                {account.name} - {curr(account.balance)}
              </div>
            </div>
          </DialogHeader>
          {isLoading ? (
            <Loader />
          ) : (
            <Tabs
              value={tab}
              onValueChange={(tab) => setTab(tab as "credit" | "transfer")}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="credit">Add Funds</TabsTrigger>
                <TabsTrigger value="transfer">Transfer</TabsTrigger>
              </TabsList>
              <TabsContent value="credit" className="space-y-4">
                <Form {...form}>
                  <form
                    onSubmit={(e) => {
                      e.stopPropagation();
                      return form.handleSubmit(onSubmit)(e);
                    }}
                    className="space-y-4 pt-2"
                  >
                    <FormField
                      control={form.control}
                      name="cardId"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Funding Method</FormLabel>
                          <FormControl>
                            {cards && (
                              <RadioGroup
                                value={field.value}
                                onValueChange={field.onChange}
                              >
                                {cards.map((card) => (
                                  <div
                                    className="flex w-full items-center justify-between"
                                    key={card.token}
                                  >
                                    <div className="flex items-center space-x-2">
                                      <RadioGroupItem
                                        value={card.token}
                                        id={`card-${card.token}`}
                                      />
                                      <Label
                                        className="flex flex-row"
                                        variant="checkbox"
                                        htmlFor={`card-${card.token}`}
                                      >
                                        <span className="align-center flex flex-row">
                                          {cardLogo(card.label)} {card.label}
                                        </span>
                                      </Label>
                                    </div>
                                    <TangoDeleteCardButton
                                      cardToken={card.token}
                                      setIsDeleting={setIsDeleting}
                                    />
                                  </div>
                                ))}
                              </RadioGroup>
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <TangoRegisterCardDialog />
                    <FormField
                      control={form.control}
                      name="funds"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          <FormLabel>Funds</FormLabel>
                          <FormControl>
                            <CurrencyInput
                              {...field}
                              value={field.value || ""}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className="flex flex-row justify-between">
                      <div className="text-md flex flex-row text-muted-foreground">
                        Fee (3.5%){" "}
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <QuestionMarkCircleIcon className="ml-2 h-5 w-5" />
                            </TooltipTrigger>
                            <TooltipContent>
                              Credit card processing fee
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <div>{curr(fee)}</div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="text-md text-muted-foreground">
                        Total payment amount
                      </div>
                      <div>{curr(total)}</div>
                    </div>
                    <DialogFooter>
                      <Button variant="outline" onClick={() => setOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        isLoading={addFunds.isLoading}
                        disabled={isDeleting}
                        type="submit"
                      >
                        Add Funds
                      </Button>
                    </DialogFooter>
                  </form>
                </Form>
              </TabsContent>
              <TabsContent value="transfer">
                <div className="m-4 flex min-h-[450px] flex-row">
                  <ExclamationCircleIcon className="mr-2 size-10 text-orange-500" />
                  Please contact your account representative for ACH
                  instructions to fund your account
                </div>
              </TabsContent>
            </Tabs>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
