import { useEffect } from "react";
import Title from "@/components/ui/title";
import {
  useExport,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { CustomerInvoice } from "@/types";
import { Table } from "@tanstack/react-table";
import StatCard from "@/components/ui/stat-card";
import { curr } from "@/lib/curr";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTitle } from "@/lib/title";
import InvoicesTable from "@/components/orders/InvoicesTable";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { ORDERS_KEY } from "@/api/orders";

export default function Invoices() {
  useTitle("Invoices");

  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const tableState = useManualTableState(
    [
      {
        id: "invoiced_at",
        desc: true,
      },
    ],
    {
      bucket: "invoiced",
      filter: {
        ["invoiced_at"]: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    },
  );
  const { setQuery } = tableState;

  useEffect(() => {
    tableState.setFilters((prev) => ({
      ...prev,
      filter: {
        ["invoiced_at"]: dateRange && {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const result = useManualTableQuery<
    CustomerInvoice,
    {
      count: number;
      sum_total: number;
      sum_balance: number;
    }
  >(ORDERS_KEY, "/orders", tableState);
  const { data, isLoading } = result;

  const downloadRequest = useExport("/orders", tableState);

  return (
    <div className="flex-1 space-y-6">
      <Title>Invoices</Title>

      <Tabs
        value={tableState.filters.bucket}
        onValueChange={(v) =>
          tableState.setFilters((prev) => ({ ...prev, bucket: v }))
        }
      >
        <TabsList className="mb-6">
          <TabsTrigger value="invoiced">Invoiced</TabsTrigger>
          <TabsTrigger value="outstanding">Outstanding</TabsTrigger>
          <TabsTrigger value="past_due">Past-Due</TabsTrigger>
          <TabsTrigger value="all">All</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <StatCard
          title="# Invoices"
          value={data?.count.toLocaleString()}
          isLoading={isLoading}
        />
        <StatCard
          title="Total"
          value={curr(data?.sum_total)}
          isLoading={isLoading}
        />
        <StatCard
          title="Balance"
          value={curr(data?.sum_balance)}
          isLoading={isLoading}
        />
      </div>
      <InvoicesTable
        header={(table: Table<CustomerInvoice>) => (
          <DataTableActions
            dateRange={dateRange}
            downloadRequest={downloadRequest}
            placeholder="Search by number or description"
            setDateRange={setDateRange}
            setQuery={setQuery}
            table={table}
          />
        )}
        tableState={tableState}
        query={result}
      />
    </div>
  );
}
