import { CustomerDetail } from "@/types";
import { useEffect } from "react";
import { ALLOWS_SIGNUP } from "@/constants/services";

export function hexToRgb(hex: string): [number, number, number] {
  // Remove the hash (#) if present
  hex = hex.replace(/^#/, "");

  // Ensure the hex code is either 3 or 6 characters long
  if (hex.length === 3) {
    // Convert shorthand (e.g., "f00") to full form (e.g., "ff0000")
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  // Parse the hex values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return [r, g, b];
}

function calculateLuminance(r: number, g: number, b: number) {
  // Convert RGB values to linear space
  const [rLinear, gLinear, bLinear] = [r, g, b].map((channel) => {
    const normalized = channel / 255;
    return normalized <= 0.03928
      ? normalized / 12.92
      : Math.pow((normalized + 0.055) / 1.055, 2.4);
  });

  // Calculate relative luminance
  return 0.2126 * rLinear! + 0.7152 * gLinear! + 0.0722 * bLinear!;
}

export function getContrastColor(hex: string): string {
  // Convert hex to RGB
  const rgb = hexToRgb(hex);

  // Calculate relative luminance
  const luminance = calculateLuminance(rgb[0], rgb[1], rgb[2]);

  // Return black for light backgrounds and white for dark backgrounds
  return luminance > 0.5 ? "#111827" : "#f9fafb";
}

export function useTheme(
  theme: Pick<CustomerDetail, "color" | "drawer_color">,
) {
  useEffect(() => {
    if (theme.color) {
      document.body.style.setProperty(
        "--primary",
        hexToRgb(theme.color).join(" "),
      );
      document.body.style.setProperty(
        "--primary-foreground",
        hexToRgb(getContrastColor(theme.color)).join(" "),
      );
    } else {
      document.body.style.removeProperty("--primary");
      document.body.style.removeProperty("--primary-foreground");
    }

    if (!ALLOWS_SIGNUP) {
      document.body.style.setProperty("--drawer", "241 245 249");
      document.body.style.setProperty("--drawer-foreground", "17 24 39");
    } else if (theme.drawer_color) {
      document.body.style.setProperty(
        "--drawer",
        hexToRgb(theme.drawer_color).join(" "),
      );
      document.body.style.setProperty(
        "--drawer-foreground",
        hexToRgb(getContrastColor(theme.drawer_color)).join(" "),
      );
    } else {
      document.body.style.removeProperty("--drawer");
      document.body.style.removeProperty("--drawer-foreground");
    }
  }, [theme.color, theme.drawer_color]);
}
