import { useState } from "react";
import { CustomerMembership } from "@/types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useCanInvite } from "@/hooks/useCanInvite";
import { useCanLeave } from "@/hooks/useCanLeave";
import { useMemberships } from "@/api/membership";
import { PaginateList } from "@/components/ui/paginate-list";
import TeamMemberRow from "@/components/account/TeamMemberRow";

export default function TeamMembersCard() {
  const [nameFilter, setNameFilter] = useState<string>("");
  const { data: memberships = [] } = useMemberships();

  const canInvite = useCanInvite();
  const canLeave = useCanLeave();

  function renderChildren(m: CustomerMembership): JSX.Element {
    return (
      <TeamMemberRow
        key={m.id}
        membership={m}
        canInvite={canInvite}
        canLeave={canLeave}
      />
    );
  }

  const filterItem = (membership: CustomerMembership, query: string) =>
    membership.user.name.toLowerCase().includes(query.toLowerCase());

  return (
    <Card>
      <CardHeader className="flex flex-row items-center gap-4 space-y-0">
        <CardTitle className="flex-grow">Team Members</CardTitle>
        {memberships.length > 6 && (
          <Input
            type="search"
            value={nameFilter}
            placeholder="Search by name"
            className="flex-0 w-64"
            onChange={(e) => setNameFilter(e.target.value)}
          />
        )}
      </CardHeader>
      <CardContent>
        <PaginateList
          filterItem={filterItem}
          items={memberships}
          itemsPerPage={10}
          renderChildren={renderChildren}
          query={nameFilter}
        />
      </CardContent>
    </Card>
  );
}
