import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSetBusiness } from "@/context/BusinessContext";
import { CustomerOrder, OrderItem, CustomerQuote } from "@/types";
import { useToast } from "@/components/ui/use-toast";

interface QuoteWithDetails extends CustomerQuote {
  items: OrderItem[];
}

export const QUOTES_KEY = "quotes";

export function useGetQuote(code: string) {
  const setBusiness = useSetBusiness();
  return useQuery(
    [QUOTES_KEY, "detail", code],
    () =>
      axios.get<QuoteWithDetails>(`/quotes/${code}`).then(({ data }) => {
        setBusiness(data.business);
        return data;
      }),
    {
      refetchOnWindowFocus: true,
    },
  );
}

export function useAcceptQuote(code: string) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();

  return useMutation(() =>
    axios.post<CustomerOrder>(`/quotes/${code}/accept`).then(({ data }) => {
      queryClient.invalidateQueries([QUOTES_KEY, "detail", code]);
      navigate(`/orders/${data.code}`);
      toast({
        title: "Thank you for accepting the quote",
        description: "Your order is now being processed.",
        variant: "success",
      });
    }),
  );
}

export function useDeclineQuote(code: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((payload: { reason?: string }) =>
    axios
      .post<QuoteWithDetails>(`/quotes/${code}/decline`, payload)
      .then(({ data }) => {
        queryClient.setQueryData([QUOTES_KEY, "detail", code], data);
        toast({
          title: "Your quote has been declined",
          description:
            "Your sales rep or account manager will be in touch soon.",
          variant: "info",
        });
      }),
  );
}
