import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CartProduct } from "@/types";

export const PRODUCTS_KEY = "products";

export function getProductQueryKey(productId: number | undefined | null) {
  return [PRODUCTS_KEY, "detailById", productId];
}

export function useSearchProducts({
  query,
  filters = {},
  page = 0,
  params: otherParams,
}: {
  query: string;
  filters?: { [key: string]: string | undefined };
  params?: Record<string, string | boolean>;
  page?: number;
}) {
  const queryClient = useQueryClient();
  const params = { query, filters, page: page + 1, ...otherParams };

  return useQuery([PRODUCTS_KEY, "search", params], () =>
    axios
      .get<{
        data: CartProduct[];
        meta: { current_page: number; last_page: number };
        facets: Record<string, Record<string, number>>;
      }>("/products", {
        params,
      })
      .then(({ data }) => {
        data.data.forEach((product) => {
          queryClient.setQueryData(
            [PRODUCTS_KEY, "detailById", product.id],
            product,
          );
        });
        return data;
      }),
  );
}

export function useGetProduct(slug: string) {
  return useQuery([PRODUCTS_KEY, "detail", slug], () =>
    axios
      .get<{ data: CartProduct; colors: CartProduct[] }>(`/products/${slug}`)
      .then(({ data }) => data),
  );
}

export function useGetProductById(id?: number | null) {
  return useQuery(
    getProductQueryKey(id),
    () =>
      axios.get<CartProduct>(`/products/by-id/${id}`).then(({ data }) => data),
    { staleTime: Infinity, refetchOnMount: false, enabled: id != null },
  );
}
