import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AddressPayload } from "@/types";
import { useToast } from "@/components/ui/use-toast";

export const TANGO_ACCOUNT_KEY = "tangoAccount";

interface RegisterCardPayload {
  last_name: string;
  first_name: string;
  expiration: string;
  number: string;
  verification: string;
}

interface CreditCardType {
  accountIdentifier: string;
  accountNumber: string;
  activationDate: string;
  contactInformation: {
    fullName: string;
    emailAddress: string;
  }[];
  emailAddress: string;
  fullName: string;
  createdDate: string;
  customerIdentifier: string;
  expirationDate: string;
  label: string;
  lastFourDigits: string;
  status: "ACTIVE" | "INACTIVE";
  token: string;
}

export interface AccountType {
  id: string;
  number: string;
  name: string;
  email: string;
  balance: number;
  status: string;
  created_at: string;
}

interface AddFundsType {
  accountNumber: string;
  amount: number;
  amountCharged: number;
  credtedDate: string;
  feePerfect: number;
  referenceDepositID: string;
  status: string;
}

export function useGetTangoAccount(enabled = true) {
  return useQuery(
    [TANGO_ACCOUNT_KEY, "account"],
    () => axios.get<AccountType>("tango-account").then(({ data }) => data),
    {
      enabled,
    },
  );
}

export function useGetCreditCards() {
  return useQuery([TANGO_ACCOUNT_KEY, "cards"], () =>
    axios
      .get<CreditCardType[]>("tango-account/credit-cards")
      .then(({ data }) => data),
  );
}

export function useRegisterCard() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (payload: { address: AddressPayload; creditCard: RegisterCardPayload }) =>
      axios
        .post<CreditCardType>("/tango-account/register", payload)
        .then(({ data }) => {
          toast({
            title: "Credit Card Added",
            variant: "success",
          });
          return data;
        }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CreditCardType[]>(
          [TANGO_ACCOUNT_KEY, "cards"],
          (prev) => {
            if (!prev) {
              return prev;
            }
            return [...prev, data];
          },
        );
      },
    },
  );
}

export function useUnregisterCard() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (cardId: string) =>
      axios.post("/tango-account/unregister", { cardId }).then(() => {
        toast({
          title: "Credit Card Deleted",
          variant: "success",
        });
      }),
    {
      onSuccess: (_, cardId) => {
        queryClient.setQueryData<CreditCardType[]>(
          [TANGO_ACCOUNT_KEY, "cards"],
          (prev) => {
            if (!prev) {
              return prev;
            }
            return prev.filter((card) => card.token !== cardId);
          },
        );
      },
    },
  );
}

export function useAddFunds(onSuccess: () => void) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (payload: { cardId: string; funds: number | string }) =>
      axios
        .post<AddFundsType>("/tango-account/add-funds", payload)
        .then(({ data }) => {
          onSuccess();
          toast({
            title: "Funds Added",
            variant: "success",
          });
          return data;
        }),
    {
      onSuccess: (data: AddFundsType) => {
        queryClient.setQueryData<AccountType>(
          [TANGO_ACCOUNT_KEY, "account"],
          (prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              balance: prev.balance + data.amount,
            };
          },
        );
      },
    },
  );
}
