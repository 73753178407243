import Title from "@/components/ui/title";
import ProjectFieldForm from "@/components/projects/ProjectFieldForm";
import Toolbar from "@/components/ui/toolbar";
import { useGetString, useSettings } from "@/context/AppContext";
import Markdown from "@/components/ui/markdown";
import WizardStep from "@/components/wizard/WizardStep";
import { ProjectFieldPayload } from "@/types";

export default function ProjectCustomFields({
  defaultValues,
  onSubmit,
}: {
  defaultValues: { fields?: ProjectFieldPayload[] };
  onSubmit: (v: { fields: ProjectFieldPayload[] }) => Promise<void>;
}) {
  const getString = useGetString();
  const settings = useSettings();

  return (
    <WizardStep>
      <Toolbar>
        <Title>Additional Information</Title>
        <div className="prose mt-1 max-w-full text-base text-muted-foreground">
          <Markdown>
            {getString(
              "project_additional_information_description",
              "Please provide the following information that your company requires.",
            )}
          </Markdown>
        </div>
      </Toolbar>

      <ProjectFieldForm
        onSubmit={onSubmit}
        initialFields={defaultValues.fields}
        customerProjectFields={settings.project_fields}
        submitLabel="Next"
      />
    </WizardStep>
  );
}
