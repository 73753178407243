import {
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { CustomerOrderApproval } from "@/types";
import { handleLaravelErrors } from "@/lib/form";
import { useQueryClient } from "@tanstack/react-query";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useMaybeUser } from "@/context/AppContext";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { APPROVALS_KEY } from "@/api/approval";

const formSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  reason_category: z.string(),
  reason: z.string(),
});

function getFiniteReason(approval: CustomerOrderApproval) {
  if (approval.is_art && !approval.is_order) {
    return "art";
  }
  if (approval.is_order && !approval.is_art) {
    return "order";
  }

  return undefined;
}

export default function DeclineForm({
  approval,
}: {
  approval: CustomerOrderApproval;
}) {
  const user = useMaybeUser();

  const queryClient = useQueryClient();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user?.name,
      email: user?.email,
      reason_category: getFiniteReason(approval),
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return axios
      .post<CustomerOrderApproval>(
        `/approvals/${approval.code}/decline`,
        values,
      )
      .then(() => {
        queryClient.invalidateQueries([APPROVALS_KEY]);
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogHeader>
          <DialogTitle>Decline Order</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {!getFiniteReason(approval) && (
            <FormField
              control={form.control}
              name="reason_category"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reason for Decline</FormLabel>
                  <RadioGroup
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <div className="flex items-center space-x-3">
                      <RadioGroupItem value="art" id="art-option" />
                      <div>
                        <Label variant="checkbox" htmlFor="art-option">
                          Art
                        </Label>
                        <div className="text-sm text-slate-500">
                          e.g. Incorrect placement of logo, wrong PMS color,
                          etc.
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <RadioGroupItem value="order" id="order-option" />
                      <div>
                        <Label variant="checkbox" htmlFor="order-option">
                          Order
                        </Label>
                        <div className="text-sm text-slate-500">
                          e.g. Wrong sizes, incorrect shipping address, etc.
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <RadioGroupItem
                        value="art_and_order"
                        id="art-order-option"
                      />
                      <div>
                        <Label variant="checkbox" htmlFor="art-order-option">
                          Both of above
                        </Label>
                        <div className="text-sm text-slate-500">
                          e.g. Wrong sizes &amp; wrong placement of logo
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <RadioGroupItem value="other" id="other-option" />
                      <div>
                        <Label variant="checkbox" htmlFor="other-option">
                          Something else is incorrect
                        </Label>
                        <div className="text-sm text-slate-500">
                          e.g. I no longer need this order
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Please explain</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <DialogFooter>
          <Button
            type="submit"
            variant="destructive"
            isLoading={form.formState.isSubmitting}
          >
            Decline
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
