import { useState } from "react";
import { CustomerMembership } from "@/types";
import { useUpdateMembershipRequest } from "@/api/membership";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { XIcon } from "lucide-react";

export default function RemoveMemberFromTeamButton({
  member,
}: {
  member: CustomerMembership;
}) {
  const [open, setOpen] = useState(false);
  const updateRequest = useUpdateMembershipRequest();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <XIcon className="size-4 text-slate-500" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Remove Member From Budget</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want remove this member from this budget?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={updateRequest.isLoading}
            onClick={() =>
              updateRequest
                .mutateAsync({
                  id: member.id,
                  team_id: null,
                })
                .then(() => {
                  setOpen(false);
                })
            }
          >
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
