import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { Notification } from "@/types";
import { useCallback, useEffect } from "react";
import { useAppState } from "@/context/AppContext";
import Pusher from "pusher-js";

declare global {
  interface Window {
    Pusher: typeof Pusher;
  }
}

window.Pusher = Pusher;

export const NOTIFICATIONS_KEY = "notifications";

interface NotificationsRes {
  data: Notification[];
  meta: {
    per_page: number;
    next_cursor?: string;
    prev_cursor?: string;
    unread_count: number;
  };
}

export function useNotifications() {
  return useInfiniteQuery(
    [NOTIFICATIONS_KEY],
    ({ pageParam }) =>
      axios
        .get<NotificationsRes>("/notifications", {
          params: {
            cursor: pageParam,
            count: 12,
          },
        })
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.next_cursor;
      },
      refetchOnMount: false,
    },
  );
}

export function useUpdateReadAt() {
  const queryClient = useQueryClient();

  return useCallback(
    (ids: number[], read_at: string | null, unread_count: number) => {
      queryClient.setQueryData<InfiniteData<NotificationsRes>>(
        [NOTIFICATIONS_KEY],
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            pages: prev.pages.map((p) => ({
              ...p,
              meta: {
                ...p.meta,
                unread_count,
              },
              data: p.data.map((n) => {
                if (ids.includes(n.id)) {
                  return {
                    ...n,
                    read_at,
                  };
                }
                return n;
              }),
            })),
          };
        },
      );
    },
    [queryClient],
  );
}

export function useNotificationMarkRead() {
  const updateReadAt = useUpdateReadAt();
  return useMutation((ids: number[]) => {
    return axios
      .post<{ unread_count: number }>("/notifications/mark-read", { ids })
      .then(({ data }) => {
        updateReadAt(ids, new Date().toISOString(), data.unread_count);
      });
  });
}

export function useNotificationMarkUnread() {
  const updateReadAt = useUpdateReadAt();
  return useMutation((ids: number[]) => {
    return axios
      .post<{ unread_count: number }>("/notifications/mark-unread", { ids })
      .then(({ data }) => {
        updateReadAt(ids, null, data.unread_count);
      });
  });
}

export function useOnNewNotification() {
  const queryClient = useQueryClient();
  return useCallback(
    (notification: Notification) => {
      queryClient.setQueryData<InfiniteData<NotificationsRes>>(
        [NOTIFICATIONS_KEY],
        (prev) => {
          if (!prev || !prev.pages[0]) return prev;

          const [firstPage, ...otherPages] = prev.pages;
          return {
            ...prev,
            pages: [
              {
                meta: {
                  ...firstPage.meta,
                  unread_count: firstPage.meta.unread_count + 1,
                },
                data: [notification, ...firstPage.data],
              },
              ...otherPages,
            ],
          };
        },
      );
    },
    [queryClient],
  );
}

export function useWatchForNewNotifications() {
  const { membership, Echo } = useAppState();
  const membershipId = membership.id;
  const onNewNotification = useOnNewNotification();

  useEffect(() => {
    const channel = Echo.private(`memberships.${membershipId}`);

    channel.listen(
      "NotificationCreated",
      (e: { notification: Notification }) => {
        onNewNotification(e.notification);
      },
    );

    return () => {
      channel.stopListening("NotificationCreated");
    };
  }, [Echo, membershipId, onNewNotification]);
}
