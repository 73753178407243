import { ProjectOption } from "@/types";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tags } from "lucide-react";
import PriceDetails from "@/components/products/PriceDetails";
import { imageUrl } from "@/lib/image";
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react";
import ProjectOptionTags from "@/components/projects/ProjectOptionTags";
import { cn } from "@/lib/utils";

export interface ProjectOptionCardProps {
  option: Pick<
    ProjectOption,
    | "images"
    | "name"
    | "price"
    | "price_qty"
    | "min_qty"
    | "fees"
    | "tags"
    | "sample_order"
  >;
  onClick: () => void;
  children?: ReactNode;
  style?: CSSProperties;
  gutterTop?: boolean;
}

const ProjectOptionCard = forwardRef(
  (
    {
      option,
      onClick,
      children,
      style,
      gutterTop = true,
    }: ProjectOptionCardProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className="group relative flex h-full w-full flex-col rounded-lg border bg-card px-4 py-3 text-left hover:border-slate-300"
        style={style}
      >
        <Avatar
          className={cn("mx-auto size-48 rounded-none", gutterTop && "mt-8")}
        >
          <AvatarImage
            src={imageUrl(option.images[0], { h: 200, w: 200, fit: "contain" })}
          />
          <AvatarFallback>
            <Tags className="size-6" />
          </AvatarFallback>
        </Avatar>

        <div className="absolute left-2 top-2 flex gap-1">
          <ProjectOptionTags option={option} />
        </div>

        <div
          className="my-2 line-clamp-2 min-h-[3rem] text-left text-base font-semibold"
          title={option.name}
        >
          {option.name}
        </div>

        <PriceDetails
          unitPrice={option.price}
          numUnits={option.price_qty}
          isStartingAt={option.price_qty === option.min_qty}
          fees={option.fees}
        />

        {children}
      </button>
    );
  },
);

ProjectOptionCard.displayName = "ProjectOptionCard";

export function ProjectOptionCardSkeleton() {
  return (
    <div className="relative flex flex-col gap-2 rounded-lg border p-4">
      <Avatar className="mx-auto size-40 animate-pulse rounded-none">
        <AvatarFallback>
          <Tags className="size-6" />
        </AvatarFallback>
      </Avatar>

      <div className="space-y-1">
        <div className="h-6 animate-pulse bg-muted" />
        <div className="h-6 animate-pulse bg-muted" />
      </div>
      <div className="h-4 animate-pulse bg-muted" />
    </div>
  );
}

export default ProjectOptionCard;
