import {
  useMutation,
  useQuery,
  useQueryClient,
  WithRequired,
} from "@tanstack/react-query";
import axios from "axios";
import { CustomerMembership } from "@/types";
import { useAppState } from "@/context/AppContext";

export const MEMBERSHIPS_KEY = "memberships";

export function useMemberships(params: Record<string, string> = {}) {
  const {
    membership: { role },
  } = useAppState();

  return useQuery(
    [MEMBERSHIPS_KEY, "list", params],
    () =>
      axios
        .get<{
          data: CustomerMembership[];
        }>("/memberships", {
          params: {
            sort: "role,user.name",
            ...params,
          },
        })
        .then(({ data }) => data.data),
    {
      enabled: role !== "guest" && role !== "member",
    },
  );
}

export function useGetMembership(id: number | null | undefined) {
  return useQuery(
    [MEMBERSHIPS_KEY, "detail", id],
    () =>
      axios
        .get<WithRequired<CustomerMembership, "gifting_balance" | "team">>(
          `/memberships/${id}?with=gifting_balance,team`,
        )
        .then(({ data }) => data),
    {
      enabled: id != null,
    },
  );
}

export function useUpdateMembershipRequest() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      id,
      ...payload
    }: {
      id: number;
      role?: string;
      team_id?: number | null;
    }) =>
      axios
        .put<WithRequired<CustomerMembership, "gifting_balance" | "team">>(
          `/memberships/${id}?with=gifting_balance,team`,
          payload,
        )
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([MEMBERSHIPS_KEY]);
        queryClient.setQueryData([MEMBERSHIPS_KEY, "detail", data.id], data);
      },
    },
  );
}

export function useRemoveMembershipRequest() {
  const queryClient = useQueryClient();
  return useMutation((id: number) => axios.delete(`/memberships/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([MEMBERSHIPS_KEY]);
    },
  });
}
