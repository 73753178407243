import { createContext, useContext } from "react";
import { Project } from "@/types";

export const ProjectContext = createContext<{
  project?: Project;
  onShowConfetti: () => void;
}>({
  onShowConfetti: () => null,
});

export function useProjectContext() {
  return useContext(ProjectContext);
}

export function useProject(): Project {
  const context = useProjectContext();
  if (!context.project) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context.project;
}
