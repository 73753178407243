import { GiftLinkProduct } from "@/types";
import GiftKitSelector from "@/components/gifting/links/GiftKitSelector";
import GiftingProductImage from "@/components/gifting/products/GiftingProductImage";
import { GiftSelections } from "@/api/gifts";
import GiftProductSizeSelector from "@/components/gifting/links/GiftProductSizeSelector";
import GiftSelectableCard from "@/components/gifting/links/GiftSelectableCard";
import pluralize from "pluralize";

export default function GiftProductSelector({
  product,
  onChange,
  value,
  path,
  isSelected,
  onSelect,
  price,
  children,
}: {
  product: GiftLinkProduct;
  onChange: (path: string[], selections: GiftSelections | undefined) => void;
  value: GiftSelections;
  path: string[];
  isSelected: boolean;
  onSelect?: () => void;
  price?: number;
  children?: React.ReactNode;
}) {
  if (product.type === "kit") {
    return (
      <GiftKitSelector
        kitProduct={product}
        onChange={onChange}
        value={value && typeof value === "object" ? value : {}}
        path={path}
        isSelected={isSelected}
        onSelect={onSelect}
        price={price}
      />
    );
  }

  return (
    <GiftSelectableCard
      onSelect={onSelect}
      isSelected={isSelected}
      className="flex items-start gap-4"
    >
      <div>
        <GiftingProductImage
          product={product}
          className="!size-28 rounded-md border bg-white"
        />
      </div>
      <div>
        <div className="mb-2 mt-1">
          <h4 className="font-medium">{product.name}</h4>
          {price != null && <div>{pluralize("credit", price, true)} </div>}
        </div>
        {isSelected ? (
          <GiftProductSizeSelector
            product={product}
            value={typeof value === "number" ? value : null}
            onChange={(v) => onChange(path, v)}
          />
        ) : null}
      </div>
      {children}
    </GiftSelectableCard>
  );
}
