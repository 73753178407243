import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useGetGiftingImages } from "@/api/gifting-images";

export default function InsertImageVariable({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) {
  const { data: images = [] } = useGetGiftingImages();

  if (images.length === 0) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="xs" variant="link" className="h-5">
          Insert Image
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {images.map((image) => (
          <DropdownMenuItem
            key={image.id}
            onSelect={() => {
              onChange(`${value ?? ""}\n[${image.name}]`.trim());
            }}
          >
            {image.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
