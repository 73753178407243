import { ProjectOption } from "@/types";
import PriceDetails from "@/components/products/PriceDetails";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { imageUrl } from "@/lib/image";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tags } from "lucide-react";
import ProjectOptionTags from "@/components/projects/ProjectOptionTags";
import ProductImageTiles from "@/components/products/ProductImageTiles";

export default function ProjectOptionGroupForPrint({
  options,
}: {
  options: ProjectOption[];
}) {
  const option = options[0]!;
  const {
    id,
    name,
    variants,
    description,
    images,
    price,
    price_qty,
    min_qty,
    fees,
  } = option;

  const colorImages = options.map((go) => go.images[0]!);

  return (
    <div className="flex h-full break-inside-avoid-page">
      <div className="w-1/2 space-y-4 p-6">
        <h1 className="font-bold">{name}</h1>
        {colorImages.length > 1 && (
          <div>
            <h2 className="font-semibold">Colors:</h2>
            <ProductImageTiles
              images={colorImages}
              selectedImage={imageUrl(images[0], {
                h: 900,
                w: 900,
                fit: "contain",
              })}
              onImageSelected={() => null}
            />
          </div>
        )}
        <div>
          <PriceDetails
            unitPrice={price}
            numUnits={price_qty}
            isStartingAt={price_qty === min_qty}
            fees={fees}
          />
        </div>
        {variants.length > 1 && (
          <div>
            <h2 className="font-semibold">Sizes:</h2>
            <Table>
              <TableBody>
                <TableRow key={id}>
                  {variants.map((variant, index) => (
                    <TableCell key={index} className="px-6 font-medium">
                      {variant.size}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
        {min_qty > 1 && (
          <div>
            <h2 className="font-semibold">Minimum: {min_qty}</h2>
          </div>
        )}
        <p className="line-clamp-6 overflow-auto text-sm text-gray-700">
          {description}
        </p>
        <div>
          <ProjectOptionTags option={option} expanded />
        </div>
      </div>
      <div className="h-full w-1/2">
        {images && images.length > 0 && (
          <Avatar className="mx-auto size-96 rounded-none">
            <AvatarImage
              src={imageUrl(images[0], { h: 400, w: 400, fit: "contain" })}
            />
            <AvatarFallback>
              <Tags className="size-6" />
            </AvatarFallback>
          </Avatar>
        )}
      </div>
    </div>
  );
}
