import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import { useTitle } from "@/lib/title";
import { useGetGift } from "@/api/gifts";
import { useParams, useSearchParams } from "react-router-dom";
import { DataCell, DataList } from "@/components/ui/data-list";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  BanIcon,
  CopyIcon,
  ExternalLinkIcon,
  MoreVerticalIcon,
  RedoIcon,
  Send,
} from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import Lottie from "lottie-react";
import confetti from "@/assets/lottie/confetti2.json";
import { useCallback, useEffect, useState } from "react";
import GiftStatusSection from "@/components/gifting/GiftStatusSection";
import pluralize from "pluralize";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import GiftingProductCard from "@/components/gifting/products/GiftingProductCard";
import AddressBlock from "@/components/addresses/AddressBlock";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import GiftCancelDialog from "@/components/gifting/GiftCancelDialog";
import GiftRedeemReminderDialog from "@/components/gifting/GiftSendReminderDialog";
import {
  canCancelGift,
  getSendAsExample,
  getSendingMethod,
} from "@/components/gifting/utils";
import { Address } from "@/types";
import { cn } from "@/lib/utils";
import GiftRetryDialog from "@/components/gifting/GiftRetryDialog";
import GiftSignature from "@/components/gifting/links/GiftSignature";
import { useAppState } from "@/context/AppContext";

export default function GiftDetail() {
  const { id } = useParams();
  const { data } = useGetGift(Number(id));
  const { toast } = useToast();
  useTitle("Gift");

  const [showConfetti, setShowConfetti] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    membership: { customer },
  } = useAppState();

  const onShowConfetti = useCallback(() => {
    setShowConfetti(true);
  }, [setShowConfetti]);

  useEffect(() => {
    if (searchParams.has("created")) {
      onShowConfetti();
      setSearchParams((prev) => {
        prev.delete("created");
        return prev;
      });
    }
  }, []);

  const actions = data && (
    <>
      <div className="flex">
        <Input
          value={data.url}
          className="w-72 rounded-r-none text-sm"
          readOnly
        />
        <Button
          variant="outline"
          className="rounded-none border-l-0 border-r-0"
          onClick={() =>
            window.navigator.clipboard.writeText(data.url).then(() => {
              toast({
                title: "Link Copied",
                description: "The gift URL has been copied to your clipboard.",
              });
            })
          }
        >
          <CopyIcon className="h-5 w-5" />
        </Button>
        <Button variant="outline" className="rounded-l-none" asChild>
          <a href={data.url} target="_blank" rel="noreferrer">
            <ExternalLinkIcon className="h-5 w-5" />
          </a>
        </Button>
      </div>
      {(canCancelGift(data) || data.status === "failed") && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="print:hidden">
            <Button size="icon" variant="outline">
              <MoreVerticalIcon className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {data.status === "failed" && (
              <GiftRetryDialog gift={data}>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  <RedoIcon className="mr-2 h-4 w-4" />
                  Retry
                </DropdownMenuItem>
              </GiftRetryDialog>
            )}
            {canCancelGift(data) && (
              <GiftRedeemReminderDialog ids={[data.id]}>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  <Send className="mr-2 h-4 w-4" />
                  Send Reminder
                </DropdownMenuItem>
              </GiftRedeemReminderDialog>
            )}
            {canCancelGift(data) && (
              <GiftCancelDialog gift={data}>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  <BanIcon className="mr-2 h-4 w-4" />
                  Cancel
                </DropdownMenuItem>
              </GiftCancelDialog>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Gift {data && ` to ${data.name}`}</Title>

        {data && (
          <p className="text-muted-foreground">
            Sent by {data.sender?.name || "Unknown"}
          </p>
        )}
      </Toolbar>

      {data && (
        <div className="space-y-6">
          <GiftStatusSection gift={data} />

          <div
            className={cn(
              "grid gap-4 lg:grid-cols-2",
              data.gift_note && data.should_send && "2xl:grid-cols-3",
            )}
          >
            <Card className="px-5 py-4">
              <div className="mb-4 flex items-center gap-3">
                <h3 className="text-xl font-semibold">Recipient</h3>
                {data.external_source && data.external_url && (
                  <a href={data.external_url!} target="_blank" rel="noreferrer">
                    <IntegrationIcon
                      type={data.external_source}
                      size="size-6"
                    />
                  </a>
                )}
              </div>
              <DataList>
                <DataCell label="First Name">{data.first_name}</DataCell>
                <DataCell label="Last Name">{data.last_name}</DataCell>
                {data.company && (
                  <DataCell label="Company" className="col-span-2">
                    {data.company}
                  </DataCell>
                )}
                <DataCell label="Email" className="col-span-2">
                  {data.email}
                </DataCell>
                {data.fulfillment_type !== "gift_card" && (
                  <>
                    <DataCell label="Address" className="col-span-2">
                      {data.address ? (
                        <AddressBlock address={data.address as Address} />
                      ) : (
                        <p className="text-muted-foreground">
                          Awaiting address from recipient
                        </p>
                      )}
                    </DataCell>
                    <DataCell label="Tracking" className="col-span-2">
                      {data.shipment ? (
                        <a
                          href={data.shipment.tracking_url || "#"}
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium text-primary"
                        >
                          {data.shipment.tracking_number ||
                            "No tracking number"}
                        </a>
                      ) : (
                        <p className="text-muted-foreground">Not shipped yet</p>
                      )}
                    </DataCell>
                  </>
                )}
              </DataList>
            </Card>

            {data.subject || data.message ? (
              <Card className="px-5 py-4">
                <h3 className="mb-4 text-xl font-semibold">
                  {getSendingMethod(data)}
                </h3>
                <DataList className="sm:grid-cols-1">
                  {data.subject && (
                    <DataCell label="Subject">{data.subject}</DataCell>
                  )}
                  <DataCell label="Message">
                    <div className="mb-4 whitespace-pre-line">
                      {data.message}
                    </div>

                    <GiftSignature
                      sender_name={getSendAsExample(
                        data.send_as,
                        data.sender,
                        customer,
                      )}
                      sender={data.sender}
                      customer={customer}
                    />
                  </DataCell>
                </DataList>
              </Card>
            ) : null}

            {data.gift_note ? (
              <Card className="px-5 py-4">
                <h3 className="mb-4 text-xl font-semibold">Custom Note Card</h3>
                <div className="whitespace-pre-line text-sm text-muted-foreground">
                  {data.gift_note}
                </div>
              </Card>
            ) : null}
          </div>
          <section>
            <h3 className="mb-2 text-xl font-semibold">
              {pluralize("Gift", data.items.length)}
            </h3>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {data.items.map((item) => (
                <GiftingProductCard
                  key={item.id}
                  product={item.product}
                  selections={item.selections}
                />
              ))}
            </div>
          </section>
        </div>
      )}

      {showConfetti && (
        <div className="pointer-events-none absolute inset-0">
          <Lottie
            animationData={confetti}
            className="h-full w-full"
            onLoopComplete={() => setShowConfetti(false)}
          />
        </div>
      )}
    </div>
  );
}
