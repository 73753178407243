import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import startCase from "lodash/startCase";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { EyeIcon } from "lucide-react";
import { useUser } from "@/context/AppContext";
import { GiftRecipientPayload } from "@/types";
import {
  GiftVariables,
  renderTemplate,
  TEMPLATE_VARIABLES,
} from "@/components/gifting/templates/utils";

export default function InsertTemplateVariable({
  value,
  onChange,
  recipient: recipientProp,
}: {
  value: string;
  onChange: (newValue: string) => void;
  recipient?: Pick<
    GiftRecipientPayload,
    "first_name" | "last_name" | "company" | "email"
  >;
}) {
  const user = useUser();
  const recipient = recipientProp || {
    first_name: "John",
    last_name: "Doe",
    company: "Acme Corp",
    email: "john.doe@acme.com",
  };
  const variables: GiftVariables = {
    ...recipient,
    sender_name: user.name,
    sender_email: user.email,
  };

  return (
    <div className="flex items-center gap-2">
      {value && value.includes("{{") && value.includes("}}") ? (
        <Popover>
          <PopoverTrigger>
            <EyeIcon className="size-4 text-primary" />
          </PopoverTrigger>
          <PopoverContent className="w-96 whitespace-pre-line text-sm">
            <div className="mb-3 text-xs text-muted-foreground">Preview</div>
            {renderTemplate(value, variables)}
          </PopoverContent>
        </Popover>
      ) : null}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="xs" variant="link" className="h-5">
            Insert Variable
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {TEMPLATE_VARIABLES.map((variable) => (
            <DropdownMenuItem
              key={variable}
              onSelect={() => {
                onChange(`${value ?? ""} {{ ${variable} }}`.trim());
              }}
            >
              {startCase(variable)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
