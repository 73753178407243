import { useFieldArray, UseFormReturn } from "react-hook-form";
import { GiftingProductPayload } from "@/types";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import CurrencyInput from "@/components/ui/currency-input";
import GiftingProductSelect from "@/components/selects/GiftingProductSelect";
import { Button } from "@/components/ui/button";
import { TrashIcon } from "lucide-react";
import { useEffect } from "react";
import SimpleTooltip from "@/components/ui/simple-tooltip";

export default function KitType({
  form,
  isCreate,
}: {
  form: UseFormReturn<GiftingProductPayload>;
  isCreate?: boolean;
}) {
  const variantsField = useFieldArray({
    control: form.control,
    name: "variants",
  });

  useEffect(() => {
    const values = form.getValues();
    if (!values.variants.some((v) => v.kit_product_id)) {
      form.setValue("variants", [{ kit_product_id: null }]);
    }
  }, [form]);

  return (
    <div className="space-y-5">
      {isCreate && (
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  value={field.value || ""}
                  placeholder="e.g. New Hire Kit"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      <div>
        <Label>Products in Kit</Label>
        <div className="space-y-2">
          {variantsField.fields.map((f, i) => (
            <div className="flex items-center gap-3">
              <FormField
                key={f.id}
                control={form.control}
                name={`variants.${i}.kit_product_id`}
                render={({ field }) => (
                  <FormItem className="min-w-0 flex-grow">
                    <GiftingProductSelect
                      value={field.value || null}
                      onChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                key={f.id}
                control={form.control}
                name={`variants.${i}.hide`}
                render={({ field }) => (
                  <FormItem>
                    <SimpleTooltip tooltip="Hide this product from the recipient">
                      <Switch
                        checked={field.value ?? false}
                        onCheckedChange={field.onChange}
                        className="data-[state=checked]:bg-amber-400"
                      />
                    </SimpleTooltip>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i > 0 ? (
                <button
                  type="button"
                  onClick={() => variantsField.remove(i)}
                  className="hover:text-danger-foreground px-2 text-muted-foreground"
                >
                  <TrashIcon className="size-4" />
                </button>
              ) : (
                <div className="w-8" />
              )}
            </div>
          ))}
          <Button
            variant="outline"
            size="xs"
            onClick={() =>
              variantsField.append({
                kit_product_id: null,
              })
            }
          >
            Add Product
          </Button>
        </div>
      </div>

      <FormField
        control={form.control}
        name="kit_product_limit"
        render={({ field }) => (
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Switch
                id="kit-product-limit-switch"
                checked={field.value != null}
                onCheckedChange={(checked) =>
                  field.onChange(checked ? "" : null)
                }
              />
              <Label variant="checkbox" htmlFor="kit-product-limit-switch">
                Limit the number of products the recipient can choose
              </Label>
            </div>

            {field.value != null && (
              <FormItem>
                <FormLabel>Number of Products</FormLabel>
                <FormControl>
                  <Input type="number" {...field} value={field.value!} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          </div>
        )}
      />

      <FormField
        control={form.control}
        name="kit_price_limit"
        render={({ field }) => (
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Switch
                id="kit-price-limit-switch"
                checked={field.value != null}
                onCheckedChange={(checked) =>
                  field.onChange(checked ? "" : null)
                }
              />
              <Label variant="checkbox" htmlFor="kit-price-limit-switch">
                Limit products the recipient can choose by the total price
              </Label>
            </div>

            {field.value != null && (
              <FormItem>
                <FormLabel>Value of Products</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} value={field.value!} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          </div>
        )}
      />
    </div>
  );
}
