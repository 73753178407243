import { useState } from "react";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import AddressFields from "@/components/addresses/AddressFields";
import {
  AddressPayload,
  GiftPayload,
  GiftRecipientPayload,
  giftRecipientPayloadSchema,
} from "@/types";
import { Input } from "@/components/ui/input";
import { useGetIntegrations } from "@/api/integrations";
import { AutoComplete } from "@/components/ui/autocomplete";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { Switch } from "@/components/ui/switch";
import { useGetIntegrationRecipients } from "@/api/integration-recipients";

function FirstNameInput({
  field,
  onContact,
}: {
  field: ControllerRenderProps<GiftRecipientPayload, "first_name">;
  onContact: (contact: Partial<GiftPayload>) => void;
}) {
  const { data, isLoading, isFetching } = useGetIntegrationRecipients({
    query: field.value,
  });

  return (
    <AutoComplete
      value={field.value}
      onValueChange={field.onChange}
      emptyMessage="No contacts found"
      loadingMessage="Searching contacts from your integrations..."
      onOptionSelect={(option) => {
        const contact = data?.find((c) => c.id === option.value);
        if (contact) {
          onContact(contact);
        }
      }}
      options={data?.map((c) => ({
        value: c.id,
        label: (
          <div className="flex w-full items-center">
            <div className="flex-grow">
              <div className="text-sm">
                {c.first_name} {c.last_name}
              </div>
              <div className="text-xs text-muted-foreground">{c.email}</div>
            </div>
            <IntegrationIcon type={c.external_source!} size="size-6" />
          </div>
        ),
      }))}
      isLoading={isLoading && isFetching}
    />
  );
}

export default function GiftRecipientForm({
  defaultValues,
  onSubmit: onSubmitProp,
  children,
  isDigitalGift,
}: {
  defaultValues: Partial<GiftRecipientPayload>;
  onSubmit: (v: GiftRecipientPayload) => Promise<void>;
  children?: React.ReactNode;
  isDigitalGift: boolean;
}) {
  const [collectAddressLater, setCollectAddressLater] = useState<boolean>(
    !defaultValues.address,
  );
  const form = useForm<GiftRecipientPayload>({
    resolver: zodResolver(giftRecipientPayloadSchema),
    defaultValues: {
      ...defaultValues,
      address: defaultValues.address
        ? (defaultValues.address as GiftRecipientPayload["address"])
        : null,
    },
  });
  const { data = [] } = useGetIntegrations();

  const onSubmit = (values: GiftRecipientPayload) => {
    return onSubmitProp({
      ...values,
      address: collectAddressLater
        ? null
        : ({
            name: `${values.first_name} ${values.last_name}`.trim(),
            company: values.company,
            ...values.address,
          } as AddressPayload),
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid max-w-4xl grid-cols-2 gap-5">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  {data.some((i) => i.is_connected) ? (
                    <FirstNameInput
                      field={field}
                      onContact={(contact) => {
                        Object.entries(contact).forEach(([key, value]) => {
                          form.setValue(
                            key as keyof GiftRecipientPayload,
                            value as string | null,
                          );
                        });
                        if (contact.address) {
                          setCollectAddressLater(false);
                        }
                      }}
                    />
                  ) : (
                    <Input {...field} value={field.value || ""} />
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="company"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Company</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {!isDigitalGift && (
            <>
              <div className="col-span-full flex items-center gap-2">
                <Switch
                  id="collectAddressLater"
                  checked={collectAddressLater}
                  onCheckedChange={(later) => {
                    setCollectAddressLater(later);
                    if (later) {
                      form.setValue("address", null);
                    } else {
                      form.setValue("address", {
                        name: "TO BE REPLACED",
                        address_1: "",
                        city: "",
                        zip: "",
                        country: "US",
                      });
                    }
                  }}
                />
                <Label variant="checkbox" htmlFor="collectAddressLater">
                  Ask the recipient for their address as part of the gift
                  redemption
                </Label>
              </div>

              {!collectAddressLater && (
                <div className="col-span-full">
                  <AddressFields form={form} hideNameFields />
                </div>
              )}
            </>
          )}
        </div>

        {children}
      </form>
    </Form>
  );
}
