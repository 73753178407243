import { Webstore } from "@/types";
import startCase from "lodash/startCase";
import StatusLight from "@/components/ui/status-light";

export default function WebstoreStatus({
  status,
}: {
  status: Webstore["status"];
}) {
  const getColor = () => {
    if (status === "live") {
      return "bg-green-500";
    }
    return "bg-slate-500";
  };

  return (
    <div className="inline-flex items-center space-x-2">
      <StatusLight className={getColor()} />
      <span>{startCase(status)}</span>
    </div>
  );
}
