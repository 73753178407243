import { useEffect, useState } from "react";
import { Loader } from "lucide-react";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { projectOptionToPayload } from "@/lib/projects";
import { Label } from "@/components/ui/label";
import EmptyState from "@/components/ui/empty-state";
import ProjectOptionDetails from "@/components/projects/ProjectOptionDetails";
import { ProjectOption, ProjectOptionPayload } from "@/types";
import { usePreviousProjectOptions } from "@/api/project-options";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import ProjectOptionCard from "@/components/projects/ProjectOptionCard";

export default function PreviousOptionsDialog({
  onSelect,
}: {
  onSelect: (payload: ProjectOptionPayload) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [option, setOption] = useState<ProjectOption | null>(null);
  const [mode, setMode] = useState("mine");
  const [query, setQuery] = useState<string>("");

  const { data: optionsRes = [], isLoading } = usePreviousProjectOptions(
    query,
    mode,
  );

  useEffect(() => {
    setQuery("");
    setMode("mine");
    setOption(null);
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="link" size="xs">
          Search Past Options
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl p-0">
        <div className="flex h-[calc(100vh-2rem)] flex-col gap-4 p-6">
          <DialogHeader>
            <DialogTitle>Search Options Previously Provided</DialogTitle>
          </DialogHeader>
          {!option ? (
            <div className="grid gap-4 overflow-hidden p-1 lg:grid-cols-4">
              <div className="space-y-5">
                <Input
                  type="search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="e.g. Yeti Mug"
                />
                <div>
                  <Label className="mb-2 flex items-center">Search Mode</Label>
                  <RadioGroup value={mode} onValueChange={setMode}>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="mine" id="mine-option" />
                      <Label variant="checkbox" htmlFor="mine-option">
                        My Options
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="pinned_mine"
                        id="pinned-mine-option"
                      />
                      <Label variant="checkbox" htmlFor="pinned-mine-option">
                        My Favorites
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="pinned_team"
                        id="pinned-team-option"
                      />
                      <Label variant="checkbox" htmlFor="pinned-team-option">
                        Team Favorites
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <div className="col-span-3 overflow-y-auto">
                <div className="flex items-center justify-center">
                  {isLoading ? (
                    <Loader className="size-10 animate-spin" />
                  ) : optionsRes.length ? (
                    <div className="grid gap-4 lg:grid-cols-3">
                      {optionsRes.map((option) => (
                        <ProjectOptionCard
                          key={option.id}
                          option={option}
                          gutterTop={false}
                          onClick={() => setOption(option)}
                        />
                      ))}
                    </div>
                  ) : (
                    <EmptyState
                      title="No Prior Options Found"
                      description="You have no prior options that match your search criteria"
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex min-h-0 flex-col items-start gap-2">
              <Button
                variant="link"
                onClick={() => setOption(null)}
                className="pl-0"
              >
                &larr; Back to Search
              </Button>
              <ProjectOptionDetails
                option={option}
                children={
                  <div className="mt-6 space-y-6">
                    <Card>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Size</TableHead>
                            <TableHead>Your Price</TableHead>
                            <TableHead>Cost</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {option.variants.map((variant, idx) => (
                            <TableRow key={`${variant.size}-${idx}`}>
                              <TableCell>{variant.size}</TableCell>
                              <TableCell>{curr(variant.price)}</TableCell>
                              <TableCell>{curr(variant.cost)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                    <Button
                      onClick={() => {
                        onSelect(projectOptionToPayload(option));
                        setOpen(false);
                      }}
                    >
                      Choose This Product
                    </Button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
