import { ProjectItem, ProjectOption, ProjectOptionPayload } from "@/types";
import { FolderPlus, Plus } from "lucide-react";
import EmptyState from "@/components/ui/empty-state";
import { Button } from "@/components/ui/button";
import {
  useCreateProjectOption,
  useSortProjectOptions,
  useUpdateProjectOption,
} from "@/api/project-options";
import { ProjectOptionCardSkeleton } from "@/components/projects/ProjectOptionCard";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import ProjectOptionForm from "@/components/projects/ProjectOptionForm";
import { useRef, useState } from "react";
import ProjectCardGrid from "@/components/projects/ProjectCardGrid";
import { projectOptionToPayload } from "@/lib/projects";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import SortableProjectOptionCard from "@/components/projects/SortableProjectOptionCard";

export default function ProjectItemProvideOptions({
  item,
  options,
  optionsLoading,
}: {
  item: ProjectItem;
  options: ProjectOption[];
  optionsLoading: boolean;
}) {
  const [optionId, setOptionId] = useState<number | "create">();
  const createOption = useCreateProjectOption();
  const updateOption = useUpdateProjectOption(Number(optionId));
  const sortOptions = useSortProjectOptions();
  const optionFormRef = useRef<{ isDirty: boolean } | null>(null);

  const onClose = () => setOptionId(undefined);

  const onSubmit = (values: ProjectOptionPayload) => {
    if (optionId === "create") {
      return createOption.mutateAsync(values).then(onClose);
    }
    return updateOption.mutateAsync(values).then(onClose);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = options.findIndex((o) => o.id === active.id);
      const newIndex = options.findIndex((o) => o.id === over.id);

      sortOptions.mutate(
        arrayMove(options, oldIndex, newIndex).map((o) => o.id),
      );
    }
  };

  const option = options.find((o) => o.id === optionId);

  return (
    <div className="mt-2">
      {optionsLoading && (
        <ProjectCardGrid>
          <ProjectOptionCardSkeleton />
          <ProjectOptionCardSkeleton />
          <ProjectOptionCardSkeleton />
        </ProjectCardGrid>
      )}

      {options.length > 0 && (
        <DndContext onDragEnd={handleDragEnd}>
          <ProjectCardGrid>
            <SortableContext items={options.map((o) => o.id)}>
              {options.map((option) => (
                <SortableProjectOptionCard
                  key={option.id}
                  option={option}
                  onClick={() => setOptionId(option.id)}
                />
              ))}
            </SortableContext>
          </ProjectCardGrid>
        </DndContext>
      )}

      {options.length === 0 ? (
        <div className="px-6 py-3">
          <EmptyState
            Icon={FolderPlus}
            title="No Options"
            description="Get started by adding an option"
          >
            <Button variant="default" onClick={() => setOptionId("create")}>
              Add Option <Plus className="ml-2 size-5" />
            </Button>
          </EmptyState>
        </div>
      ) : (
        <div className="mt-4 flex justify-end">
          <Button variant="link" onClick={() => setOptionId("create")}>
            Add Option <Plus className="ml-2 size-5" />
          </Button>
        </div>
      )}

      <Sheet
        open={optionId != undefined}
        onOpenChange={() => {
          if (
            optionFormRef.current?.isDirty &&
            !confirm("You have unsaved changed. Are you sure you want to exit?")
          ) {
            return;
          }
          onClose();
        }}
      >
        <SheetContent className="overflow-y-auto sm:max-w-xl">
          <SheetHeader className="mb-2">
            <SheetTitle>
              {optionId === "create" ? "Add" : "Edit"} Option
            </SheetTitle>
          </SheetHeader>
          <ProjectOptionForm
            key={optionId}
            ref={optionFormRef}
            onSubmit={onSubmit}
            defaultValues={
              option
                ? projectOptionToPayload(option)
                : {
                    product_type: "database",
                    project_item_id: item.id,
                    images: [],
                    target_margin: 40,
                    enable_color_options: true,
                    tags: {},
                    sample_order_id: null,
                    is_pinned: false,
                  }
            }
          />
        </SheetContent>
      </Sheet>
    </div>
  );
}
