import { CustomerOrder } from "@/types";
import { useGetOrder } from "@/api/orders";
import { Link } from "react-router-dom";

export default function OrderTrackingNumbers({
  order,
}: {
  order: CustomerOrder;
}) {
  const { data } = useGetOrder(order.code);

  const shipments = data?.shipments.filter((s) => !!s.tracking_url) || [];

  if (shipments.length === 0) {
    return null;
  }

  return (
    <div className="mt-3">
      <div>Tracking:</div>
      {shipments
        .map((s) => (
          <a
            className="block text-primary hover:underline"
            key={s.id}
            href={s.tracking_url!}
            target="_blank"
            rel="noopener noreferrer"
          >
            {s.tracking_number}
          </a>
        ))
        .slice(0, 2)}
      {shipments.length > 2 && (
        <Link
          to={`./orders/${order.code}`}
          className="block text-muted-foreground"
        >
          and {shipments.length - 2} more
        </Link>
      )}
    </div>
  );
}
