import { ProductCollection } from "@/types";
import { cn } from "@/lib/utils";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import CollectionCard from "@/components/collections/CollectionCard";

export default function CollectionButton({
  collection,
  selected,
  onSelect,
}: {
  collection: ProductCollection;
  selected: boolean;
  onSelect: (c: ProductCollection) => void;
}) {
  return (
    <button
      type="button"
      onClick={() => onSelect(collection)}
      className="relative"
    >
      <CollectionCard
        collection={collection}
        className={cn(
          selected ? "border-primary ring-2 ring-primary" : "border-slate-300",
        )}
      />

      {selected && (
        <CheckCircleIcon className="absolute right-2 top-2 z-[1] size-6 text-primary" />
      )}
    </button>
  );
}
