import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { GiftingProduct } from "@/types";
import { useToast } from "@/components/ui/use-toast";
import { useDeleteGiftingProduct } from "@/api/gifting-products";
import { useNavigate } from "react-router-dom";

export default function GiftingProductDeleteDialog({
  product,
  children,
}: {
  product: GiftingProduct;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const deleteRequest = useDeleteGiftingProduct();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Gifting Product</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want delete this product?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={deleteRequest.isLoading}
            onClick={() =>
              deleteRequest.mutateAsync(product.id).then(() => {
                setOpen(false);
                toast({
                  title: "Successfully deleted product",
                  variant: "success",
                });
                navigate("/gifting-products");
              })
            }
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
