import { ProjectActivity } from "@/types";
import { Trash } from "lucide-react";
import { useDeleteProjectActivity } from "@/api/project-activity";
import ConfirmationButton from "@/components/ui/confirmation-button";
import UserAvatar from "@/components/account/UserAvatar";
import { useUser } from "@/context/AppContext";
import ProjectMessageItem, {
  BUBBLE_CLASS,
  ProjectMessageBubble,
} from "@/components/projects/activity/ProjectMessageItem";
import { cn } from "@/lib/utils";
import { ProjectActivityReactions } from "@/components/projects/activity/ProjectActivityReactions";
import AddReactionButton from "@/components/projects/activity/AddReactionButton";
import { useProjectSelections } from "@/api/project-selections";
import { formatDateTime, formatRelative } from "@/lib/dates";

export default function ProjectActivityItem({
  activity,
}: {
  activity: ProjectActivity;
}) {
  const { data } = useProjectSelections();
  const selections = data?.data || [];
  const deleteRequest = useDeleteProjectActivity();
  const me = useUser();
  const isMe = me.id === activity.user.id;

  const getDescription = () => {
    if (activity.eventable) {
      if (activity.type === "added_user") {
        return (
          <>
            <span> added </span>
            <strong className="font-medium">{activity.eventable.name}</strong>
            <span> to the project</span>
          </>
        );
      }
      if (activity.type === "removed_user") {
        return (
          <>
            <span> removed </span>
            <strong className="font-medium">{activity.eventable.name}</strong>
            <span> from the project</span>
          </>
        );
      }
      if (
        activity.type === "approved_order" ||
        activity.type === "rejected_order"
      ) {
        const option = selections.find(
          (s) => s.option.order_id === activity.eventable.id,
        )?.option;
        return (
          <>
            <span>
              {activity.type === "approved_order" ? " approved " : " declined "}
            </span>
            <strong className="font-medium">
              {option ? option.name : activity.eventable.description}
            </strong>
          </>
        );
      }
      if (
        activity.type === "archived_option" ||
        activity.type === "restored_option"
      ) {
        return (
          <>
            {activity.description.replace("an option", activity.eventable.name)}
          </>
        );
      }
    }
    return activity.description;
  };

  return (
    <li>
      <div className="group relative flex items-center text-xs">
        <UserAvatar
          className="size-6 -translate-x-1/2 rounded-full bg-white"
          user={activity.user}
          fallbackClassName="bg-slate-300"
        />
        <p>
          <strong className="font-medium">{activity.user.name} </strong>
          <span className="text-muted-foreground">{getDescription()}</span>
        </p>
        <div className="ml-auto flex items-center">
          <time
            className="!ml-3 whitespace-nowrap text-slate-400"
            dateTime={activity.created_at}
            title={formatDateTime(activity.created_at)}
          >
            {formatRelative(activity.created_at)}
          </time>
        </div>

        <div className="invisible absolute right-0 top-0 divide-x overflow-hidden rounded-md border bg-white shadow-sm group-focus-within:visible group-hover:visible">
          <AddReactionButton
            activityId={activity.id}
            className="px-2 py-1 hover:bg-primary/10"
          />

          {activity.type === "messaged" && isMe && (
            <ConfirmationButton
              title="Delete Message"
              isLoading={deleteRequest.isLoading}
              onClick={() => deleteRequest.mutate(activity.id)}
              className="px-2 py-1 hover:bg-primary/10"
            >
              <Trash className="size-4 text-muted-foreground" />
            </ConfirmationButton>
          )}
        </div>
      </div>

      {activity.type === "messaged" ? (
        <ProjectMessageItem message={activity.eventable} isMe={isMe} />
      ) : activity.note ? (
        <div className={cn("ml-3 mt-2 flex items-start")}>
          <ProjectMessageBubble message={activity.note} isMe={isMe} />
        </div>
      ) : activity.type === "sample_shipped" ||
        activity.type === "sample_delivered" ? (
        <div className={cn("ml-3 mt-2", BUBBLE_CLASS)}>
          {activity.eventable.shippable &&
            "description" in activity.eventable.shippable && (
              <div>{activity.eventable.shippable.description}</div>
            )}
          {activity.eventable.tracking_url && (
            <div>
              <span>Tracking: </span>
              <a
                href={activity.eventable.tracking_url}
                className="text-primary hover:underline"
                target="_blank"
              >
                {activity.eventable.tracking_number}
              </a>
            </div>
          )}
        </div>
      ) : null}

      {activity.reactions.length > 0 && (
        <div className={cn("ml-3 mt-2")}>
          <ProjectActivityReactions activity={activity} />
        </div>
      )}
    </li>
  );
}
