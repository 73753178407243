import { UseFormReturn } from "react-hook-form";
import { GiftingProductPayload, GiftingProductType } from "@/types";
import { FormField } from "@/components/ui/form";
import { RadioGroup } from "@headlessui/react";
import { RadioOption } from "@/components/ui/radio-option";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { getGiftingProductTypeTitle } from "@/components/gifting/utils";

function TypeRadioOption({ type }: { type: GiftingProductType }) {
  return (
    <RadioOption
      value={type}
      label={getGiftingProductTypeTitle(type)}
      description={
        type === "kit" ? (
          <span>Bundle multiple products together to create a kit.</span>
        ) : type === "inventory" ? (
          <span>Gift a product from your existing inventory.</span>
        ) : type === "on_demand" ? (
          <>
            <p className="mb-2">
              Upon redemption, the gift will be ordered and shipped.
            </p>
            <Badge variant="indigo" className="mt-auto">
              Requires Assistance
            </Badge>
          </>
        ) : type === "batch" ? (
          <>
            <p className="mb-2">
              Upon redemption, the gift put into a queue, produced in a batch,
              kitted, and shipped.
            </p>
            <Badge variant="indigo" className="mt-auto">
              Requires Assistance
            </Badge>
          </>
        ) : type === "gift_card" ? (
          <p>Send a digital gift card to anyone in the world.</p>
        ) : null
      }
    />
  );
}

export default function TypeSelect({
  form,
  className,
}: {
  form: UseFormReturn<GiftingProductPayload>;
  className?: string;
}) {
  return (
    <FormField
      control={form.control}
      name="type"
      render={({ field }) => (
        <RadioGroup
          value={field.value}
          onChange={field.onChange}
          className={className}
        >
          <RadioGroup.Label className="mb-2 block text-muted-foreground">
            What type of product do you want to add?
          </RadioGroup.Label>
          {field.value ? (
            <div className="space-y-1">
              <TypeRadioOption type={field.value} />
              <Button
                variant="link"
                size="xs"
                onClick={() => field.onChange(undefined)}
              >
                &larr; Back
              </Button>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {(
                ["inventory", "on_demand", "batch", "kit", "gift_card"] as const
              ).map((type) => (
                <TypeRadioOption key={type} type={type} />
              ))}
            </div>
          )}
        </RadioGroup>
      )}
    />
  );
}
